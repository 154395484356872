import { Avatar, Badge, Box, Button, Card, CardContent, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { ForumOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Help from '../assets/images/help.jpg'


const useStyles = makeStyles(theme => ({
    badge: {
        '& .MuiBadge-anchorOriginBottomRightRectangle': {
            right: '5px',
            bottom: '10px',
            background: green[400],
            border: '1px solid white'
        }
    },
    title: {
        fontSize: '1rem'
    },
    card : {
        marginTop: '1.5rem',
        padding: '1rem',
        '&:hover': {
            boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
          }
    },
    btn : {
        padding: '.6rem 1.8rem',
        borderRadius: '10px',
        textTransform: 'none',
        fontSize: '.9rem',
        color: '#fff',
        '&:hover': {
            background: theme.secondaryColor
        }
    },
    post: {
        border: `1px solid ${theme.primaryColor}`
    },
}))
const ChatBox = () => {
    const classes = useStyles()


  return (
    <div>
        <Card className={`${classes.card} ${classes.post}`} elevation={0}>
            <CardContent style={{padding: '1rem .5rem'}}>
                <Box display={'flex'} gridGap='.5rem' marginBottom={'1rem'}>
                    <Badge badgeContent={1} variant='dot' className={classes.badge} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                        <Avatar src={Help} style={{borderRadius: '50px'}} />
                    </Badge>
                    <Box>
                        <Typography variant='h6' className={classes.title}>Need help? I'm here</Typography>
                        <Typography variant='body2' color='textSecondary'>Let me help with your order</Typography>
                    </Box>
                </Box>
                <Button disableElevation className={classes.btn} startIcon={<ForumOutlined />} variant='contained' color='primary' fullWidth>Let's Chat</Button>
            </CardContent>
        </Card>
    </div>
  )
}

export default ChatBox