import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ArrowBack, Person, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useReducer, useState } from "react";
import Google from "../../assets/images/google.svg";
import Banner from "../../assets/images/banner.jpg";
import { connect } from "react-redux";
import "animate.css";
import Modal from "../../components/Modal";
import { loginUser, errorModal, successModal } from "../../actions/actions";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    width: '55%',
    margin: '0 auto',
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  subtitle: {
    display: "block",
    margin: "2rem",
    fontWeight: 500,
    color: "#fff",
    padding: '2rem'
  },
  field: {
    marginBottom: "1.5rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  btn: {
    background: theme.primaryColor,
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
  btnGoogle: {
    padding: "1rem 0",
    background: grey[300],
    borderRadius: "8px",
  },
  flex: {
    marginBottom: "2rem",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  form: {
    width: '100%'
  },
  banner : {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems : "center",
    justifyContent : "end",
    backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 50%), transparent), url(${Banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  inside: {
    color: '#fff',
    padding: '2rem',
    background: 'rgba(225,225,225, 20%)',
    backdropFilter: 'blur(8px)',
    borderRadius: '10px',
    margin: '4rem 8rem'
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const [pass, setPass] = useState(false)

  const reducerFn = (state, action) => {
    switch (action.type) {
      case "PASSWORD":
        return { ...state, password: action.payload };
      case "USERNAME":
        return { ...state, username: action.payload };
      case "RESET":
        return { password: "", username: "" };
      default:
        return state;
    }
  };
  const [formInput, dispatch] = useReducer(reducerFn, {
    password: "",
    username: "",
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (formInput.username === "" || formInput.password === "") {
      return;
    }
    // call action creators
    setTimeout(async()=>{
      const res = await  props.loginUser(formInput);
      if(res.status === 'success'){
          //reset the form
          dispatch({type: "RESET"})
          props.successModal('Login successful')
      }else{
          props.errorModal(res.message)
      }
  },1000)
   
  };

  const renderPage = () => {
    if (!props.currentUser) {
      return (
        <div className={classes.root}>
          {/* MODAL  */}
          {props.modal && <Modal status={props.modal.status} />}

          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className={classes.banner}>
                <Box className={classes.inside}>
                    <Typography variant="h4" gutterBottom>
                      Control your workflow
                    </Typography>
                    <Typography>
                      Find the right services, work anywhere anytime. Get access to quality services and control your workflow
                    </Typography>
                  <Button href="/" startIcon={<ArrowBack />} style={{ color: "#fff", marginTop: '2rem' }}>Amalyapp</Button>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              className="animate__animated animate__zoomIn"
            >
              
                <Box className={`${classes.wrapper}`}>
                  <Typography className={classes.title} variant="h4">
                    Hello Again.
                  </Typography>
                  <Typography
                    style={{ marginBottom: "2.5rem" }}
                    paragraph
                    color="textSecondary"
                  >
                    Don't have an account? 
                    <Link href="/auth/register"> Create Account</Link>
                  </Typography>

                  <form className={classes.form}>
                    <TextField
                      className={classes.field}
                      variant="outlined"
                      label="Username"
                      helperText=""
                      value={formInput.username}
                      onChange={(e) =>
                        dispatch({ type: "USERNAME", payload: e.target.value })
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Person className={classes.fieldIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      className={classes.field}
                      variant="outlined"
                      label="Password"
                      fullWidth
                      type={pass ? 'text' : 'password'}
                      value={formInput.password}
                      onChange={(e) =>
                        dispatch({ type: "PASSWORD", payload: e.target.value })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            { pass ? <Visibility className={classes.fieldIcon} onClick={()=>setPass(!pass)} /> : <VisibilityOff className={classes.fieldIcon} onClick={()=>setPass(!pass)}/> }
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography paragraph>
                      
                      <Link href="/auth/forgotpassword" underline="always">
                        Forgot Password
                      </Link>
                    </Typography>
                    <Button
                      className={classes.btn}
                      disableElevation
                      onClick={(e) => onFormSubmit(e)}
                      fullWidth
                    >
                      Login
                    </Button>

                    <Box className={classes.flex} width="100%">
                      <Divider />
                      <Typography color="textSecondary" variant="body2">
                        or continue with
                      </Typography>
                      <Divider />
                    </Box>
                    <Button
                      className={classes.btnGoogle}
                      disableElevation
                      fullWidth
                    >
                      
                      <img
                        style={{ marginRight: "1rem" }}
                        src={Google}
                        alt="google_icon"
                      />
                      Login with Google
                    </Button>
                  </form>
                </Box>
             
            </Grid>
          </Grid>
        </div>
      );
    }
    return <Navigate to="/" />;
  };

  return <>{renderPage()}</>;
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { loginUser, errorModal, successModal })(Login);
