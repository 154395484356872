import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./pages/Home.js";
import Login from "./pages/auth/Login.js";
import { theme } from "./theme.js";
import Register from "./pages/auth/Register.js";
import Verify from "./pages/auth/Verify.js";
import ForgetPassword from "./pages/auth/ForgotPassword.js";
import ResetPassword from "./pages/auth/ResetPassword.js";
import Category from "./pages/Category.js";
import SubCategory from "./pages/SubCategory.js";
import SingleGig from "./pages/SingleGig";
import Checkout from "./pages/Checkout";
import Search from "./pages/Search";
import SellerProfile from "./pages/SellerProfile";
import Account from "./pages/user/Account";
import { connect } from "react-redux";
import { authRequest, getAllGigs, getAllCategories, userNotifications, getUserOrders, getSellerOrders } from "./actions/actions";
import { useEffect } from "react";
import Loader from "./components/Loader";
import CreateAccount from "./pages/seller/CreateAccount";
import {io} from 'socket.io-client'
import Messages from "./pages/Messages";
import SellerAccount from "./pages/seller/SellerAccount";
import OrderComplete from "./pages/OrderComplete";
import NotFound from "./pages/NotFound";

const socket = io.connect("http://localhost:8000")



function App({ loader, authRequest, currentUser, getAllGigs, getAllCategories, userNotifications, getUserOrders, getSellerOrders }) {
  const url = useLocation();
  const path = url.pathname

  useEffect(() => {
    authRequest();
    getAllGigs()
    getAllCategories()
  }, [authRequest, getAllGigs, getAllCategories]);

  useEffect(() => {
    if(currentUser){
      userNotifications()
      getUserOrders()
    }if(currentUser && currentUser.seller){
      getSellerOrders()
    }
  },[userNotifications, currentUser, getUserOrders, getSellerOrders])

  const renderResetPassword = () => {
    const token = url.search.split('=')[1];
    if(token === undefined){
      return <Navigate to='/' />
    }else {
      return <ResetPassword />
    }
  }

  const renderRegister = () => {
    if (currentUser) {
      if (currentUser.isEmailVerified === false) {
        return <Navigate to="/auth/verify" />;
      } else {
        return <Navigate to="/" />;
      }
    }
    return <Register />;
  };

  return (
    <ThemeProvider theme={theme}>
      {!loader ? (
        <div className="App">
          { path.startsWith("/auth") || path.startsWith("/seller/account") || path.startsWith("/order") ? null :  <Navbar /> }

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/inbox" element={<Messages socket={socket} />} />

            {/* AUTH */}
            <Route exact path="/auth/login" element={currentUser ? <Navigate to="/" /> : <Login />} />
            <Route exact path="/auth/register" element={renderRegister()} />
            <Route exact path="/auth/verify" element={<Verify />} />

            <Route exact path="/auth/forgotpassword" element={<ForgetPassword />} />
            <Route exact path="/auth/resetpassword" element={renderResetPassword()} />

            {/* CATEGORY */}
            <Route exact path="/category/:category" element={<Category />} />
            <Route exact path="/category/:category/:subcategory" element={<SubCategory />} />

            {/* SEARCH */}
            <Route exact path="/search/:keyword" element={<Search />} />

            {/* GIGS */}
            <Route exact path="/:user" element={<SellerProfile />} />
            <Route exact path="/:user/:title" element={<SingleGig />} />

            {/* CHECKOUT */}
            <Route exact path="/purchase" element={<Checkout />} />
            <Route exact path="/order/complete/ctx=:id" element={ <OrderComplete />} />

            {/* USER ACCOUNT */}
            <Route exact path="/account/settings" element={<Account />} />
            <Route exact path="/account/orders" element={<Account />} />
            <Route exact path="/account/orders/:id/details" element={<Account />} />
            <Route exact path="/account/orders/:id/messages" element={<Account />} />
            <Route exact path="/account/orders/:id/requirements" element={<Account />} />
            <Route exact path="/account/orders/:id/delivery" element={<Account />} />
            <Route exact path="/inbox" element={<Messages />} />
            <Route exact path="/account/notifications" element={<Account />} />

            {/* SELLER ACCOUNT */}
            <Route exact path="/seller/account" element={ currentUser && currentUser.total >= 80 ? <CreateAccount /> : <Navigate to='/account/settings' />} />
            <Route exact path="/seller/dashboard" element={<SellerAccount />} />
            <Route exact path="/seller/orders" element={<SellerAccount />} />
            <Route exact path="/seller/orders/:id/details" element={<SellerAccount />} />
            <Route exact path="/seller/notifications" element={<SellerAccount />} />
            <Route exact path="/seller/gigs" element={<SellerAccount />} />
            <Route exact path="/seller/earnings" element={<SellerAccount />} />
            <Route exact path="/seller/settings" element={<SellerAccount />} />
            <Route exact path="/seller/gigs/new" element={<SellerAccount />} />


            {/* 404 Page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      ) : (
        <Loader />
      )}
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return state;
};

export default connect(mapStateToProps, { authRequest, getAllGigs, getAllCategories, userNotifications, getUserOrders, getSellerOrders })(App);
