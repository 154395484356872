import { Avatar, Button, Container, Grid, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Modal from '../components/Modal'
import { connect } from 'react-redux'
import { Check } from '@material-ui/icons'
import Loader from '../components/Loader'


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDivider-root": {
          flexGrow: 1,
        },
      },
      wrapper: {
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        padding: "3.2rem",
        borderRadius: "16px",
        boxShadow: "-24px 24px 72px 8px rgb(145 158 171 / 24%)",
        "& a": {
          color: theme.primaryColor,
          fontWeight: 400,
        },
      },
      image: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      title: {
        marginBottom: ".5rem",
        fontWeight: 500,
      },
      field: {
        textAlign: "center",
        marginBottom: "1rem",
        borderRadius: "1.6rem",
        "& *": {
          borderRadius: "8px",
        },
        "& label.Mui-focused": {
          color: theme.primaryColor,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`,
          },
        },
      },
      btn: {
        padding: "1rem 0",
        color: "#fff",
        borderRadius: "8px",
        marginBottom: "2rem",
        "&:hover": {
          background: theme.secondaryColor,
        },
      },
      btnGoogle: {
        padding: "1rem 0",
        background: grey[300],
        borderRadius: "8px",
      },
      flex: {
        marginBottom: "2rem",
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
      },
      fieldIcon: {
        color: grey[400],
        cursor: "pointer",
      },
      icon: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: '0 auto',
        marginBottom: '2rem',
        background: theme.primaryColor,
        padding: '1rem'
      }
}))
const OrderComplete = (props) => {
    const classes = useStyles()
    const [orderId, setOrderId] = useState()
    const id = useLocation().pathname.split('/')[3].split('=')[1]
    //console.log(id)

    useEffect(()=> {
      if(id === undefined){
        <Navigate to='/' />
      }else{
        setOrderId(id)
      }
    },[id])

    const onFormSubmit = () => {
        if(props.currentUser.seller){
            window.location.assign('/seller/orders')
        }else{
            window.location.assign('/account/orders')
        }
    }

    const renderPage =() => {
        if(props.currentUser){
          if(props.currentUser && props.userOrders){
            console.log(id)
            const order = orderId && props.userOrders.filter(el => el._id === orderId)[0]
            console.log(order)

              if(order){
                  return <Container className={classes.root}>
                  
                  {/* MODAL  */}
                  {props.modal && <Modal status={props.modal.status} />}
          
                  <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                  
                    <Grid item xs={12} lg={5} style={{margin: '0 auto'}}>
                        <Avatar className={classes.icon} ><Check style={{fontSize: '3.5rem'}} /></Avatar>
                        <div className={`${classes.wrapper}`}>
                          <Typography className={classes.title} variant="h5">  Your order is complete </Typography>
                          <Typography style={{ marginBottom: "2.5rem" }} paragraph color="textSecondary" >
                            Thank you. Your order has been processed successfully. "{order.seller.username}" is waiting for the order requirements.
                          </Typography>
          
                          <Button className={classes.btn} variant="contained" disableElevation type="submit" color="primary" fullWidth onClick={onFormSubmit}>
                            Submit Requirements
                          </Button>
          
                        </div>
                    </Grid>
                  </Grid>
                </Container>

              }else{
                
                return <Navigate to='/' />

              }


          }
        }
        return <Loader />
      }

  return (
    <div>
        {renderPage()}
    </div>
  )
}


const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, {})(OrderComplete)