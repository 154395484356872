import { Avatar, Badge, Box, Button, Card, Switch, FormControlLabel, CardContent, Container, Divider, Grid, Hidden, IconButton, InputAdornment, Link, List, ListItem, ListItemText, MenuItem, Popover, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { MailOutlined, NotificationsOutlined, Person, Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import NotificationItem from './NotificationItem'
import { connect } from 'react-redux'
import { logoutUser, errorModal, setAvailability, successModal } from '../actions/actions'
import Modal from './Modal'





const useStyles = makeStyles(theme => ({
    root : {
       '& .MuiListItem-gutters': {
          
       },
       '& .MuiPopover-paper': {
           borderRadius: '10px'
       },
       '& .MuiBadge-colorPrimary': {
           color: '#fff'
       }

    },
    icon : {
        color: grey[300]
    },
    userIcon: {
        color: theme.primaryColor
    },
    wrap: {
        display: 'flex',
        gap: '2rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    auth : {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 'auto',
        gap: '1rem',
    },
    navbar : {
        margin: 0,
        width: '100%',
    },
    title : {
        fontWeight: 600
    },
    subtitle: {
        fontWeight: 500,
        fontSize: '1.1rem'
    },
    signup : {
        color: '#fff',
        '&:hover' : {
            background: theme.secondaryColor
        }
    },
    search: {
        marginRight: '2rem',
        '& .MuiOutlinedInput-adornedStart': {
            height: '2.7rem'
        }
    },
    categories: {
        borderBottom: `1px solid ${grey[300]}`, 
        marginBottom: '2rem',
        '& .MuiList-padding': {
            padding: '0'
        }
    },
    submenu : {
        display: 'flex',
        gap: '1rem',
        '& li' : {
            display: 'flex',
            justifyContent: 'center', 
            width: 'fit-content'
        }
    },
    link : {
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.primaryColor
        }
    },
      notifications: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center'
      },
      badges: {
        marginRight: '1rem',
        borderRadius: '10px',
        background: grey[200],
        padding: '12px'
      },
      small : {
        width: '46px',
        height: '100%',
        borderRadius: '12px',
        background: theme.primaryColor,
        cursor: 'pointer'
      },
      userLinks : {
        margin: '.5rem 0', 
        display: 'block',
        '&:hover': {
            color: theme.primaryColor
        }
      },
      pops: {
          marginTop: '1rem',
      },
      large : {
          margin: '.2rem auto',
          width: theme.spacing(7),
          height: theme.spacing(7),
          cursor: 'pointer'
      }
}))


const Navbar = (props) => {
    const classes = useStyles()
    const [search, setSearch] = useState('')
    const [value, setValue] = useState('')
    const [check, setCheck] = useState(false)
    const path = useLocation().pathname
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const unread = props.notifications && props.notifications.filter(el => el.isRead === 'unread')

    const sellerMenu = [
        {name: 'Dashboard', path: '/seller/dashboard'},
        {name: 'Orders', path: '/seller/orders'},
        {name: 'My Gigs', path: '/seller/gigs'},
        {name: 'Earnings', path: '/seller/earnings'},
        {name: 'Blogs ', path: '/seller/blog'}
    ]

    const links= [
        { name: 'Orders', path: '/account/orders' },
        { name: 'Notifications', path: '/account/notifications' },
        { name: 'Messages', path: '/inbox' },
        { name: 'Setting', path: '/account/settings' }
    ]

    const sellerLinks= [
        { name: 'Dashboard', path: '/seller/dashboard' },
        { name: 'My Gigs', path: '/seller/gigs' },
        { name: 'Orders', path: '/seller/orders' },
        { name: 'Earning', path: '/seller/earning' },
        { name: 'Settings', path: '/seller/settings' }
    ]

    const handleClick = (event, val) => {
        if(val === 'user'){
            setValue(val)
            setAnchorEl(event.currentTarget);
        }
        if(val === 'notifications'){
            setValue(val)
            setAnchorEl(event.currentTarget);
        }
        if(val === 'messages'){
            setValue(val)
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSearch = (e, keyword) => {
        e.preventDefault()
        if(keyword === ''){
            return
        }else {
            window.location.assign(`/search/${keyword.toLowerCase()}`)
        }
    }

    const onClickHandle = () => {
        if(props.currentUser.total < 80 ){
            props.errorModal('Sorry, please complete your account status')
            return
        }else{
            window.location.assign("/seller/account")
        }
    }

    const updateAvailability = async()=>{
        console.log(check + '- check')
        const res = await props.setAvailability(props.currentUser.sellerInfo._id, check)
        if(res.status === 'success'){
            props.successModal('Availability updated')
        }else{
            props.errorModal('Something went wrong, please refresh')
        }
    }

    const onCheckHandle = () => {
        setCheck(prev => {
            if(prev === true) {
                return false
            }
            if(prev === false) {
                return true
            }
        })
        console.log(check)
        updateAvailability()

    }
   


    const renderUser = () => {
        if(props.currentUser){
            if(props.currentUser.isEmailVerified ){
                return  <Grid item className={`${classes.notifications}`}>
                    <Hidden smDown>
                        <Link underline='none' href={props.currentUser.seller ? '/seller/orders' : '/account/orders'} color='textPrimary'> <Typography style={{marginRight: '2rem'}}>Orders</Typography> </Link>    
                    </Hidden>
                    
                    <IconButton className={classes.badges} onClick={(event) => handleClick(event, 'messages')}> 
                    <Badge badgeContent={0} variant='standard' color='primary' anchorOrigin={{ vertical: 'top', horizontal: 'right',}}> <MailOutlined className={classes.userIcon} /> </Badge> 
                    </IconButton>
                    <IconButton className={classes.badges} onClick={(event) => handleClick(event, 'notifications')}> 
                    <Badge badgeContent={props.notifications && unread.length} variant='standard' color='primary'> <NotificationsOutlined className={classes.userIcon} /> </Badge> 
                    </IconButton>
                    
                    <Avatar className={classes.small} onClick={(event) => handleClick(event, 'user')}>{props.currentUser.username.charAt(0)}</Avatar>
                
                </Grid>
            }else{
                return <Grid item className={classes.auth}>
                    <Button startIcon={<Person />} size='medium' href='/auth/verify' >LogIn</Button>
                    <Button size='medium' variant='contained' className={classes.signup} disableElevation color='primary' href='/auth/verify' >Join Us</Button>
                </Grid>
            }
        }
        return <Grid item className={classes.auth}>
                <Button startIcon={<Person />} size='medium' href='/auth/login' >LogIn</Button>
                <Button size='medium' variant='contained' className={classes.signup} disableElevation color='primary' href='/auth/register' >Join Us</Button>
            </Grid>
    }

  return (
    <div className={classes.root}>
        {props.modal && <Modal status={props.modal.status} />}
            <Box borderBottom={`1px solid ${grey[300]}`}>
                <Container maxWidth='lg' >
                    <Grid container  className={classes.navbar} spacing={4}>
                        {/* LOGO */}
                        <Grid item className={classes.wrap}>
                            <Link underline='none' href='/'><Typography variant='h5' className={classes.title}>Amalyapp</Typography></Link> 
                        </Grid>

                        <Hidden smDown>
                            <Grid item  className={classes.wrap} >
                                <Link color='textPrimary'><Typography variant='body1'>Post a Job</Typography></Link>
                            </Grid>
                        </Hidden>

                        {/* SEARCH */}
                        <Hidden xsDown>
                            <Grid item lg={6} md={4} sm={4} >
                                <form onSubmit={(e) => onSearch(e, search)}>
                                    <TextField variant='outlined' value={search} fullWidth className={classes.search} placeholder='Find a gig' onChange={(e)=> setSearch(e.target.value)}  InputProps={{
                                        startAdornment: <InputAdornment position='start'><Search className={classes.icon} /></InputAdornment>
                                        }} />
                                </form>
                            </Grid>
                        </Hidden>

                        {/* LOGIN/REGISTER */}
                        {  renderUser() }
                        

                    </Grid>
                </Container>
            </Box>

            { path.startsWith('/auth') ? null : 
             <Box className={classes.categories}>
                <Container>
                    
                    {
                        path.startsWith('/seller') ?
                        <List style={{display:'flex'}}>
                            <span className={classes.submenu}>
                            { sellerMenu.map((item) => {
                                return (
                                    <ListItem key={item.name}>
                                        <Link href={item.path} className={classes.link} underline='none' color='textPrimary'>
                                            <ListItemText >{item.name}</ListItemText>
                                        </Link>
                                    </ListItem>
                                    )
                                })
                            }
                            </span>
                            <span style={{marginLeft: 'auto', display: 'flex', gap: '.5rem'}}>
                            <ListItem>
                                <Link href={'/seller/requests'} style={{marginLeft: 'auto'}} className={classes.link} underline='none' color='textPrimary'>
                                    <Typography>FAQs</Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href={'/seller/requests'} style={{marginLeft: 'auto'}} className={classes.link} underline='none' color='textPrimary'>
                                    <Typography>Support</Typography>
                                </Link>
                            </ListItem>
                            </span>

                        </List>
                         :
                        <List className={classes.submenu}>
                            { props.allCategories && props.allCategories.map(item => {
                                return (
                                    <ListItem key={item.category}>
                                        <Link href={`/category/${item.slug}`} className={classes.link} underline='none' color='textPrimary'>
                                            <ListItemText >{item.category}</ListItemText>
                                        </Link>
                                    </ListItem>
                                    )
                                })
                            }
                        </List>

                    }
                </Container>
            </Box> 
            }

            {/* POPOVERS */}
            <Popover open={open} anchorEl={anchorEl} className={classes.pops} 
                onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}>

                {
                    value === 'user' &&
                        <Card>
                            <CardContent style={{padding: '1.5rem', width: '12rem'}}>
                                <Box textAlign={'center'}>
                                    <Avatar className={classes.large} src={props.currentUser.profile_pic ? props.currentUser.profile_pic.url : null} />
                                    <Typography variant='h6'>{props.currentUser.username}</Typography>
                                    <Typography noWrap color='textSecondary' variant='body2'>{props.currentUser.email}</Typography>
                                </Box>

                                <Divider style={{margin: '1rem 0'}} />
                                {
                                    !props.currentUser.seller ?
                                    <Box>
                                        {links.map(el => {
                                        return (
                                            <MenuItem onClick={()=> window.location.assign(`${el.path}`)} style={{borderRadius: '6px'}} key={el.name}>{el.name}</MenuItem>
                                            )
                                        })}
                                    </Box>
                                    :
                                    <Box>
                                        {sellerLinks.map(el => {
                                        return (
                                            <MenuItem onClick={()=> window.location.assign(`${el.path}`)} style={{borderRadius: '6px'}} key={el.name}>{el.name}</MenuItem>
                                            )
                                        })}
                                    </Box>
                                }


                                { !props.currentUser.seller ?
                                    <>
                                    <Divider style={{margin: '1rem 0'}} />
                                    <MenuItem style={{borderRadius: '6px'}} onClick={onClickHandle}><Typography>Become a Seller</Typography></MenuItem> 
                                    </> :
                                    <>
                                        <Divider style={{margin: '1rem 0'}} />
                                        <FormControlLabel control={<Switch onChange={onCheckHandle} checked={props.currentUser.seller && props.currentUser.sellerInfo.availability ? true : false } color='primary' />} label={<Typography>Available</Typography>} />
                                    </>
                                }
                                <Divider style={{margin: '1rem 0'}} />

                                <Box>
                                    <MenuItem style={{borderRadius: '6px'}}><Typography>Help & Support</Typography></MenuItem>
                                    <MenuItem onClick={() => props.logoutUser()} style={{borderRadius: '6px'}}><Typography >Logout</Typography></MenuItem>
                                </Box>
                            </CardContent>
                        </Card>                   
                }

                {/* NOTIFICATIONS */}
                {
                    value === 'notifications' &&
                    <Card>
                        <CardContent style={{padding: '1.5rem', width: '20rem'}} >
                            <Typography className={classes.subtitle}>Notifications({unread.length})</Typography>
                            <Divider style={{margin: '1rem 0'}} />
                                <Box >
                                    {
                                        unread.length > 0 ? unread.map(item => {
                                            return <NotificationItem key={item._id} subject={item.subject} body={item.body} />
                                        })
                                        :
                                        <Typography>You have no Notifications</Typography>
                                    }
                                    
                    
                                </Box>
                            <Divider style={{margin: '1rem 0'}} />
                            <Button style={{textAlign: 'right'}} href={props.currentUser && props.currentUser.seller ? '/seller/notifications' : '/account/notifications'}>View all</Button>
                        </CardContent>
                    </Card>    
                }

                {
                    value === 'messages' &&
                    <Card>
                        <CardContent style={{padding: '1.5rem', width: '20rem'}} >
                            <Typography className={classes.subtitle}>Messages(0)</Typography>
                            <Divider style={{margin: '1rem 0'}} />
                                <Box >
                                    <Typography color='textSecondary'>You have no messages</Typography>
                                </Box>
                            <Divider style={{margin: '1rem 0'}} />
                            <Button style={{textAlign: 'right'}} >All Messages</Button>
                        </CardContent>
                    </Card>    
                }
             </Popover>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps,{logoutUser, errorModal, setAvailability, successModal})(Navbar)