import { Button, Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    post: {
        borderRadius: '10px',
        border: `1px solid ${theme.primaryColor}`,
        background: theme.primaryColor
    }
}))

const JobCard = () => {
    const classes = useStyles()


  return (
    <div>
         <Card className={`${classes.post}`}  elevation={0} style={{marginTop: '1.5rem'}}>
            <CardContent style={{padding: '2rem 1.5rem', color: '#fff'}}>
                            
                    <Typography variant='h6' gutterBottom>Create or Post A Job</Typography>
                    <Typography variant='body1' paragraph >Cant find a talent for your job? Let's help you connect to great talents. Simply create a job and be connected. </Typography>
                    <Button variant='contained'  style={{background:'#fff', color: '#ED8A2F', height: '2.5rem', borderRadius: '10px', padding: '0 2rem'}} disableElevation >Post A Job</Button>

            </CardContent>
        </Card>
    </div>
  )
}

export default JobCard