import { combineReducers } from "redux";

export const loaderReducer = ( loader = true, action) => {
    switch (action.type) {
        case "LOADING":
            return true
        case "EXIT_LOADING":
            return false
        default:
            return loader;
    }
}

export const userReducer = (user = null, action) => {
    switch (action.type) {
        case "NEW_USER":
            return {...action.payload}
        case "LOGIN_USER":
            return {...action.payload}
        case "NO_USER":
            return user
        case "LOGOUT_USER":
            return user
        default:
           return user
    }
}

export const allUsersReducer = (allUsers = null, action) => {
    switch (action.type) {
        case "ALL_USERS":
            return action.payload
        default:
           return allUsers
    }
}

// CATEGORIES & SUBCATEGORIES

export const categoryReducer = (allCategories = null, action) => {
    switch (action.type) {
        case "ALL_CATEGORIES":
            return action.payload
        default:
           return allCategories
    }
}

// MODAL

export const modalReducer = (modal = null, action) => {
    switch (action.type) {
        case "LOADING_MODAL":
            return { message : action.payload, status: 'loading' }
        case "SUCCESS_MODAL":
            return { message : action.payload, status: 'success' }
        case "ERROR_MODAL":
            return { message : action.payload, status: 'error' }
        case "RESET_MODAL":
            return null
        default:
           return modal
    }
}

export const gigReducer = (gig = null, action) => {
    switch (action.type) {
        case "SELLER_GIGS":
            return action.payload
        default:
           return gig
    }
}

export const allGigsReducer = (allGigs = null, action) => {
    switch (action.type) {
        case "ALL_GIGS":
            return action.payload
        default:
           return allGigs
    }
}


export const userOrdersReducer = (orders = null, action) => {
    switch (action.type) {
        case "USER_ORDERS":
            return action.payload
        case "NO_ORDERS":
            return []
        default:
           return orders
    }
}

export const sellerOrdersReducer = (sellerOrders = null, action) => {
    switch (action.type) {
        case "SELLER_ORDERS":
            return action.payload
        case "NO_ORDERS":
            return []
        default:
           return sellerOrders
    }
}

export const notificationReducer = (notifications = null, action) => {
    switch (action.type) {
        case "USER_NOTIFICATIONS":
            return action.payload    
        default:
            return notifications
    }
}

// CHAT REDUCERS
export const chatReducer = (chatUsers = null, action) => {
    switch (action.type) {
        case "CHAT_USERS":
            return action.payload
        default:
            return chatUsers
    }
}
export const currentChatReducer = (currentChat = null, action) => {
    switch (action.type) {
        case "CURRENT_CHAT":
            return action.payload
        case "UPDATE_CHAT":
            return [...currentChat, action.payload]
            
        default:
            return currentChat
    }
}







export default combineReducers({
    loader: loaderReducer,
    allUsers: allUsersReducer,
    allGigs: allGigsReducer,
    allCategories: categoryReducer,
    currentUser : userReducer, 
    modal : modalReducer, 
    gigs : gigReducer,
    userOrders: userOrdersReducer, 
    sellerOrders: sellerOrdersReducer, 
    notifications: notificationReducer,
    chats: chatReducer,
    currentChat: currentChatReducer,
});

