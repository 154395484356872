import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, Link, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Attachment from './Attachment'


const useStyles = makeStyles(theme => ({
    wrap : {
        marginBottom: '2rem'
    }
}))

const DeliveryItem = () => {
    const classes = useStyles()


  return (
    <div className={classes.wrap}>
        <Card variant='outlined'>
           <CardHeader style={{background: grey[200], borderRadius: 0}} 
                title={<Typography variant='h6'>DELIVERY #1</Typography>} 
                subheader={<Typography variant='body2' color='textSecondary' >27th March 2022 at 11:15PM</Typography>}
             />
           <Divider />
           <CardContent style={{display: 'flex', gap: '1rem', padding: '2.5rem'}}>
             <Avatar style={{borderRadius: '50px'}}/>
                <Box>
                   <Typography variant='h6' gutterBottom><Link>jeremiah</Link>'s message</Typography>
                       <span>
                         <Typography color='textSecondary'>
                             Hi henry,<br/>
                            Thanks again for your order! Your delivery is enclosed. If there are any problems, please let me know. I'll get back to you as soon as I can.
                            <br/><br/>
                            Thanks again and have a great day! :) <br/>
                            jeremiah
                        </Typography>
                         </span>
                        <Box margin='1rem 0'>
                            <Typography variant='h6' gutterBottom>Attachments</Typography>
                            <Grid container spacing={2}>
                                <Grid item lg={3}> <Attachment /> </Grid>
                                <Grid item lg={3}> <Attachment /> </Grid>
                                <Grid item lg={3}> <Attachment /> </Grid>
                            </Grid>
                        </Box>
                </Box>
            </CardContent>
        </Card>
    </div>
  )
}

export default DeliveryItem