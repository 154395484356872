import { Box, Button, Chip, Container, Grid, IconButton, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel'
import { useLocation } from 'react-router-dom'
import GigItem from '../components/GigItem'
import { connect } from 'react-redux'
import { getCategory } from '../actions/actions'
import Loader from '../components/Loader'
import Files from '../assets/images/paper.png'
import slugify from 'slugify'


const useStyles = makeStyles(theme => ({
    subs : {
        marginBottom: '2.5rem',
        '& p' : {
            fontSize: '1rem'
        },
        '& .owl-nav': {
            display: 'flex',
            gap: '1rem',
            position: 'absolute',
            top: '-40%',
            right: '0%',
            '& .owl-prev, & .owl-next' : {
                color: theme.primaryColor,
                padding: '10px 12px',
            },
            '& .owl-next' : {
                background: '#d3d3d347',
                borderRadius: '8px'
            }
        },
    },
    title : {
        fontSize: '1.5rem',
        fontWeight: 500,
    },
    banner : {
        padding : '4rem',
        marginBottom: '2rem',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff'
    },
    iconBtn: {
        background: theme.primaryColor,
        height: "3rem",
        width: "3rem",
        color: '#fff'
    },

}))


const Category = (props) => {
    const { getCategory } = props
    const classes = useStyles()
    const [data, setData] = useState()
    const path = useLocation().pathname.split('/')[2]
    //console.log(path)

    useEffect(()=>{
        const fetchData = async() => {
            const res = await getCategory(path)
            //console.log(res.data)
            setData(res.data)
        }
        fetchData()
    },[getCategory, path])


    const renderPage = () => {
        if(data && props.allGigs){
            const subcategory = Object.keys(data.subcategories)
            const filtered = props.allGigs.filter(el => el.category === data.category)
            // console.log(filtered)

            return  <Container>
            <Box className={classes.banner} style={{backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 50%), rgba(0,0,0, 50%)), url(${data.photo.url})` }}>
                <Typography variant='h4' gutterBottom>{data.category}</Typography>
                <Typography paragraph>Be sure to stand out from the crowd.</Typography>
                <Button variant='contained' color='primary' disableElevation style={{color:'#fff'}}> How it works</Button>
            </Box>

            <Box className={classes.subs}>
                <Typography variant='h5' className={classes.title} gutterBottom >Most popular in {data.category}</Typography>

                <OwlCarousel
                 items={5} autoplaySpeed={1500} margin={20} loop={true}
                    nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                    responsive={ { 1400 : {items : '5'}, 1200 : {items : '4.5'}, 760 : {items : '3'}, 340 : {items : '1.5'} } }
                > 
                { subcategory.map(item => {
                    return (
                        <Link underline='none' key={item} href={`/category/${data.slug}/${slugify(item, {lower: true, trim: true})}`}>
                            <Box  marginTop='1rem' display='flex' padding='.8rem' gridGap='10px' border={'1px solid lightgrey'} borderRadius='10px' alignItems='center'>
                                <IconButton className={classes.iconBtn} > {item.charAt(0).toUpperCase()} </IconButton>
                                <Typography variant='body1' color='textPrimary'> {item} </Typography>
                            </Box>
                        </Link>
                    )
                })}
                
                </OwlCarousel>
            </Box>

            <Box>
                { filtered.length > 0 && <Typography className={classes.title} style={{marginBottom: '1.5rem'}} variant='h5' gutterBottom >Explore more on {data.category}</Typography> }
                <Grid container spacing={3}>
                    { 
                        props.allGigs && filtered.length > 0 ? filtered.map(item => {
                            return (
                                <Grid item lg={3} md={4} sm={6} xs={12} key={item.id}>
                                    <GigItem data={item} />
                                </Grid>
                            )
                        }) 
                        :
                        <Grid item sm={12}>
                            <Box textAlign={'center'} padding='4rem'>
                                <img src={Files} width='20%' alt='no files' style={{display: 'block', margin: '0 auto', marginBottom: '1rem'}}/>
                                <Chip label='Sorry, no Gigs available'  />
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
            
        </Container>
        }

        return <Loader />
    }

  return (
    
    <>{renderPage()}</>
  )
}

const mapStateToProps = ( state ) => {
    return state
}

export default connect(mapStateToProps, {getCategory})(Category)