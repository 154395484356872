import { Box, Button, Container, Grid, InputAdornment, Link, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import BannerImg from '../assets/images/banner-img.png'
import CoverImg from '../assets/images/banner4.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiContainer-maxWidthLg': {
            maxWidth: theme.container
        },
        '& .MuiOutlinedInput-adornedEnd': {
            background: '#fff'
        }
    },
    wrap : {
        height: '60vh',
        padding: '3rem',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '1.5rem',
        display: 'flex',

    },
    icon : {
        color: grey[300]
    },
    title : {
        fontWeight: 300,
        fontSize: '3rem',
        marginBottom: '1rem',
        color: '#fff'
    }
}))
const Banner = () => {
    const classes = useStyles()

  return (
      <Container maxWidth='lg' className={classes.root}>
        <Box className={classes.wrap} style={{backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 50%), transparent), url(${CoverImg})`}}>
                <Grid container spacing={3}>
                    <Grid item sm={6} style={{display: 'flex',justifyContent: 'center', flexDirection: 'column', color: '#fff'}}>
                        <Typography variant='h3' className={classes.title}>Join us & Explore Thousands of Jobs</Typography>
                        <Typography paragraph>Find Talents, Employment & Career Opportunities</Typography>
                        <Box>
                            <TextField style={{margin:'2rem 0 1rem 0'}} variant='outlined' fullWidth placeholder='Find a gig' InputProps={{
                                startAdornment: <InputAdornment position='start'><Search className={classes.icon} /></InputAdornment>,
                                endAdornment: <InputAdornment position='end'><Button size='large' color='primary'>Find Jobs</Button></InputAdornment>
                                }} />
                    
                            <Typography variant='body1' color='primary' style={{display:'flex', gap:'1rem', color: '#fff'}}>
                                Popular Searches : <Typography variant='body1' component={'span'}> <Link >Designer</Link>, <Link >Developer</Link>, <Link >Social Media</Link>, <Link >VoiceOver</Link></Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        {/* <img src={BannerImg} alt='banner' width={'100%'} /> */}
                    </Grid>
                </Grid>
        </Box>

      </Container>
  )
}

export default Banner