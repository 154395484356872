import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Chip, Container, Dialog, Divider, FormControlLabel, Grid, InputAdornment, LinearProgress, Link, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { KeyboardArrowLeft, NavigateNext } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useReducer, useState } from 'react'
import VisaCard from '../assets/images/visa.svg'
import MasterCard from '../assets/images/mastercard.svg'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from '../components/Loader'
import { Rating } from '@material-ui/lab'
import PaymentGif from '../assets/images/payment.gif'
import { newOrder, successModal, errorModal } from '../actions/actions'



const useStyles = makeStyles(theme => ({
    root : {
        marginTop: '4rem',
        '& *': {
          borderRadius: '8px'
        },
        "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
            }
        },
    },
    caption: {
        '& span': {
          fontSize: '1.2rem',
          fontWeight: 500,
        }
      },
    card : {
        padding: '1rem',
        borderRadius: '1rem',
        border: '1px solid white',
        boxShadow: '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    summary : {
        display: 'flex',
        flexDirection: 'column',
    },
    checkout : {
        marginTop: '1rem',
        background: theme.primaryColor,
        height: '3rem',
        color: '#fff',
        '&:hover' : {
            background: theme.secondaryColor
        }
    },
    payment : {
        display: 'flex',
        border: `1px solid ${grey[300]}`,
        padding: '1.5rem 2rem',
        borderRadius: '.5rem',
     
    },
    title: {
        width: '70%'
    }
}))
const Checkout = (props) => {
    const { allGigs } = props
    const classes = useStyles()
    const [show, setShow] = useState(true)
    const [loader, setLoader] = useState(false)
    const [currentGig, setCurrentGig] = useState()
    const path = useLocation().search.split('=')
    const [value, setValue] = React.useState('card');
    const months = ['01','02','03','04','05','06','07','08','09','10','11','12']
    const years = ['2022', '2023', '2024', '2025', '2026', '2027']
    const gigId = path[1].slice(0,-3)
    const selectedPackage = path[2]

    useEffect(()=>{
        if(path === undefined){
            window.location.assign('/')
        }
        if(gigId && allGigs){
            const gig = allGigs.filter(gig => gig.id === gigId)
            if(gig.length === 0){
                window.location.assign('/')
            }else{
                setCurrentGig(gig[0])
                setShow(false)
            }
        }

    },[path, allGigs, gigId])

    // initail state
    const initialState = {
        accName: '',
        phone: '',
        payment: value,
        cardName: '', 
        cardNumber: '', 
        expiryMonth: '', 
        expiryYear: '', 
        cvv: ''
    }

      // checkout reducer
      const checkOutReducer = (state, action) => {
        switch (action.type) {
            case "ACC_NAME":
                return {...state, accName: action.payload }
            case "PHONE":
                return {...state, phone: action.payload }
            case "CARD_NAME":
                return {...state, cardName: action.payload }
            case "CARD_NUMBER":
                return {...state, cardNumber: action.payload }
            case "EXPIRY_MONTH":
                return {...state, expiryMonth: action.payload }
            case "EXPIRY_YEAR":
                return {...state, expiryYear: action.payload }
            case "CVV":
                return {...state, cvv: action.payload }
            case "RESET":
                return initialState        
            default:
                return initialState  
        }
    }

    const [formInput, dispatch] = useReducer(checkOutReducer, initialState)


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const onPurchase = (id, packages, total, payment ) => {
        const data = {
            gig: id,
            package: packages,
            total,
            payment
        }
        //console.log(data)
        setLoader(true)
        setTimeout(async() => {
            const res = await props.newOrder(data)
            if(res.status === 'success'){
                setLoader(false)
                console.log(res.data)
                window.location.assign(`/order/complete/ctx=${res.data.id}`)
            }else{
                setLoader(false)
                props.errorModal(res.message)
            }
        }, 5000);
    }

    const renderPage = () => {
        if(!show && currentGig){
            const serviceFee = 5/100
            const subtotal = parseInt(JSON.parse(currentGig.packages[`${selectedPackage}`]).Price)
            const total = (subtotal * serviceFee) + subtotal
            return <Container className={classes.root}>
            <Breadcrumbs separator={<NavigateNext fontSize='small' />} style={{margin: '1.5rem 0'}}>
               <Link color='textSecondary' underline='none' className={classes.link} href={'/'}>Amalyapp</Link>
               <Link color='textSecondary' underline='none' className={classes.link} href={'/purchase'}>Purchase</Link>
                <Typography color="textPrimary">Cart & Checkout</Typography>
            </Breadcrumbs>

            <Grid container spacing={4}>
                <Grid item lg={8}>
                    <Card variant='outlined' className={classes.card}>
                        <Box style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <CardHeader title={`Your Cart(1 item) `} className={classes.caption} />
                            <Button startIcon={<KeyboardArrowLeft /> }
                            variant='contained' color='secondary' disableElevation 
                            className={classes.update}>Go Back</Button>
                        </Box>
                        <Divider />
                        <CardContent style={{padding: '2rem'}}>

                            <Box>
                                <Box>
                                    <Grid container spacing={5} style={{alignItems: 'center'}}>
                                        <Grid item sm={3} style={{height: '8rem', width: '8rem', backgroundImage: `url(${currentGig.gallery[0].url})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                           
                                        </Grid>
                                        <Grid item sm={9}>
                                            <Box>
                                                <Link color='textPrimary' href={`/${currentGig.user.username.toLowerCase()}/${currentGig.slug}`}>
                                                    <Typography variant='h6' noWrap className={classes.title}>{currentGig.title}</Typography>
                                                </Link>
                                                <Typography variant='body1' gutterBottom>Package - {selectedPackage}</Typography>
                                                <span style={{display: 'flex', gap: '10px'}}>
                                                    <Rating readOnly value={currentGig.avgRating} size='medium' color='primary' /> 
                                                    <Typography variant='body1'>({currentGig.avgRating})</Typography>
                                                </span>
                                                
                                                <Divider style={{margin: '10px 0'}} />
                                                <Typography variant='body1' style={{fontWeight: 500}}>What's included</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    
                                </Box>
                            </Box>

                        </CardContent>
                    </Card>

                    <Card variant='outlined' className={classes.card} style={{marginTop: '2rem'}}>
                        <CardHeader title='Payment Method' className={classes.caption} style={{paddingBottom: '0'}} />
                        <CardContent>
                        <RadioGroup value={value} onChange={handleChange}>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <Box className={classes.payment}>
                                        <FormControlLabel value='card' control={<Radio />} />
                                        <Box>
                                            <Typography variant='h6' color='textPrimary'>Visa/Credit Card </Typography>
                                            <Typography variant='body2' color='textSecondary'>A flat service fee of 0.09% </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                <Box className={classes.payment}>
                                        <FormControlLabel value='momo' control={<Radio />} />
                                        <Box>
                                            <Typography variant='h6' color='textPrimary'>Mobile Money </Typography>
                                            <Typography variant='body2' color='textSecondary'>A flat service fee of 0.05% </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            
                        </RadioGroup>
                        </CardContent>
                    </Card>

                {/* VISA CARD */}
                {
                    value === 'card' &&
                    <Card variant='outlined' className={classes.card} style={{marginTop: '2rem'}}>
                        <CardContent >
                            <Box style={{display:'flex', justifyContent: 'space-between'}}>
                                <span>
                                    <Typography variant='h6' color='textPrimary'>Visa/Credit Card</Typography>
                                    <Typography paragraph variant='body1' color='textSecondary'>We support Master, Visa, Debit and Credit Cards</Typography>
                                </span>
                                <span>
                                    <img src={MasterCard} alt="mastercard" />
                                    <img src={VisaCard} alt="visacard" />
                                </span>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <TextField variant='outlined' value={formInput.cardName} onChange={(e)=> dispatch({type: "CARD_NAME", payload: e.target.value})} fullWidth label='Name on Card Account' />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField fullWidth variant='outlined' type={'number'} label='Card Number' value={formInput.cardNumber} onChange={(e)=> dispatch({type: "CARD_NUMBER", payload: e.target.value})}/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField variant='outlined' select value={formInput.expiryMonth} onChange={(e)=> dispatch({type: "EXPIRY_MONTH", payload: e.target.value})} fullWidth label='Card Expiry Month' >
                                    {months.map(el => {
                                        return (
                                        <MenuItem key={el} value={el} >{el}</MenuItem>
                                        )
                                    })}
                                    </TextField>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField variant='outlined' select value={formInput.expiryYear} onChange={(e)=>dispatch({type: "EXPIRY_YEAR", payload: e.target.value})} fullWidth label='Card Expiry Year' >
                                    {years.map(el => {
                                        return (
                                        <MenuItem key={el} value={el} >{el}</MenuItem>
                                        )
                                    })}
                                    </TextField>
                                </Grid>
                                <Grid item sm={6}>
                                <TextField variant='outlined' type={'number'} label='CVV' value={formInput.cvv} onChange={(e)=>dispatch({type: "CODE", payload: e.target.value})} fullWidth/>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                    
                }

                {/* MOBILE MONEY */}
                {
                    value === 'momo' &&
                    <Card variant='outlined' className={classes.card} style={{marginTop: '2rem'}}>
                        <CardContent>
                            <Box style={{display:'flex', justifyContent: 'space-between'}}>
                                <span>
                                    <Typography variant='h6' color='textPrimary'>Mobile Money</Typography>
                                    <Typography paragraph variant='body1' color='textSecondary'>We support MTN, Vodafone, AirtelTigo</Typography>
                                </span>
                                <span>
                                    <img src={MasterCard} alt="mastercard" />
                                    <img src={VisaCard} alt="visacard" />
                                </span>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <TextField variant='outlined' value={formInput.accName} onChange={(e)=> dispatch({type: "ACC_NAME", payload: e.target.value})} fullWidth label='Account Name' />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField fullWidth variant='outlined' type={'number'} label='Phone Number' value={formInput.phone} onChange={(e)=> dispatch({type: "PHONE", payload: e.target.value})}/>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                }

                </Grid>
                <Grid item lg={4}>
                    <Card variant='outlined' className={classes.card}>
                        <CardHeader title='Summary' className={classes.caption} />
                        <Divider />
                        <CardContent>
                            <div className={classes.summary}>
                                <div className={classes.field}>
                                    <Typography variant='body1' color='textSecondary' >SubTotal</Typography>
                                    <Typography variant='body1'>Gh¢ {subtotal.toLocaleString()}</Typography>
                                </div>
                                <div className={classes.field} style={{marginTop: '.6rem'}}>
                                    <Typography variant='body1' color='textSecondary' >Discount</Typography>
                                    <Typography variant='body1'> ---</Typography>
                                </div>
                                <div className={classes.field} style={{marginTop: '.6rem'}}>
                                    <Typography variant='body1' color='textSecondary' >Service Fee</Typography>
                                    <Typography variant='body1'>5%</Typography>
                                </div>
                                <Divider style={{margin: '1rem 0 .8rem 0'}} />

                                <div className={classes.field} style={{margin: '.6rem 0'}}>
                                    <Typography variant='body1' style={{fontWeight: 600}} >Total</Typography>                      
                                    <Typography variant='body1' style={{fontWeight: 600}}>Gh¢ {total.toLocaleString()}</Typography>
                                </div>

                                {/* DISCOUNT FIELD */}
                                <Box>
                                <TextField style={{marginTop: '1rem', borderRadius: '1rem'}} fullWidth label='Discount' variant='outlined' InputProps={{endAdornment: <InputAdornment position='end'><Chip label='Apply' onClick={()=>{}} /></InputAdornment>}} />
                                <Button variant='contained' disableElevation className={classes.checkout} onClick={() => onPurchase(gigId, selectedPackage, total, value)} fullWidth color='primary'>Complete Purchase</Button>
                                </Box>


                            </div>
                        </CardContent>
                    </Card>
                    
                </Grid>
            </Grid>

            {/* LOADER */}
            <Dialog open={loader} maxWidth={'sm'}>
                <Box padding={'2.5rem'} textAlign={'center'}>
                    <img src={PaymentGif} alt='creating gig' width={'25%'}/>
                    <Typography paragraph>Processing your order. Please wait.</Typography>
                    <LinearProgress />
                </Box>
            </Dialog>
        </Container>

        }

        return <Loader />
    }



  return (

    <>
        {renderPage()}
    </>

  )
}


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {newOrder, successModal, errorModal})(Checkout)