import { Avatar, Box, Button, Card, CardContent, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CoverImg from '../../assets/images/cover-image.png'
import React, { useReducer, useRef, useState } from 'react'
import { Add, ArrowForward, CameraAlt, Facebook, GitHub, Instagram, LinkedIn, Twitter, Visibility, VisibilityOff } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import { editUserSettings, editSellerSettings, editUserLinks, editUserPhoto, successModal, errorModal, editUserCoverImage } from '../../actions/actions'
import User from '../../assets/images/user.gif'
import { Autocomplete } from '@material-ui/lab'
import Modal from '../../components/Modal'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '-2rem',
        background: '#b4b4b412',
    },
    banner: {
        height: '15rem',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    bannerWrap: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'flex-end',
    },
    large : {
        margin: '.5rem auto',
        width: theme.spacing(15),
        height: theme.spacing(15),
        border: `1px solid ${theme.primaryColor}`,
        cursor: 'pointer'
    },
    card : {
        borderRadius: '15px',
        '&:hover': {
            boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
          }
    },
    post: {
        borderRadius: '15px',
        border: `1px solid ${theme.primaryColor}`
    },
    wrap : {
        '& span': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
        }
    },
    socials: {
        margin: '5px'
    },
    field : {
        marginBottom: '.5rem'
    },
    fieldIcon: {
        color: grey[400],
        cursor: 'pointer'
    }
}))
const SellerSettings = (props) => {
    const { user, editSellerSettings, editUserLinks, editUserPhoto, successModal, errorModal } = props
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const skills = ['Photoshop', 'React','Nodejs', 'Adobe Suit', 'Illustrator','Website', 'Content Writing', 'Blogging', 'Voice Over', 'Wordpress', 'Flutter', 'Mobile Apps', 'SEO', 'Digital Marketing', 'Video Editing', 'Animation']
    const languages = ['English', 'French', 'Spanish']
    const browse = useRef()
    const cover = useRef()

    const linkState = {
        facebook: '',
        linkedin: '',
        instagram : '',
        twitter: '',
        github: ''
    }

    const profileState = {
        about: '',
        skills: [],
        language : [],
        phone: '',
    }

    const passwordState = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }

    const passReducerFn = (state, action)=> {
        switch (action.type) {
        case "CURRENT":
            return {...state, currentPassword: action.payload}
        case "NEW_PASS":
            return {...state, newPassword: action.payload}
        case "CONFIRM":
            return {...state, confirmPassword: action.payload}
        case "RESET":
            return passwordState
        default:
            return state;
        }
    }

    const linksReducerFn = (state, action) => {
        switch (action.type) {
            case "FB":
                return {...state, facebook: action.payload}
            case "LK":
                return {...state, linkedin: action.payload}
            case "IN":
                return {...state, instagram: action.payload}
            case "TW":
                return {...state, twitter: action.payload}
            case "GH":
                return {...state, github: action.payload}
            case "RESET":
                return linkState
            default:
                return state;
        }
    }
    const reducerFn = (state, action) => {
        switch (action.type) {
            case "ABOUT":
                return {...state, about: action.payload}
            case "LANGUAGE":
                return {...state, language: action.payload}
            case "SKILLS":
                return {...state, skills: action.payload}
            case "PHONE":
                return {...state, phone: action.payload}
            case "RESET":
                return profileState
            default:
                return state;
        }
    }
    const [formInput, dispatch] = useReducer(reducerFn, profileState)
    const [linkInput, linkDispatch] = useReducer(linksReducerFn, linkState)
    const [passInput, passDispatch] = useReducer(passReducerFn, passwordState)

    const onFormSubmit = (val) => {
        if(val === 'profile'){
            if(formInput.about === '' && formInput.skills.length === 0 && formInput.language.length === 0 && formInput.phone === ''){
                return
            }
            setLoader(true)
            setTimeout(async()=> {
                const res = await editSellerSettings(formInput, user.sellerInfo._id)
                if(res.status === 'success'){
                    //reset the form
                    dispatch({type: "RESET"})
                    setLoader(false)
                    successModal('Settings update successful')
                }else{
                    setLoader(false)
                    errorModal(res.message)
                }
            }, 2000)
            console.log(formInput)
            return
        }
        if(val === 'password'){
            if(passInput.currentPassword === '' || passInput.newPassword === '' || passInput.confirmPassword === '' ){
                return
            }
            if(passInput.newPassword !== passInput.confirmPassword ){
                errorModal('Sorry, passwords do not match')
                return
            }
            //console.log(passInput)
            setLoader(true)
            setTimeout(async()=> {
                const data = {...passInput }
                const res = await editUserSettings(data)
                if(res.status === 'success'){
                    //reset the form
                    dispatch({type: "RESET"})
                    setLoader(false)
                    successModal('Settings update successful')
                }else{
                    setLoader(false)
                    errorModal(res.message)
                }
            }, 2000)
            return
        }
        if(val === 'social'){
            //console.log(linkInput)
            setLoader(true)
            setTimeout(async()=> {
                const res = await editUserLinks(linkInput)
                if(res.status === 'success'){
                    //reset the form
                    dispatch({type: "RESET"})
                    setLoader(false)
                    successModal('Settings update successful')
                }else{
                    setLoader(false)
                    errorModal(res.message)
                }
            }, 2000)
            return
        }
    }

    const onPhotoSelect = (e) => {
        const data = new FormData()
        data.append('profile_pic', e.target.files[0])
        setLoader(true)
        setTimeout(async() => {
            const res = await editUserPhoto(data)
            if(res.status === 'success'){
                //reset the form
                setLoader(false)
                successModal('Profile photo uploaded successfully')
            }else{
                setLoader(false)
                errorModal(res.message)
            }
        }, 2000);
    }

    const onCoverSelect = (e) => {
        const data = new FormData()
        data.append('coverImg', e.target.files[0])
        setLoader(true)
        setTimeout(async() => {
            const res = await props.editUserCoverImage(data)
            if(res.status === 'success'){
                //reset the form
                setLoader(false)
                props.successModal('Cover image uploaded successfully')
            }else{
                setLoader(false)
                props.errorModal('Sorry, something went wrong. Please refresh and try again')
            }
        }, 2000);
    }


    const closeHandle = () => {
        dispatch({type: "RESET"})
        setOpen(false)
    }



  return (
    <div className={classes.root}>
         { props.modal && <Modal status={props.modal.status} />}
        <Box className={classes.banner} style={{ backgroundImage: user.coverImg ? `url(${user.coverImg.url})` : `url(${CoverImg})` }}>
            <Container className={classes.bannerWrap}>
                <input type={'file'} accept='.jpg, .png, .jpeg' style={{display: 'none'}} ref={cover} onChange={(e)=> onCoverSelect(e)} />
                <Button variant='contained' color='primary' startIcon={<CameraAlt />} 
                style={{color: '#fff', textTransform: 'none', marginBottom: '2rem', background: '#ed8a2f42'}} disableElevation onClick={() => cover.current.click()}>Cover Image</Button>
            </Container>
        </Box> 

        <Container>
            <Grid container spacing={4}>
                <Grid item sm={3}>
                    <Card className={classes.post} elevation={0} style={{marginTop: '-6rem'}}>
                        <CardContent style={{textAlign: 'center', padding: '2rem 1.5rem'}}>
                            <Avatar className={classes.large} src={user.profile_pic ? user.profile_pic.url : null} />
                            <Typography variant='h6'>{user.username}</Typography>
                            <Typography variant='body1' paragraph color='textSecondary'>{user.email}</Typography>
                            <input type={'file'} accept='.jpg, .jpeg, .png' ref={browse} name='profile_pic' style={{display: 'none'}} onChange={(e) => onPhotoSelect(e)} />
                            <Button variant='contained' color='primary' style={{color: '#fff', borderRadius: '8px'}} disableElevation onClick={()=> browse.current.click()}>Upload Photo</Button>

                            <Divider style={{margin: '1rem 0'}} />

                            <Box className={classes.wrap}>
                                <span>
                                    <Typography>City</Typography>
                                    <Typography color='textSecondary'>{user.sellerInfo ? user.sellerInfo.city : '*********' }</Typography>
                                </span>
                                <span>
                                    <Typography>Country</Typography>
                                    <Typography color='textSecondary'>{user.sellerInfo ? user.sellerInfo.country : '*********' }</Typography>
                                </span>
                                <span>
                                    <Typography>Phone</Typography>
                                    <Typography color='textSecondary'>({user.sellerInfo.countryCode}){user.sellerInfo.phone}</Typography>
                                </span>
                            </Box>

                        </CardContent>
                    </Card>

                    {/* TOP SKILLS */}
                    <Card className={classes.post} elevation={0} style={{marginTop: '1.5rem'}}>
                        <CardContent style={{padding: '2rem 1.5rem'}}>
                            
                            
                            <Typography variant='h6' gutterBottom>Top Skills</Typography>   
                            <Typography color='textSecondary' paragraph>Add your top skills to here</Typography>
                            <Box marginBottom={'1rem'}>
                                    {
                                        user.sellerInfo.skills && user.sellerInfo.skills.map(el => {
                                            return (
                                                <Chip key={el} className={classes.socials} label={<Typography variant='body2'>{el}</Typography>} />
                                            )
                                        })
                                    }
                             
                            </Box>
                
                        </CardContent>
                    </Card>

                    <Card className={classes.post} elevation={0} style={{marginTop: '1.5rem'}}>
                        <CardContent style={{padding: '2rem 1.5rem'}}>
                            
                            <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                <Typography variant='h6' gutterBottom>Social Links</Typography>
                                <IconButton style={{background: grey[300], padding:'.5rem', marginTop: '-10px'}} onClick={()=>setOpen(true)}><Add fontSize='small' /></IconButton>
                            </Box>
                            <Typography color='textSecondary' paragraph>Add your social handles to display here</Typography>
                            <Box marginBottom={'1rem'}>
                                { user.links &&  user.links.facebook ? <Chip className={classes.socials} icon={<Facebook />} label={<Typography variant='body2'>Facebook</Typography>} /> : null }
                                { user.links && user.links.linkedin ? <Chip className={classes.socials} icon={<LinkedIn />} label={<Typography variant='body2'>LinkedIn</Typography>} />  : null}
                                { user.links && user.links.instagram  ? <Chip className={classes.socials} icon={<Instagram />} label={<Typography variant='body2'>Instagram</Typography>} /> : null}
                                { user.links && user.links.twitter ? <Chip className={classes.socials} icon={<Twitter />} label={<Typography variant='body2'>Twitter</Typography>} /> : null }
                                { user.links && user.links.github  ? <Chip className={classes.socials} icon={<GitHub />} label={<Typography variant='body2'>GitHub</Typography>} /> : null }
                            </Box>
                            
                
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item sm={9} style={{padding: '2rem'}}>
                    <Box marginBottom={'1rem'} marginTop={'2rem'}>
                        <Typography variant='h6' style={{fontSize: '1.5rem'}}>Profile</Typography>
                    </Box>
                    <Card elevation={0} variant='outlined' className={classes.card}>
                        <CardContent style={{padding: '2.5rem'}}>
                            <Grid container spacing={3} >
                                <Grid item sm={12}>
                                    <TextField variant='outlined' label='About Me' multiline rows={6} value={formInput.about} onChange={(e)=>dispatch({type:"ABOUT", payload: e.target.value})} fullWidth  />
                                </Grid>
                                <Grid item sm={12}>
                                    <Autocomplete multiple options={skills} limitTags={2} value={formInput.skills} onChange={(e, values) => dispatch({ type: "SKILLS", payload: values }) } 
                                        renderInput={(params) => (
                                        <TextField {...params} className={classes.field}  variant='outlined' label='Top Skills' fullWidth  />
                                    )} />
                                </Grid>
                                <Grid item sm={12}>
                                    <Autocomplete multiple options={languages} limitTags={2} value={formInput.language} onChange={(e, values)=>dispatch({type:"LANGUAGE", payload: values})} 
                                    renderInput={(params) => (
                                        <TextField {...params} className={classes.field} variant='outlined'  label='Languages' fullWidth  />
                                    )} />
                                </Grid>
                                <Grid item sm={12} style={{display: 'flex', gap: '1rem'}} >
                                    <TextField type={'number'} variant='outlined' value={formInput.phone} label='Phone Number' 
                                    onChange={(e)=> dispatch({type:"PHONE", payload: e.target.value})} fullWidth placeholder={user.sellerInfo.phone}
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'> {user.sellerInfo.countryCode} </InputAdornment>
                                    }} 
                                    />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => onFormSubmit('profile')} className={classes.btn} 
                                    style={{color:'#fff', marginRight: '1rem'}} variant='contained' 
                                    endIcon={ <ArrowForward /> }
                                    disableElevation color='primary'> Save Profile </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box marginBottom={'1rem'} marginTop={'2rem'}>
                        <Typography variant='h6' style={{fontSize: '1.5rem'}}>Password</Typography>
                    </Box>
                    <Card elevation={0} variant='outlined' className={classes.card}>
                        <CardContent style={{padding: '2.5rem'}}>
                            <Grid container spacing={3} >
                               
                                <Grid item sm={12}>
                                    <TextField value={passInput.currentPassword} type={'password'} variant='outlined' label='Current Password' 
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'><Visibility className={classes.fieldIcon} /></InputAdornment>
                                    }}
                                    onChange={(e)=> passDispatch({type:"CURRENT", payload: e.target.value})} fullWidth  />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField value={passInput.newPassword} type={'password'} variant='outlined' label='New Password' InputProps={{
                                        endAdornment: <InputAdornment position='end'><VisibilityOff className={classes.fieldIcon} /></InputAdornment>
                                    }}
                                    onChange={(e)=> passDispatch({type:"NEW_PASS", payload: e.target.value})} fullWidth  />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField value={passInput.confirmPassword} type={'password'} variant='outlined' label='Confirm Password' InputProps={{
                                        endAdornment: <InputAdornment position='end'><VisibilityOff className={classes.fieldIcon} /></InputAdornment>
                                    }}
                                    onChange={(e)=> passDispatch({type:"CONFIRM", payload: e.target.value})} fullWidth  />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => onFormSubmit('password')} className={classes.btn} 
                                    style={{color:'#fff', marginRight: '1rem'}} variant='contained' 
                                    endIcon={ <ArrowForward /> }
                                    disableElevation color='primary'> Update Password </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

        </Container>
        
        {/* SOCIAL LINKS */}
        <Dialog open={open} >
        <DialogTitle >Social Accounts</DialogTitle>
        
        <DialogContent >
            <Box minWidth={'300px'} padding='2rem'>
                <TextField className={classes.field} value={linkInput.facebook} onChange={(e) => linkDispatch({type: "FB", payload: e.target.value})} variant='outlined' fullWidth label='Facebook' InputProps={{
                    endAdornment: <InputAdornment position='end'><Facebook style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={linkInput.linkedin} onChange={(e) => linkDispatch({type: "LK", payload: e.target.value})} variant='outlined' fullWidth label='LinkedIn' InputProps={{
                    endAdornment: <InputAdornment position='end'><LinkedIn style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={linkInput.instagram} onChange={(e) => linkDispatch({type: "IN", payload: e.target.value})} variant='outlined' fullWidth label='Instagram' InputProps={{
                    endAdornment: <InputAdornment position='end'><Instagram style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={linkInput.twitter} onChange={(e) => linkDispatch({type: "TW", payload: e.target.value})} variant='outlined' fullWidth label='Twitter' InputProps={{
                    endAdornment: <InputAdornment position='end'><Twitter style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={linkInput.github} onChange={(e) => linkDispatch({type: "GH", payload: e.target.value})} variant='outlined' fullWidth label='GitHub' InputProps={{
                    endAdornment: <InputAdornment position='end'><GitHub style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />

            </Box>
        </DialogContent>
        <DialogActions >
            <Button variant='outlined' onClick={closeHandle}>Cancel</Button>
            <Button variant='contained' disableElevation color='secondary' onClick={() => onFormSubmit('social')}>Add Account</Button>
        </DialogActions>
    </Dialog>

    {/* LOADER */}
    <Dialog open={loader} >
        <Box padding={'2.5rem'} textAlign={'center'}>
            <img src={User} alt='creating gig' width={'25%'}/>
            <Typography paragraph>Creating your seller account. Please wait...</Typography>
            <LinearProgress />
        </Box>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {editUserSettings, successModal, errorModal, editSellerSettings, editUserLinks, editUserPhoto, editUserCoverImage})(SellerSettings)