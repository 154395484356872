import { alpha, Box, Button, Dialog, Grid, InputAdornment, LinearProgress, Link, MenuItem, TextField, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowForward, Facebook, GitHub, Instagram, LinkedIn } from '@material-ui/icons';
import { Autocomplete, Rating } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles'
import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react'
import Freelancer from '../../assets/images/freelance.jpg'
import { connect } from 'react-redux'
import { createSeller, successModal, errorModal } from '../../actions/actions'
import User from '../../assets/images/user.gif'
import Modal from '../../components/Modal';

const useStyles = makeStyles(theme => ({
  field : {
    marginBottom: '1.6rem',
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '2rem'
  },
  btn: {
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
  wallpaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    backgroundImage: `url(${Freelancer})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  icon : {
    color: grey[400]
  }
}))



const CreateAccount = (props) => {
    const classes = useStyles();
    const [cities, setCity] = useState([]);
    const [field, setField] = useState([]);
    const [subfield, setSubField] = useState([]);
    const [loader, setLoader] = useState(false);
    const[page, setPage] = useState(0)
    const[caption, setCaption] = useState('Personal Details')
    const options = ["Ghana", "Nigeria", "Senegal", "Ivory Coast"]
    const skills = ['Photoshop', 'React','Nodejs', 'Adobe Suit', 'Illustrator','Website', 'Content Writing', 'Blogging', 'Voice Over', 'Wordpress', 'Flutter', 'Mobile Apps', 'SEO', 'Digital Marketing', 'Video Editing', 'Animation']
    const languages = ['English', 'French','Spanish',]
    const [zip, setZip] = useState('')

    //axios call to city api
    const getCity = async(country) => {
      try {
          const res = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {country})
          setCity(res.data.data);
          dispatch({type: "CITY", payload: res.data.data[0]})
          
      } catch (error) {
          console.log(error)
      }
  }

  useEffect(()=>{
    const fetchIndustries = async() => {
      const res = await axios.get("/api/web/v1/category")
      console.log(res.data.data)
      setField(res.data.data);
    }
    fetchIndustries()
  },[])

  useEffect(()=>{
    console.log(subfield)

  },[subfield])

    // filter Field Categories for subcategories
    const filterField = (val) => {
        const subs = field.filter((el) => el.category === val);
        console.log(subs)
        // set the subfields
        setSubField(Object.keys(subs[0].subcategories))
    }

    const setCountryCode = (val) => {
      if(val === "Ghana"){
        setZip("+233")
      }
      if(val === "Nigeria"){
        setZip("+234")
      }
      if(val === "Senegal"){
        setZip("+221")
      }
      if(val === "Ivory Coast"){
        setZip("+225")
      }
    }

    const initialState = {
      about: '',
      country: '',
      countryCode: zip,
      city: '',
      phone: '',
      category: '',
      subcategory: '',
      skills: [],
      language: [],
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      github: ''
    }
    const reducerFn = (state, action) => {
      switch (action.type) {
        case "ABOUT":
          return {...state, about: action.payload}
        case "COUNTRY":
          setCity([]);
          getCity(action.payload);
          setCountryCode(action.payload)
          return {...state, country: action.payload}
        case "CITY":
          return {...state, city: action.payload}
        case "PHONE":
          return {...state, phone: action.payload}
        case "INDUSTRY":
          filterField(action.payload)
          return {...state, category: action.payload}
        case "SERVICE_FIELD":
          return {...state, subcategory: action.payload}
        case "SKILLS":
          return {...state, skills: action.payload }
        case "LANGUAGE":
          return {...state, language: action.payload }
        case "FB":
          return {...state, facebook: action.payload }
        case "LK":
          return {...state, linkedin: action.payload }
        case "IN":
          return {...state, instagram: action.payload }
        case "TW":
          return {...state, twitter: action.payload }
        case "GH":
          return {...state, github: action.payload }
        case "RESET":
          return initialState
          
        default:
          return initialState;
      }
    }

    const [formInput, dispatch] = useReducer(reducerFn, initialState)

    const stepBack = () => {
      if(page === 1){
        setPage(0)
        setCaption('Personal Details')
      }
      if(page === 2){
        setPage(1)
        setCaption('Professional Info')
      }
    }

    const onFormSubmit = (e) => {
      e.preventDefault();
      if(page === 0){
        if(formInput.phone === '' || formInput.about === '' || formInput.country === '' || formInput.city === ''){
          return
        }
        setCaption('Professional Info')
        setPage(1);
      }
      if(page === 1){
        if(formInput.industry === '' || formInput.field === '' || formInput.skills.length === 0 || formInput.language.length === 0){
          return
        }
        setCaption('Social Accounts')
        setPage(2);
      }
      if(page === 2){
        // call action creators
        setLoader(true)
        setTimeout(async() => {
          const data = {...formInput, countryCode: zip}
          const res = await props.createSeller(data)
          if(res.status === 'success'){
            //reset the form
            setLoader(false)
            props.successModal('Your seller profile is ready. Please wait..')
            setTimeout(()=>{
              window.location.assign('/seller/dashboard')
            }, 2000)
        }else{
            setLoader(false)
            props.errorModal(res.data.message)
        }
        }, 2000);
      }
    }


  return (
    <div>
       { props.modal && <Modal status={props.modal.status} />}
        <Grid container>
            <Grid item lg={6}>

            {/* WRAPPER */}
            <Box height={'100vh'} display='flex' flexDirection={'column'} justifyContent='center' alignItems={'center'}>

              <Box width='60%' margin='0 auto'>
                <Typography variant='h5' className={classes.title}>{caption}<span style={{color: '#ED8A2F'}}>.</span></Typography>
                <Typography paragraph color='textSecondary' style={{marginBottom: '2rem'}} >
                  Let potential buyers know more about you and
                  your gained skills to provide them services
                </Typography>

                <form>
                {
                  page === 0 &&
                  <>
                      <TextField className={classes.field} variant='outlined' value={formInput.about} label='Tell Us A little About You' multiline rows={6} onChange={(e)=>dispatch({type:"ABOUT", payload: e.target.value})} fullWidth/>
                      <TextField className={classes.field} select variant='outlined' value={formInput.country} label='Country' fullWidth onChange={(e)=>dispatch({type:"COUNTRY", payload: e.target.value})}>
                        {
                          options.map((el)=> {
                            return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                          })
                        }                                               
                      </TextField>
                      <TextField className={classes.field} variant='outlined' value={formInput.city} label='Select City' select fullWidth onChange={(e)=>dispatch({type:"CITY", payload: e.target.value})}>
                        {
                          cities.map(city => {
                            return <MenuItem key={city} value={city}><Typography>{city}</Typography></MenuItem>
                          })
                        }
                      </TextField>
                      <TextField className={classes.field} value={formInput.phone} fullWidth variant='outlined' label='Phone' InputProps={{
                        startAdornment: <InputAdornment position='start'>{zip}</InputAdornment>
                      }} onChange={(e)=>dispatch({type:"PHONE", payload: e.target.value})} />
                  </>
                }
                {
                  page === 1 &&
                    <>
                    <TextField className={classes.field} select variant='outlined' defaultValue={''} value={formInput.category} label='Industry' fullWidth onChange={(e)=> dispatch({type:"INDUSTRY", payload: e.target.value})}>
                        {
                          field.map((el)=> {
                            return <MenuItem key={el.category} value={el.category}><Typography>{el.category}</Typography></MenuItem>
                          })
                        }                                               
                      </TextField>
                      <TextField className={classes.field} select variant='outlined' defaultValue={''} value={formInput.subcategory} label='Field of Service' fullWidth onChange={(e)=>dispatch({type:"SERVICE_FIELD", payload: e.target.value})}>
                        {
                          subfield.map((el, index)=> {
                            return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                          })
                        }                                               
                      </TextField>
                      <Autocomplete multiple options={skills} limitTags={2} value={formInput.skills} onChange={(e, values) => dispatch({ type: "SKILLS", payload: values }) } 
                        renderInput={(params) => (
                        <TextField {...params} className={classes.field}  variant='outlined' label='Top Skills' fullWidth  />
                      )} />
                      <Autocomplete multiple options={languages} limitTags={2} value={formInput.language} onChange={(e, values)=>dispatch({type:"LANGUAGE", payload: values})} 
                      renderInput={(params) => (
                        <TextField {...params} className={classes.field} variant='outlined'  label='Languages' fullWidth  />
                      )} />
                    </>
                }
                {
                  page === 2 && 
                  <>
                        <TextField variant='outlined' className={classes.field} fullWidth label='LinkedIn' value={formInput.linkedin} onChange={(e)=> dispatch({type: 'LK', payload: e.target.value})} InputProps={{
                        endAdornment : <InputAdornment position='end'><LinkedIn className={classes.icon} /> </InputAdornment>
                        }} />
                        <TextField variant='outlined' className={classes.field} fullWidth label='Facebook' value={formInput.facebook} onChange={(e)=> dispatch({type: 'FB', payload: e.target.value})} InputProps={{
                        endAdornment : <InputAdornment position='end'><Facebook className={classes.icon} /> </InputAdornment>
                          }} />
                        <TextField variant='outlined' className={classes.field} fullWidth label='Instagram' value={formInput.instagram} onChange={(e)=> dispatch({type: 'IN', payload: e.target.value})} InputProps={{
                          endAdornment : <InputAdornment position='end'><Instagram className={classes.icon} /> </InputAdornment>
                          }} />
                          <TextField variant='outlined' className={classes.field} fullWidth label='Twitter' value={formInput.twitter} onChange={(e)=> dispatch({type: 'TW', payload: e.target.value})} InputProps={{
                          endAdornment : <InputAdornment position='end'><GitHub className={classes.icon} /> </InputAdornment>
                          }} /> 

                          <TextField variant='outlined' className={classes.field} fullWidth label='GitHub' value={formInput.github} onChange={(e)=> dispatch({type: 'GH', payload: e.target.value})} InputProps={{
                          endAdornment : <InputAdornment position='end'><GitHub className={classes.icon} /> </InputAdornment>
                          }} />     
                  </>
                }
                <Box display='flex' gridGap='1rem'>
                  { page > 0 && <Button className={classes.btn} onClick={stepBack} variant='contained' color='secondary' disableElevation fullWidth>Previous</Button> }
                  <Button className={classes.btn} onClick={onFormSubmit} endIcon={<ArrowForward />} variant='contained' color='primary' disableElevation fullWidth>Continue</Button>
                </Box>
                <Link href='/'>
                  <Typography color='textSecondary' variant='body2' style={{textAlign: 'center'}}>Return to Homepage</Typography>
                </Link>
                </form>
              </Box>
            </Box>

            </Grid>
            
            <Grid item lg={6}>
                <Box height={'100vh'} className={classes.wallpaper}>

                  <Box padding={'3rem'} borderRadius='15px' bgcolor={alpha('#fff', 0.15)} width='70%' margin={'0 auto'} marginBottom='3rem' style={{backdropFilter: 'blur(4px)', color: '#fff'}}>
                    <Typography style={{marginBottom: '2rem'}} variant='h6'>"With Amaly, finding the best talent for my project just got interesting. Can't imagine completing projects without Amaly"</Typography>
                    
                    <Box display={'flex'} justifyContent='space-between'>
                      <span>
                        <Typography variant='h6'>Andie Lane</Typography>
                        <Typography variant='body2'>Catalog Agency</Typography>
                        <Typography variant='body2'>Web Designer</Typography>
                      </span>
                      <span>
                        <Rating value={5} readOnly />
                      </span>
                    </Box>

                  </Box>

                </Box>
            </Grid>
        </Grid>

      {/* LOADER */}
      <Dialog open={loader} >
        <Box padding={'2.5rem'} textAlign={'center'}>
            <img src={User} alt='creating gig' width={'25%'}/>
            <Typography paragraph>Creating your seller account. Please wait...</Typography>
            <LinearProgress />
        </Box>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {createSeller, successModal, errorModal})(CreateAccount)