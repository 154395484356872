import { Box, Button, Card, CardContent, CardHeader, Dialog, Grid, InputAdornment, LinearProgress, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import PageCrumbs from '../../components/PageCrumbs'
import { connect } from 'react-redux'
import Modal from '../../components/Modal'
import User from '../../assets/images/user.gif'
import { editUserProfile, errorModal, successModal, editUserSettings } from '../../actions/actions'
import { ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'


const useStyles = makeStyles(theme => ({
    root: {
        '& *' : {
            borderRadius: '10px',
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: `1px solid ${theme.primaryColor}`
            }
          }
    },
    title: {
        fontWeight: 500,
        marginBottom: '-1rem'
    },
    large : {
        width: theme.spacing(10),
        height: theme.spacing(10),
        borderRadius: '50px'
    },
    card : {
        marginTop: '2rem',
        padding: '1rem',
        '&:hover': {
            boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
          }
    },
    btn : {
        padding: '.6rem 1.8rem',
        borderRadius: '10px',
        textTransform: 'none',
        fontSize: '.9rem',
        '&:hover': {
            background: theme.secondaryColor
        }
    }
}))

const Profile = (props) => {
    const classes = useStyles()
    const [showCurrent, setShowCurrent] = useState(false);
    const [showNew, setShowNew] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [loader, setLoader] = useState(false)

    const passwordReducerFn = (state, action)=> {
        switch (action.type) {
        case "CURRENT":
            return {...state, currentPassword: action.payload}
        case "NEWPASS":
            return {...state, newPassword: action.payload}
        case "CONFIRM":
            return {...state, confirmPassword: action.payload}
        case "RESET_PASS":
            return { currentPassword: '', newPassword: '', confirmPassword: ''}
        default:
            return state;
        }
    }

    const reducerFn = (state, action) => {
        switch (action.type) {
            case "FNAME":
                return {...state, fname: action.payload}
            case "LNAME":
                return {...state, lname: action.payload}
            case "RESET":
                return {fname: '', lname: '', phone: ''}
            default:
                return state;
        }
    }
    const [formInput, dispatch] = useReducer(reducerFn, {fname: '', lname: ''})
    const [passInput, passDispatch] = useReducer(passwordReducerFn, {currentPassword: '', newPassword: '', confirmPassword: ''})

    const onFormSubmit = (val) => {
        if(val === 'personal'){
            if(formInput.fname === '' && formInput.lname === '' ){
                return
            }
            setLoader(true)
            setTimeout(async()=>{
                const res = await props.editUserProfile(formInput);
                if(res === 'success'){
                    //reset the form
                    dispatch({type: "RESET"})
                    setLoader(false)
                    props.successModal('Settings update successful')
                }else{
                    setLoader(false)
                    props.errorModal('Sorry. Something went wrong. Please try again')
                }
            },2000)
        }
        if(val === 'password'){
            //do checks
            if(passInput.currentPassword === '' || passInput.newPassword === '' || passInput.confirmPassword === ''){
                return
            }
            if(passInput.newPassword !== passInput.confirmPassword ){
                props.errorModal('Invalid. Passwords do not match')
                return
            }
            // call action creators
            setLoader(true)
            setTimeout(async()=>{
                const res = await props.editUserSettings(passInput);
                if(res.status === 'success'){
                    //reset the form
                    passDispatch({type: "RESET_PASS"})
                    setLoader(false)
                    props.successModal('Password update successful')
                }else{
                    setLoader(false)
                    props.errorModal(res.message)
                }
            },2000)
        }
    }

  return (
    <div className={classes.root}>
        { props.modal && <Modal status={props.modal.status} />}
        <Typography variant='h5' className={classes.title}>Settings</Typography>
        <PageCrumbs link1={{name:'Amalyapp', path: '/'}} link2={{name:'Account', path: '/account/profile'}} link3={'Settings'} />
        <Box>
        
            <Card elevation={0} variant='outlined' className={classes.card}>
            <CardHeader title='Personal Details' style={{padding: '.5rem 1rem'}} />
            <CardContent>
                <Grid container spacing={3} >
                    <Grid item sm={12}>
                        <TextField value={formInput.fname} variant='outlined' label='Firstname' onChange={(e)=>dispatch({type:"FNAME", payload: e.target.value})} fullWidth  />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField value={formInput.lname} variant='outlined' label='Lastname' onChange={(e)=> dispatch({type:"LNAME", payload: e.target.value})} fullWidth  />
                    </Grid>
                    <Grid item>
                        <Button onClick={()=>onFormSubmit('personal')} className={classes.btn} 
                        style={{color:'#fff', marginRight: '1rem'}} variant='contained' 
                        endIcon={ <ArrowForward /> }
                        disableElevation color='primary'> Update Details </Button>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>

        <Card elevation={0} variant="outlined" className={classes.card}>
        <CardHeader title='Password & Security' style={{padding: '.5rem 1rem'}} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item sm={12} lg={6}>
                <TextField variant="outlined" value={props.currentUser.email} label="Email Address" fullWidth disabled />
              </Grid>
              <Grid item sm={12} lg={6}>
                <TextField value={passInput.currentPassword} onChange={(e)=> passDispatch({type:"CURRENT", payload: e.target.value})}
                  variant="outlined" label="Current Password" type={showCurrent ? 'text' : 'password'} fullWidth
                  InputProps={{ endAdornment: <InputAdornment position='end'>
                    { showCurrent ?  <Visibility style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setShowCurrent(!showCurrent)} /> :  <VisibilityOff style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setShowCurrent(!showCurrent)} />}
                    </InputAdornment>
                  }}
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <TextField value={passInput.newPassword} type={showNew ? 'text' : 'password'} onChange={(e)=> passDispatch({type:"NEWPASS", payload: e.target.value})}
                variant="outlined" label="New Password"  InputProps={{
                    endAdornment: <InputAdornment position='end'> { showNew ?  <Visibility style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setShowNew(!showNew)} /> :  <VisibilityOff style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setShowNew(!showNew)} />}
                    </InputAdornment>
                }} fullWidth />
              </Grid>
              <Grid item sm={12} lg={6}>
                <TextField value={passInput.confirmPassword} type={confirm ? 'text' : 'password'} onChange={(e)=> passDispatch({type:"CONFIRM", payload: e.target.value})}
                  variant="outlined" label="Confirm Password" fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                    {confirm ?  <Visibility style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setConfirm(!confirm)} /> :  <VisibilityOff style={{color: `${grey[400]}`, cursor: 'pointer'}} onClick={()=>setConfirm(!confirm)} />}
                    </InputAdornment>
                  }}
                />
              </Grid>
              <Grid item>
                <Button className={classes.btn} style={{ color: "#fff", marginRight: "1rem" }} variant="contained" disableElevation
                  color="primary" endIcon={<ArrowForward />} onClick={()=>onFormSubmit('password')}> Update
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        </Box>

        {/* LOADER */}
      <Dialog open={loader} >
        <Box padding={'2.5rem'} textAlign={'center'}>
            <img src={User} alt='creating gig' width={'25%'}/>
            <Typography paragraph>Updating your settings. Please wait.</Typography>
            <LinearProgress />
        </Box>
      </Dialog>

    </div>
  )
}

const mapStateToProps =(state) => {
    return state
}

export default connect(mapStateToProps, {editUserProfile,editUserSettings, errorModal, successModal})(Profile)