import { Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    wrap : {
        position: 'relative',
        overflow: 'hidden',
        height: '22rem',
        borderRadius: '10px',
        '&:hover #imgBox' :{
            transform: 'scale(1.1)'
        }
    },
    inner : {
        height: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '10px',
        transition: 'all .3s ease'
    },
    title: {
       position: 'absolute',
       bottom: '10%',
       left: '10%',
       color: '#fff',
    }
}))
const CategoryItem = ({title, image, link}) => {
    const classes = useStyles()


  return (
    <>
        <Link href={link}>
            <Box className={classes.wrap} >
                <Box className={classes.inner} id='imgBox' style={{backgroundImage:`linear-gradient(360deg, #000000de, transparent), url(${image})`}}></Box>
                <Typography variant='body2' color='primary' >Learn more</Typography>
                <Typography variant='h6' className={classes.title}>{title}</Typography>
            </Box>

        </Link>
    </>
  )
}

export default CategoryItem