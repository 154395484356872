import axios from "axios";

// AUTH ACTION CREATORS //

export const authRequest = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/web/v1/auth/request");
      //console.log(res)
      if (res.data.status === "user found") {
        dispatch({ type: "NEW_USER", payload: res.data.data });
        dispatch({ type: "EXIT_LOADING" });
      }
      if (res.data.status === "no user found") {
        dispatch({ type: "NO_USER" });
        dispatch({ type: "EXIT_LOADING" });
      }
    } catch (error) {
      if(error.response.data.message.startsWith('Operation')){
        errorModal('Connection Timeout. Please check your internet connection');
      }else {
        errorModal('Sorry, connection timeout. Something went wrong');
      }

    }
  };
};

export const testAction = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/multiple", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      console.log(res)
      
    } catch (error) {
      console.log(error.response);
    }
  };
};

// REGISTER //
export const signUpUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(successModal("Creating your account..."));
      const res = await axios.post("/api/web/v1/auth/signup", data);
      //console.log(res)

      if (res.data.status === "success") {
        dispatch(successModal("Registration successful"));
        // redirect to verify page
        setTimeout(() => {
          dispatch({ type: "NEW_USER", payload: res.data.data });
        }, 1500);
      }
    } catch (error) {
      console.log(error.response);

      if (
        error.response.data.status === "failed" &&
        error.response.data.error.code === 11000
      ) {
        const errMessage = `Sorry, ${Object.keys(
          error.response.data.error.keyValue
        )} is already taken.`;
        dispatch(errorModal(errMessage));
      }
      if (
        error.response.data.status === "failed" &&
        error.response.data.error.name === "ValidationError"
      ) {
        const errMessage = error.response.data.message.split(":")[2].trim();
        dispatch(errorModal(errMessage));
      }
    }
  };
};

// VERIFY EMAIL
export const verifyEmail = (token) => {
  return async (dispatch) => {
    try {
      dispatch(successModal("Verifying your account. Please wait"));

      const res = await axios.patch(`/api/web/v1/verify-email/${token}`);
      if (res.data.status === "success") {
        dispatch(successModal("Verification successful. Please wait.."));

        setTimeout(() => {
          dispatch({ type: "LOGIN_USER", payload: res.data.data });
        }, 1500);
      }
    } catch (error) {
      if (error.response.data.status === "failed") {
        dispatch(errorModal(error.response.data.message));
      }
      console.log(error.response);
    }
  };
};

// RESEND EMAIL VERIFICATION
export const resendEmail = () => {
  return async (dispatch) => {
    try {
      const res = axios.get("/api/web/v1/auth/resend-email");
      if ((await res).data.status === "success") {
        dispatch(
          successModal("Verification email sent. Please check your inbox")
        );
      }
    } catch (error) {
      if (error.response.data.status === "failed") {
        dispatch(errorModal(error.response.data.message));
      }
    }
  };
};

// UPDATE USER PHOTO
export const editUserPhoto = (data) => {
  return async(dispatch) => {
      try {
        const res = await axios.patch("/api/web/v1/u/account/photo", data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        //console.log(res.data);
        dispatch({ type: "NEW_USER", payload: res.data.data });
        return res.data

      } catch (error) {
        //console.log(error.response)
       return error.response
      }
  };
};

// UPDATE USER COVER IMG
export const editUserCoverImage = (data) => {
  return async (dispatch) => {
    try {
            const res = await axios.patch("/api/web/v1/u/account/cover-img", data, {
              headers: {
                "content-type": "multipart/form-data",
              },
            });
            // console.log(res.data);
            dispatch({ type: "NEW_USER", payload: res.data.data });
            return res.data

        } catch (error) {
          //console.log(error.response)
          return error.response
        }
  };
};

// UPDATE USER PROFILE
export const editUserProfile = (data) => {
  return async (dispatch) => {
    try {
            const res = await axios.patch("/api/web/v1/u/account/profile", data);
            // console.log(res.data);
            dispatch({ type: "NEW_USER", payload: res.data.data });
            return res.data.status

        } catch (error) {
          //console.log(error.response)
          return error.response.data.status
        }
  };
};



// UPDATE USER SETTINGS
export const editUserSettings = (data) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch("/api/web/v1/u/account/settings", data);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data

      } catch (error) {
        //console.log(error.response)
       return error.response.data
      }
  };
};

// UPDATE USER LINKS
export const editUserLinks = (data) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch("/api/web/v1/u/account/links", data);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data

      } catch (error) {
        //console.log(error.response)
       return error.response.data
      }
  };
};

// FORGOT PASSOWRD
export const forgotUserPassword = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/web/v1/auth/forgotpassword", data);
      if(res.data.status === 'success'){
       dispatch(successModal('Reset email sent to your inbox'))
      }
    } catch (error) {
      //console.log(error.response)
      dispatch(errorModal(error.response.data.message));
    }
  };
};

// RESET PASSOWRD
export const resetUserPassword = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/web/v1/auth/resetpassword/${token}`, data);
      if(res.data.status === 'success'){
       dispatch(successModal('Password reset successful. Please login'))
       setTimeout(()=>{
         window.location.assign('/auth/login')
       },1500)
      }
    } catch (error) {
      //console.log(error.response)
      dispatch(errorModal(error.response.data.message));
    }
  };
};



// LOGIN USER
export const loginUser = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/web/v1/auth/login", data)
        dispatch({ type: "LOGIN_USER", payload: res.data.data })
        return res.data

    } catch (error) {
      // console.log(error.response);
      return error.response.data
    }
  };
};

// LOGOUT USER
export const logoutUser = () => {
  return async (dispatch) => {
    try {
      const res = await axios.delete("/api/web/v1/auth/logout");
      if (res.data.status === "success") {
        dispatch({ type: "LOGOUT_USER" });
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error.response);
    }
  };
};

// SELLER ACCOUNT ACTION CREATORS //

export const createSeller = (data) => {
  return async (dispatch) => {
      try {
        const res = await axios.post("/api/web/v1/s/account/create", data);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data

      } catch (error) {
        console.log(error.response);
        return error.response
      }
  };
};

// CREATE NEW GIG //

export const createNewGig = (data) => {
  return async (dispatch) => {
      try {
        const res = await axios.post("/api/web/v1/gig", data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        console.log(res.data)
        return res.data.status
      } 
      catch (error) {
        console.log(error.response);
        return error.response.data.status
      }
  };
};

// FETCH SELLER GIGS
export const getSellerGigs = (id) => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/gig/seller/${id}`)
      console.log(res.data);
      if(res.data.status === 'success'){
        dispatch({type: "SELLER_GIGS", payload: res.data.data})
      }

    } catch (error) {
      console.log(error.response.data)
      dispatch(errorModal('Sorry, having trouble fetching gigs. Please refresh'))
    }
  }
}

// UPDATE SELLER SETTINGS
export const editSellerSettings = (data, id) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch(`/api/web/v1/s/account/update/${id}`, data);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data

      } catch (error) {
        console.log(error.response)
       return error.response.data
      }
  };
}

// SELLER AVAILABLITY
export const setAvailability = (id, val) => {
  return async (dispatch) => {
    try {
      if(val){
        const res = await axios.patch(`/api/web/v1/s/account/availability-true/${id}`);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data
      }else{
        const res = await axios.patch(`/api/web/v1/s/account/availability-false/${id}`);
        // console.log(res.data);
          dispatch({ type: "NEW_USER", payload: res.data.data });
          return res.data
      }

    } catch (error) {
      console.log(error.response)
      return error.response.data
    }
};
}

// FETCH CATEGORY & GIGS IN CATEGORY
export const getCategory = (name) => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/category/${name}`)
      return res.data

    } catch (error) {
      return error.response.data
    }
  }
}

// FETCH SELLER DETAILS
export const getSellerDetails = (name) => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/seller/${name}`)
      return res.data

    } catch (error) {
      return error.response.data
    }
  }
}

// FETCH ALL GIGS
export const getAllGigs = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/gig`)
      //console.log(res.data)
      dispatch({ type: "ALL_GIGS", payload: res.data.data });

    } catch (error) {
      //console.log(error.message.data)
    }
  }
}

// DELETE A GIG
export const deleteGig = (id) => {
  return async(dispatch) => {
    try {
      const res = await axios.delete(`/api/web/v1/gig/${id}`)
      dispatch({ type: "SELLER_GIGS", payload: res.data.data });
      return res.data
      
    } catch (error) {
      return error.response.data
    }
  }
}



// ALL CHAT CREATORS
export const getChatUsers = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get('/api/web/v1/chat')
      console.log(res.data);
      if(res.data.status === 'success'){
        dispatch({type: "CHAT_USERS", payload: res.data.data})
      }

    } catch (error) {
      console.log(error.response.data)
    }
  }
}

 
export const newChat = (data) => {
  return async(dispatch) => {
    try {
     await axios.post('/api/web/v1/chat', data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      //console.log('from the New Chat action:')
      //console.log(res.data.data)
      // if(res.data.status === 'success'){
      //   dispatch({type: "UPDATE_CHAT", payload: res.data.data})
      // }
    } catch (error) {
      console.log(error.response.data)
    }
  }
}

export const getCurrentChat = (id) => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/chat/${id}`)
      //console.log(res.data.data)
      dispatch({type: "CURRENT_CHAT", payload: res.data.data})
    } catch (error) {
      console.log(error.response.data)
    }
  }
}

export const realtimeChat = (data) => {
  console.log(data)
  return {
    type: 'UPDATE_CHAT',
    payload: data
  }
}

// FETCH ALL CATEGORIES
export const getAllCategories = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get("/api/web/v1/category")
      dispatch({type: "ALL_CATEGORIES", payload: res.data.data})
    } catch (error) {
      console.log(error.response)
    }
  }
}


// ORDER ACTIONS
export const newOrder = (data) => {
  return async(dispatch) => {
    try {
      const res = await axios.post(`/api/web/v1/g/order`, data)
      //console.log(res.data)
      return res.data

    } catch (error) {
      return error.response.data
    }
  }
}

export const getUserOrders = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get("/api/web/v1/g/order")
      dispatch({type: "USER_ORDERS", payload: res.data.data})
    } catch (error) {
      console.log(error.response)
    }
  }
}

export const getSellerOrders = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get("/api/web/v1/g/order/seller")
      dispatch({type: "SELLER_ORDERS", payload: res.data.data})
    } catch (error) {
      console.log(error.response)
    }
  }
}

export const acceptOrder = (id) => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/in-progress/${id}/order`)
      dispatch({type: "SELLER_ORDERS", payload: res.data.data})
      return res.data
    } catch (error) {
      return error.response.data
    }
  }
}

// NOTIFICATIONS ACTIONS
export const userNotifications = () => {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/web/v1/notifications`)
      //console.log(res.data)
      dispatch({type: "USER_NOTIFICATIONS", payload: res.data.data})

    } catch (error) {
      console.log(error.response)
    }
  }
}

export const readNotification = (id) => {
  return async(dispatch) => {
    try {
      const res = await axios.patch(`/api/web/v1/notification/${id}`)
      //console.log(res.data)
      dispatch({type: "USER_NOTIFICATIONS", payload: res.data.data})

    } catch (error) {
      console.log(error.response)
    }
  }
}

// MODAL - ERROR ACTIONS CREATORS //

export const successModal = (message) => {
  return {
    type: "SUCCESS_MODAL",
    payload: message,
  };
};

export const errorModal = (message) => {
  return {
    type: "ERROR_MODAL",
    payload: message,
  };
};

export const resetModal = () => {
  return {
    type: "RESET_MODAL",
  };
};
