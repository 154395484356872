import { Box, Button, Chip, Container, Dialog, IconButton, InputAdornment, LinearProgress, MenuItem, Popover, TextField, Typography } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { Delete, Edit, MoreVert, Search, Tune, Visibility } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import PageCrumbs from '../../components/PageCrumbs'
import { deleteGig, errorModal, successModal } from '../../actions/actions'
import { connect } from 'react-redux'
import User from '../../assets/images/user.gif'
import Loader from '../../components/Loader'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '-2rem',
    padding: '0 1rem',
    paddingTop: '2.5rem',
    background: '#b4b4b412',
    '& *' : {
      borderRadius: '10px',
    },
  "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`
      }
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
        borderRadius: 0
    }
  },
  gigImg: {
    width: '6rem',
    height: '5rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
}))

const SellerGigs = (props) => {
  const { currentUser } = props
  const classes = useStyles(props)
  const [pageSize, setPageSize] = useState(10)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(false)

  // useEffect(()=>{
  //   getSellerGigs(currentUser.id)
  // },[getSellerGigs, currentUser.id])

    const handleClose = () => {
        setAnchorEl(null);
    }
    const onhandleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const onSearch = () => {

    }
  
    const onGigDelete = (id) => {
      handleClose()
      setLoader(true)

      setTimeout(async() => {
        const res = await props.deleteGig(id)
        if(res.status === 'success'){
          setLoader(false)
          props.successModal('Gig deleted successfully')
        }else{
          setLoader(false)
          props.errorModal('Sorry, something went wrong')
        }
      }, 1000);
    }


  const columns = [
    {field: 'user', headerName: 'Gig Details', flex: 1.1, renderCell: (params) => {
        return (
              <Box display={'flex'} gridGap='1rem'>
                  <Box className={classes.gigImg} 
                  style={{backgroundImage: `url(${params.row.gallery[0].url})`}}></Box>
                  <Box width='12rem' >
                      <Typography variant='h6' style={{ fontSize: '1rem' }} noWrap>{params.row.title}</Typography>
                      <Typography variant='body2' color='textSecondary' noWrap>{params.row.category}</Typography>
                      <Typography variant='body2' color='textSecondary'>{params.row.subcategory}</Typography>
                  </Box>
              </Box>
            )
        }
    }, 
    {field: 'category', headerName: 'Clicks', flex: .5, renderCell: (params) =>  <Typography>{params.row.analytics.clicks} clicks</Typography> }, 
    {field: 'analytics', headerName: 'Orders', flex: .5, renderCell: (params) => { return <Typography>{params.row.analytics.orders} orders</Typography> } },
    {field: 'tags', headerName:' Tags', flex: .5, renderCell: (params) => { 
      return (
         params.value.map(el => <Chip key={el} style={{marginRight: '10px'}} label={el} /> )
        )
      }},
    {field: '_id', headerName:' Action', flex: .3, renderCell: (params) => { 
      //console.log(params.value)
      return (
        <>
          <IconButton onClick={onhandleClick}>
              <MoreVert fontSize='small' />
           </IconButton> 

          {/* POPOVER */}
          <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}} >
       
              <MenuItem onClick={()=> window.location.assign(`/${currentUser.username.toLowerCase()}/${params.row.slug}`)}>
                  <Visibility fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                  <Typography>View</Typography>
              </MenuItem>
              <MenuItem onClick={()=>window.location.assign(`/seller/gigs/${params.value}/details`)}>
                  <Edit fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                  <Typography>Edit</Typography>
              </MenuItem>
              <MenuItem onClick={()=>onGigDelete(params.value)}>
                  <Delete fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                  <Typography>Delete</Typography>
              </MenuItem>

              </Popover>
        </>
      )
    }}
  ]


  const renderPage = () => {
    if(props.gigs){
      return (
        <>
            <Container>
                <Typography variant='h5' className={classes.title}>Gigs ({props.gigs ? props.gigs.length : 0})</Typography>
                <Box marginTop='-10px' display={'flex'} justifyContent='space-between' alignItems={'flex-start'}>
                  <PageCrumbs link1={{name:'Dashboard', path: '/seller/dashboard'}} link2={{name:'Gigs', path: '/seller/gigs'}} link3={'All Gigs'} />
                  <Button variant='contained' color='secondary' style={{height: '2.8rem', textTransform: 'none'}} href='/seller/gigs/new' disableElevation >Create New Gig</Button>
                </Box>

                <Box marginTop={'1rem'}>  
                    <TextField  variant='outlined' style={{ marginBottom: '20px', background: '#fff'}}
                        placeholder='Find a gig' fullWidth onChange={(e) => onSearch(e.target.value)}
                        InputProps={{ 
                        startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>,
                        endAdornment: <InputAdornment position='end'> <IconButton> <Tune fontSize='small' /> </IconButton>  </InputAdornment>
                        }}/>
                    <DataGrid autoHeight style={{background: '#fff'}}
                    pagination rows={props.gigs ? props.gigs : []} isRowSelectable={false} rowsPerPageOptions={[5, 10, 15, 20]}
                    rowHeight={120} columns={columns} getRowId={ row => row._id}
                    pageSize={pageSize} checkboxSelection 
                    onPageSizeChange={(newSize)=> setPageSize(newSize)}
                    />

                    </Box>
            </Container>

            {/* LOADER */}
          <Dialog open={loader} >
            <Box padding={'2.5rem'} textAlign={'center'}>
                <img src={User} alt='creating gig' width={'25%'}/>
                <Typography paragraph>Deleting your gig. Please wait.</Typography>
                <LinearProgress />
            </Box>
          </Dialog>
        
        </>
      )
    }

    return <Loader />
  }
  
  return (
    <div className={classes.root}>
        {renderPage()}
        
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, { deleteGig, errorModal, successModal})(SellerGigs)