import { Avatar, Badge, Box, MenuItem, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "1rem",
    borderBottom: `1px solid ${grey[300]}`,
  },
  iconBtn: {
    "& span": {
      color: "#fff",
    },
  },
  title: {
    fontWeight: 500,
    fontSize: "1rem",
  },
  unstarred: {
    color: grey[400],
    "&:hover": {
      color: theme.primaryColor,
    },
  },
  starred: {
    color: theme.primaryColor,
  },
}));
const MessageListItem = ({ onChatHandle, username }) => {
  const classes = useStyles();
  // const [value, setValue] = useState(false)

  return (
    <div>
      <MenuItem className={classes.wrap} onClick={onChatHandle}>
        <Avatar style={{ borderRadius: "50px" }} />
        <Box width={"65%"}>
          <Typography noWrap variant="h6" className={classes.title}>
            {username}
          </Typography>
          <Typography noWrap color="textSecondary" variant="body2">
            I will send the documents as soon as possible
          </Typography>
        </Box>
        <Box textAlign={"center"}>
          <Typography color="textSecondary" variant="body2">
            3 days
          </Typography>
          {/* <IconButton disabled className={classes.iconBtn} ><DoneAll className={classes.unstarred}/></IconButton> */}
          <Badge
            className={classes.iconBtn}
            badgeContent={2}
            variant="standard"
            color="primary"
            style={{ color: "#fff" }}
          ></Badge>
        </Box>
      </MenuItem>
    </div>
  );
};

export default MessageListItem;
