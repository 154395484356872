import { Box, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { GetApp } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Social from '../assets/images/social.jpg'



const useStyles = makeStyles(theme => ({

    wrap: {
        border: `1px solid ${grey[400]}`,
        overflow: 'hidden'
    },
    imgBox: {
        height: '10rem',
        backgroundImage: `url(${Social})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0 !important'
    }
}))


const Attachment = () => {
    const classes = useStyles()


  return (
        <div className={classes.wrap}>
            <Box className={classes.imgBox}></Box>
            <Box display='flex' gridGap='.4rem' padding={'1rem'}>
                <Typography noWrap color='textSecondary' variant='body2'>Design File</Typography>
                <Typography color='textSecondary' variant='body2' >(512KB)</Typography>
                <GetApp fontSize='small' color='primary' />
            </Box>
        </div>
    )
  }

export default Attachment