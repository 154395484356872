import { Avatar, Box, Card, CardContent, Chip, Grid, IconButton, InputAdornment, Link, MenuItem, TextField, Typography } from '@material-ui/core'
import { Cached, CancelOutlined, CheckCircleOutlined, ListAlt, Search, SyncAltOutlined, Visibility } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DataGrid } from '@material-ui/data-grid'
import React, {useState} from 'react'
import PageCrumbs from '../../components/PageCrumbs'
import ChatBox from '../../components/ChatBox'
import JobCard from '../../components/JobCard'
import { connect } from 'react-redux'
import Loader from '../../components/Loader'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '-2rem',
        '& *' : {
          borderRadius: '10px',
        },
      "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
          }
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderRadius: 0
        }
      },
      title: {
        fontWeight: 500,
        marginBottom: '-1rem'
      },
      card : { 
        padding: '1rem',
        borderRadius: '15px',
        '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
        }
      },
      menu : {
          padding: '1rem',
      },
      icon: {
          marginRight: '.7rem',
          color: theme.primaryColor
      },
      post: {
        border: `1px solid ${theme.primaryColor}`
    },
    postBg : {
        background: theme.primaryColor
    }, 
    orderImg : {
        height: 'auto',
        width: '5em',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
}))
const Orders = (props) => {
    const classes = useStyles()
    const [pageSize, setPageSize] = useState(5)
    const [select, setSelect] = useState('All Orders')
    const [filterOrders, setFilterorders] = useState(props.orders ? props.orders : [])

    const links = [
        {name: 'All Orders', path: '#', icon: <ListAlt className={classes.icon}/>},
        {name: 'In Progress', path: '#', icon: <Cached className={classes.icon}/>},
        {name: 'Completed', path: '#', icon: <CheckCircleOutlined className={classes.icon}/>},
        {name: 'Cancelled', path: '#', icon: <CancelOutlined className={classes.icon}/>},
        {name: 'Dispute', path: '#', icon: <SyncAltOutlined className={classes.icon}/>}
    ]
    const columns = [
      {field: 'gig', headerName: 'Order Details', flex: 1.1, renderCell: (params) => {
       // console.log(params)
          const packages = params.row.package[0].toUpperCase() + params.row.package.substring(1).toLowerCase()
          return (
              <Box display={'flex'} gridGap='1rem'>
                  <Box className={classes.orderImg} style={{ backgroundImage: `url(${params.row.gig.gallery[0].url})`,}}></Box>
                  <Box width='12rem' >
                      <Link color='textPrimary' href={`/${params.value.user.username.toLowerCase()}/${params.value.slug}`}><Typography variant='h6' style={{fontSize: '1rem'}} noWrap>{params.row.gig.title}</Typography></Link>
                      <Typography variant='body2' color='textSecondary'>{packages} Package | Qnty: {params.row.purchasedQty} </Typography>
                      <Typography variant='body2' color='textSecondary'>GH¢ {params.row.total.toLocaleString()} </Typography>
                  </Box>
              </Box>
              )
          }
      }, 
      {field: 'seller', headerName: 'Ordered From', flex: .6, renderCell: (params) => {
        return (
          <Box display={'flex'} gridGap='10px' alignItems={'center'}>
            <Avatar src={params.row.gig.user.profile_pic.url} style={{borderRadius: '50%'}} />
            <Link href={`/${params.row.gig.user.username.toLowerCase()}`}> <Typography noWrap>{params.row.gig.user.username}</Typography></Link>
           
          </Box>
        )
      }}, 
      {field: 'createdAt', headerName: 'Date & Time', flex: .6, renderCell: (params) => { 
        return (
          <Box display={'flex'} flexDirection='column'>
            <Typography>{  new Date(params.value).toDateString() }</Typography>
            <Typography color='textSecondary' variant='body2'>{  new Date(params.value).toLocaleTimeString() }</Typography> 
          </Box>
          )
        }},
      {field: 'status', headerName:' Status', flex: .4, renderCell: (params) => { return <Chip disabled label={params.value} />} },
      {field: 'id', headerName:' Action', flex: .4, renderCell: (params) => { return <><IconButton onClick={()=> window.location.assign(`/account/orders/${params.value}/details`)}><Visibility fontSize='small' /></IconButton> </>}}
    ]

  const onMenuClick = (val) => {
      if(val === 'All Orders') {
        setSelect(val)
        setFilterorders(props.orders)
        return
      }
        setSelect(val)
        const filtered = props.orders.filter(el => el.status === val);
        setFilterorders(filtered)
    
  }

  const onSearch = () => {

  }


  const renderPage = () => {
    if(props.userOrders){
      return (
        <>
          <Typography variant='h5' className={classes.title}>All Orders({ props.userOrders && props.userOrders.length > 0 ? props.userOrders.length : 0 })</Typography>
          <PageCrumbs link1={{name:'Account', path: '/account/profile'}} link2={{name:'Orders', path: '/account/orders'}} link3={'All Orders'} />
          
          <Grid container spacing={5}>
              <Grid item lg={3} md={3} sm={12}>

                  <Card elevation={0} variant='outlined' className={`${classes.card} ${classes.post}`}  >
                      <CardContent style={{padding: '0'}}>
                      { links.map((el,index) => {
                          return (
                          <MenuItem onClick={()=>onMenuClick(el.name)} selected={el.name === select} className={classes.menu} key={el.name}>
                              {el.icon}
                              <Typography>{el.name}</Typography> 
                          </MenuItem>
                          )
                      })}
                      </CardContent>
                  </Card>

                  {/* CHATBOX */}
                  <ChatBox />

                  {/* POST A GIG */}
                  <JobCard />

                  
              </Grid>

              <Grid item lg={9}>
                  <Box>
                      
                          <TextField  variant='outlined' style={{ marginBottom: '20px', background: '#fff'}}
                              placeholder='Find an order' fullWidth onChange={(e) => onSearch(e.target.value)}
                              InputProps={{ 
                              startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>
                              }}/>
                          <DataGrid autoHeight style={{background: '#fff'}}
                          pagination rows={props.userOrders ? props.userOrders : []} rowsPerPageOptions={[5, 10, 15, 20]}
                          rowHeight={120} columns={columns} 
                          pageSize={pageSize} checkboxSelection 
                          onPageSizeChange={(newSize)=> setPageSize(newSize)}
                          />

                  </Box>

              </Grid>
          </Grid>
        </>
      )
    }

    return <Loader />
  }



  return (
    <div className={classes.root}>
        {renderPage()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {})(Orders)