import { Avatar, Box, Button, Card, CardContent, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, BusinessCenter, CallMade, Edit, Facebook, GitHub, Instagram, LinkedIn, LocalMall, Star, Twitter } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import Cover from '../../assets/images/cover-image.png'
import Banner from '../../assets/images/sellerbanner.jpg'
import NotificationItem from '../../components/NotificationItem';
import { connect } from 'react-redux'


const useStyles = makeStyles(theme => ({
    root: {
        background: '#f8f8f8',
        marginTop:'-2rem'
    },
    banner : {
        backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 90%), transparent ), url(${Banner})`,
        backgroundSize: 'cover',
        backgroundPositionY: '50%',
        borderRadius: '15px', 
        marginTop: '2rem',
        color: '#fff',
    },
    cover : {
        height: '10rem',
        backgroundImage: user => user.coverImg ? `url(${user.coverImg.url})`  : `url(${Cover})`,
        backgroundSize: 'cover'
    },
    avatar: {
        marginTop: '-5rem',
        width: theme.spacing(12),
        height: theme.spacing(12),
        border: '5px solid white'
    },
    card : {
        marginTop: '1.5rem',
        borderRadius: '15px',
        // border: `1px solid ${grey[300]}`,
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
          }
    },
    iconBtn: {
        padding: '.3rem',
        background: grey[300]
    },
    subtitle : {
        fontSize: '1rem'
    },
    caption : {
        fontSize: '1rem'
    },
    skills: {
        margin: '3px'
    },
    labels: {
        background: theme.primaryColor,
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: '15px'
    },
    welcome: {
        width: '340px',
        margin: '-3.5rem'
    },
    btn: {
        padding: ".6rem 1.5rem",
        color: "#fff",
        borderRadius: "8px",
        "&:hover": {
          background: theme.secondaryColor,
        },
    },
    post: {
        borderRadius: '10px',
        border: `1px solid ${theme.primaryColor}`
    },
    socials: {
        margin: '5px'
    },
    field : {
        marginBottom: '1rem'
    }
}));


const SellerDashboard = (props) => {
    const { user } = props
    const classes = useStyles(user)
    const[open, setOpen] = useState(false);
    const skills = ['React','Nodejs','Flutter','Javascript','Material UI','HTML','CSS']
    const languages = ['English', 'French']
    const socials = [
        {title: 'FaceBook', icon: <Facebook fontSize='small' />},
        {title: 'Instagram', icon: <Instagram fontSize='small' />},
        {title: 'LinkedIn', icon: <LinkedIn fontSize='small' />},
        {title: 'GitHub', icon: <GitHub fontSize='small' />}
    ]


  return (
    <div className={classes.root}>

        <Container>
            <Grid container spacing={3}>

                <Grid item lg={3}>
                    <Card elevation={0} variant='outlined' className={classes.card}>
                        <CardContent style={{padding: 0}}>
                            <Box className={classes.cover}></Box>
                            <Box padding={'2rem'}>
                                <Box display='flex' flexDirection={'column'} alignItems='center' textAlign='center' gridGap={'1rem'}>
                                    <Avatar className={classes.avatar} src={ user.profile_pic ? user.profile_pic.url : null} />
                                    <span>
                                        <Typography variant='h6'>{user.username}</Typography>
                                        <Typography color='textSecondary'>{user.email}</Typography>
                                        <Typography paragraph variant='body2' color='textSecondary'>{user.sellerInfo.city} | {user.sellerInfo.country}</Typography>
                                        <Chip icon={<BusinessCenter /> } label={user.seller && user.sellerInfo.availability ? 'Available for work' : 'Not Available'} />
                                    </span>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                            
                    <Card elevation={0} variant='outlined' className={classes.card}>
                            <CardContent style={{padding: '1.5rem'}}>
                                {/* Inbox */}
                                <Box >
                                    <span style={{display:'flex', justifyContent: 'space-between',alignItems: 'center', marginBottom: '5px'}}>
                                        <Typography variant='h6' className={classes.subtitle}>Inbox (0)</Typography>
                                        <IconButton className={classes.iconBtn}><CallMade fontSize='small' /></IconButton>
                                    </span>
                                    <span >
                                        
                                    </span>
                                </Box>

                                <Divider style={{margin: '1rem 0'}} />
                                {/* <NotificationItem /> */}
                    
                        </CardContent>
                    </Card>

                    <Card className={classes.card} variant='outlined' elevation={0} style={{marginTop: '1.5rem'}}>
                        <CardContent style={{padding: '2rem 1.5rem'}}>
                            
                            <span style={{display:'flex', justifyContent: 'space-between',alignItems: 'center', marginBottom: '5px'}}>
                                <Typography variant='h6' className={classes.subtitle}>Social Links</Typography>
                                    <IconButton className={classes.iconBtn} href='/seller/settings'><CallMade fontSize='small' /></IconButton>
                            </span>
                            <Typography color='textSecondary' paragraph>Add your social handles to display here</Typography>
                            <Box>
                                <Chip className={classes.socials} disabled icon={<Facebook />} label={<Typography variant='body2'>Facebook</Typography>} />
                                <Chip className={classes.socials} disabled icon={<LinkedIn />} label={<Typography variant='body2'>LinkedIn</Typography>} />
                                <Chip className={classes.socials} disabled icon={<Instagram />} label={<Typography variant='body2'>Instagram</Typography>} />
                                <Chip className={classes.socials} disabled icon={<Twitter />} label={<Typography variant='body2'>Twitter</Typography>} />
                                <Chip className={classes.socials} disabled icon={<GitHub />} label={<Typography variant='body2'>GitHub</Typography>} />
                            </Box>
                            
                        </CardContent>
                    </Card>
                </Grid>




                <Grid item lg={9}>
                    <Card elevation={0} className={classes.banner}>
                        <CardContent style={{ padding: '2.5rem', height: '20rem', justifyContent: 'flex-end', alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                                <Typography variant='h5' style={{fontWeight: 600, fontSize: '1.8rem'}} gutterBottom>Standout from the Crowd</Typography>
                                <Typography paragraph >
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
                                    Placeat commodi eaque, ex eveniet quisquam iusto cumque? <br />
                                    Odio qui dolorum, magnam inventore atque dicta.
                                </Typography>
                                <Button size='large' color='primary' variant='contained'  className={classes.btn}>Get Started</Button>
                        </CardContent>
                    </Card>

                    <Grid container spacing={3}>
                        <Grid item lg={4}>
                            <Card elevation={0} className={classes.card}>
                                <CardContent style={{display:'flex', gap:'1rem', padding: '1rem'}}>
                                    <Avatar className={classes.labels}><LocalMall /></Avatar>
                                    <Box>
                                        <Typography variant='h6' className={classes.caption}>Total Income</Typography>
                                        <Typography color='textSecondary' >Ghc 850.00</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={4}>
                            <Card elevation={0} className={classes.card}>
                                <CardContent style={{display:'flex', gap:'1rem', padding: '1rem'}}>
                                    <Avatar className={classes.labels}><LocalMall /></Avatar>
                                    <Box>
                                        <Typography variant='h6' className={classes.caption}>Posted Gigs</Typography>
                                        <Typography color='textSecondary' >12 Gigs</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={4}>
                            <Card elevation={0} className={classes.card}>
                                <CardContent style={{display:'flex', gap:'1rem', padding: '1rem'}}>
                                    <Avatar className={classes.labels}><Star /></Avatar>
                                    <Box>
                                        <Typography variant='h6' className={classes.caption}>Reviews</Typography>
                                        <Typography color='textSecondary'>4.6 (126 reviews)</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    
                </Grid>
            </Grid>
        </Container>

        <Dialog open={open}>
        <DialogTitle >Social Accounts</DialogTitle>
        
        <DialogContent >
            <Box minWidth={'200px'}>
                <TextField className={classes.field} variant='outlined' fullWidth label='Facebook' InputProps={{
                    endAdornment: <InputAdornment position='end'><Facebook style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} variant='outlined' fullWidth label='LinkedIn' InputProps={{
                    endAdornment: <InputAdornment position='end'><LinkedIn style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} variant='outlined' fullWidth label='Instagram' InputProps={{
                    endAdornment: <InputAdornment position='end'><Instagram style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} variant='outlined' fullWidth label='Twitter' InputProps={{
                    endAdornment: <InputAdornment position='end'><Twitter style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} variant='outlined' fullWidth label='GitHub' InputProps={{
                    endAdornment: <InputAdornment position='end'><GitHub style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />

            </Box>
        </DialogContent>
        <DialogActions style={{padding: '1rem 2rem', }}>
            <Button variant='outlined' onClick={()=>setOpen(false)}>Cancel</Button>
            <Button variant='contained' disableElevation color='secondary'>Add Account</Button>
        </DialogActions>
    </Dialog>

    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(SellerDashboard)