import { Box, Button, Card, CardActions, CardContent, Chip, Container, Divider, FormControlLabel, Grid, InputAdornment, Popover, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import GigItem from '../components/GigItem'
import Loader from '../components/Loader'
import PageCrumbs from '../components/PageCrumbs'
import { connect } from 'react-redux'
import Files from '../assets/images/paper.png'


const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 600,
        fontSize: '1.7rem'
        
    },
    result : {
       
        fontSize: '1rem',
        fontWeight: 500
    },
    filters : {
        marginRight: '1rem',
        textTransform: 'none'
    },
    filterWrap: {
        margin: '3rem 0 2rem 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            marginLeft: 'auto'
        }
    },
    icon: {
        color: grey[400],
        marginLeft: '.5rem'
    },
    filterCard: {
        padding: '1.8rem 1.2rem'
    } 
}))
const SubCategory = (props) => {
    const { allCategories, allGigs } = props
    const classes = useStyles()
    const path = useLocation().pathname.split('/')[3]
    const category = useLocation().pathname.split('/')[2]
    const [title, setTitle] = useState('')
    const [currentGigs, setCurrentGigs] = useState([])
    const currentCategory = allCategories && allCategories.filter(el => el.slug === category)[0]
   

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter ] = React.useState()
    const [value, setValue ] = React.useState('')
    const open = Boolean(anchorEl);

    
    useEffect(() => {
        const titleCase = () => {
            const val = path.split('-')
            console.log(val)
            //val = ['Logo', 'Design']
            let title = val.map(item => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
            setTitle(title)
        }

        titleCase()
    }, [path])

    useEffect(()=>{
        if(allGigs){
            const gigs = allGigs.filter(gig => gig.subcategory === title)
            setCurrentGigs(gigs)
        }
    },[allGigs, title])


    const handleClick = (e, val) => {
        setAnchorEl(e.currentTarget)
        setFilter(val)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onFilterChange = (event) => {
        setValue(event.target.value)
       
      };

    const sortGigs = (val) => {
        const prices = currentGigs.sort((a, b) => {
            const price1 = JSON.parse(a.packages.basic).Price
            const price2 = JSON.parse(b.packages.basic).Price

            if(val === 'high'){
                return price2 - price1
            }
            if(val === 'low'){
                return price1 - price2
            }
            return 0 //deafult
    
        })
        setCurrentGigs(prices)
    }

    const filterGigs = (val) => {
        if(val === "Budget"){
            //do something
            setAnchorEl(null);
            return
        }
        if(val === "Delivery"){
            //do something
            setAnchorEl(null);
            return
        }
        if(val === "Rating"){
            //do something
            setAnchorEl(null);
            return
        }
        if(val === "Sorting"){
            sortGigs(value)
            setAnchorEl(null);
            return
        }
    }

    
    const renderPage = () => {
        if(allGigs){
            return (
                <Container >
                    <PageCrumbs link1={{name:'Amalyapp', path: '/'}} link2={{ name: allCategories && currentCategory.category, path: allCategories && `/category/${currentCategory.slug}` }} link3={title}/>
                    <Typography variant='h5' className={classes.title} gutterBottom>{title}</Typography>
                    <Typography color='textSecondary'>Stand out from the crowd with a logo that fits your brand personality</Typography>

                    {
                        currentGigs.length > 0 ?
                        <>
                            <Box className={classes.filterWrap} >
                                <Typography variant='body1' className={classes.result}>Showing {currentGigs.length} results</Typography>
                                <span>
                                    <Button variant='outlined' className={classes.filters} onClick={(e) => handleClick(e, 'Budget')} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Budget</Button>
                                    <Button variant='outlined' className={classes.filters} onClick={(e) => handleClick(e, 'Delivery')} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Delivery</Button>
                                    <Button variant='outlined' className={classes.filters} onClick={(e) => handleClick(e, 'Rating')} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Ratings</Button>
                                    <Button variant='outlined' className={classes.filters} onClick={(e) => handleClick(e, 'Sorting')} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Sorting</Button>
                                </span>
                                <Popover style={{marginTop: '.5rem'}} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                                >
                                <Card variant='outlined' elevation={0} >
                                        { filter === 'Budget' && 
                                        <CardContent className={classes.filterCard} style={{width: '16rem'}}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={6} md={6} xs={6}>
                                                    <TextField size="small" label='Min' fullWidth variant='outlined' placeholder='0' InputProps={{
                                                        startAdornment: <InputAdornment position='start'>Gh¢</InputAdornment>
                                                    }} />
                                                </Grid>
                                                <Grid item lg={6} md={6} xs={6}>
                                                    <TextField size="small" label='Max' fullWidth variant='outlined' placeholder='0' InputProps={{
                                                        startAdornment: <InputAdornment position='start'>Gh¢</InputAdornment>
                                                    }} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        }
                                        { filter === 'Delivery' &&
                                        <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                            <RadioGroup name="delivery-filter" value={value} onChange={onFilterChange} color='primary'>
                                                <FormControlLabel value="express" control={<Radio />} label="Express 24Hrs" />
                                                <FormControlLabel value="up-to-3days" control={<Radio />} label="Up to 3days" />
                                                <FormControlLabel value="up-to-5days" control={<Radio />} label="Up to 5days" />
                                                <FormControlLabel value="up-to-7days" control={<Radio />} label="Up to 7days" />
                                                <FormControlLabel value="anytime" control={<Radio />} label="Anytime" />
                                            </RadioGroup>
                                        </CardContent>
                                        }
                                        { filter === 'Sorting' &&
                                            <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                                <RadioGroup name="sorting-filter" value={value} onChange={onFilterChange}>
                                                     <FormControlLabel value="low" control={<Radio />} label="Lowest Price" />
                                                    <FormControlLabel value="high" control={<Radio />} label="Highest Price" />
                                                    <FormControlLabel value="none" control={<Radio />} label="None" />
                                                </RadioGroup>
                                            </CardContent>
                                        }
                                        { filter === 'Ratings' &&
                                            <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                                <RadioGroup name="ratings-filter" value={value} onChange={onFilterChange}>
                                                    <FormControlLabel value="4.5+" control={<Radio />} label="Above 4.5" />
                                                    <FormControlLabel value="4.5" control={<Radio />} label="4.5 Ratings" />
                                                    <FormControlLabel value="4.0" control={<Radio />} label="4.0 Ratings" />
                                                    <FormControlLabel value="3.5" control={<Radio />} label="3.5 Ratings" />
                                                    <FormControlLabel value="-3.0" control={<Radio />} label="Below 3.0" />
                                                </RadioGroup>
                                            </CardContent>
                                        }
                                    
                                    <Divider />
                                        <CardActions style={{padding: '.8rem'}}>
                                            <Button>Clear</Button>
                                            <Button variant='contained' disableElevation color='primary' style={{color: '#fff'}} onClick={()=>filterGigs(filter)} >Apply</Button>
                                        </CardActions>
                                    </Card>
                                </Popover>
                            </Box>
                        
                            <Box>
                                <Grid container spacing={3}>
                                    {currentGigs.map(item => {
                                        return (
                                            <Grid item lg={3} md={4} sm={6} xs={12} key={item.id}>
                                                <GigItem data={item}  />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        
                        </> :
                        <Grid item sm={12}>
                            <Box textAlign={'center'} padding='4rem'>
                                <img src={Files} width='20%' alt='no files' style={{display: 'block', margin: '0 auto', marginBottom: '1rem'}}/>
                                <Chip label='Sorry, no Gigs available'  />
                            </Box>
                        </Grid>
                    }

                </Container>    
            )
        }
        return <Loader />
    }
    


  return (
    <>
        {renderPage()}
    </>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(SubCategory)