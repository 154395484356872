import { Box, Button, Card, CardContent, Container, Dialog, Divider, Grid, LinearProgress, Link, MenuItem, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import { AssignmentOutlined, ChatOutlined, DoneAllOutlined, KeyboardArrowLeft, ScheduleOutlined,} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import {useLocation, Link as MyLink} from 'react-router-dom'
import PageCrumbs from '../../components/PageCrumbs'
import FullStack from '../../assets/images/fullstack.jpg'
import Requirements from '../../assets/images/requirements.svg'
import ChatBox from '../../components/ChatBox'
import JobCard from '../../components/JobCard'
import { grey } from '@material-ui/core/colors'
import DeliveryItem from '../../components/DeliveryItem'
import { connect } from 'react-redux'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import LoadGig from '../../assets/images/browser.gif'
import { acceptOrder, successModal, errorModal } from '../../actions/actions'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '2rem',
        '& *' : {
          borderRadius: '10px',
        },
      "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
          }
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderRadius: 0
        },
        '& .MuiDivider-vertical': {
            height: 'auto'
        }
      },
      title: {
        fontWeight: 500,
        marginBottom: '-1rem'
      },
      card : { 
        padding: '1rem',
        borderRadius: '15px',
        '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
        }
      },
      menu : {
          padding: '1rem',
      },
      icon: {
          marginRight: '.7rem',
          color: theme.primaryColor
      },
      post: {
        border: `1px solid ${theme.primaryColor}`
    },
    postBg : {
        background: theme.primaryColor
    }, 
    orderImg : {
        height: 'auto',
        width: '5em',
        backgroundImage: `url(${FullStack})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    navBtn: {
        textTransform: 'none',
        fontWeight: 400
    },
    btnWrap: {
        display: 'flex',
        justifyContents: 'center',
        marginBottom: '2rem',
        '& span': {
            marginLeft: 'auto',
            display: 'flex',
            gap: '.5rem'
        }
    },
    details: {
        display: 'flex',
        gap: '1rem'
    },
    table: {
        border: `1px solid ${grey[300]}`,
        '& *': {
            borderRadius: 0
        }
    }
}))
const SellerOrderDetails = (props) => {
    const { id } = props
    const classes = useStyles()
    const [loader, setLoader] = useState(false)
    const path = useLocation().pathname.split('/')[4]
    const currentOrder = props.sellerOrders && props.sellerOrders.filter(el => el.id === id)[0]
    console.log(currentOrder)

    const links = [
        {name: 'Details', path: `/account/orders/${id}/details`, icon: <AssignmentOutlined className={classes.icon}/>},
        {name: 'Message', path: `/account/orders/${id}/message`, icon: <ChatOutlined className={classes.icon}/>},
        {name: 'Requirements', path: `/account/orders/${id}/requirements`, icon: <DoneAllOutlined className={classes.icon}/>},
        {name: 'Delivery', path: `/account/orders/${id}/delivery`, icon: <ScheduleOutlined className={classes.icon}/>}
    ]

    const orderItems = [
        { id: 1, item: 'I will fix elementor error', qty: '1', duration: '1 Hour', price: '70'},
        {id: 2, item: 'I will fix Wordpress plugin error', qty: '1', duration: '1 Hour', price: '70'},
    ]


    const onOrderAccept = (id) => {
        setLoader(true)
        setTimeout( async() => {
            const res = await props.acceptOrder(id)
            if(res.status === 'success'){
                setLoader(false)
                props.successModal('Order accepted')
            }else{
                setLoader(false)
                props.errorModal('Sorry, something went wrong. Please try again')
            }
        }, 1000);
    }

    const onOrderCancel = (id) => {

    }

    const renderPage = () => {
        if(currentOrder){
            const selectedPackage = JSON.parse(currentOrder.gig.packages[`${currentOrder.package}`])
            return <>
            <Container>
            { props.modal && <Modal status={props.modal.status} />}
            <Typography variant='h5' className={classes.title}>Order #{props.sellerOrders && currentOrder.orderNumber}</Typography>
            <PageCrumbs link1={{name:'Dashboard', path: '/seller/dashboard'}} link2={{name:'Orders', path: '/seller/orders'}} link3={'Order Detail'} />
            
            <Grid container spacing={5}>
                <Grid item lg={3} md={3} sm={12}>
                    <Card elevation={0} variant='outlined' className={`${classes.card} ${classes.post}`}  >
                        <CardContent style={{padding: '0'}}>
                            { links.map((el) => {
                                return (
                                    <MyLink key={el.name} to={el.path} style={{color: 'inherit', textDecoration: 'none'}}>
                                        <MenuItem selected={el.name.toLowerCase() === path} className={classes.menu} >
                                            {el.icon}
                                            <Typography>{el.name}</Typography> 
                                        </MenuItem>
                                    </MyLink>
                                )
                            })}
                        </CardContent>
                    </Card>

                    {/* CHATBOX */}
                    <ChatBox />

                    {/* POST A GIG */}
                    <JobCard />

                    
                </Grid>

                <Grid item lg={9}>
                    <Box className={classes.btnWrap}>
                        <Button href='/seller/orders' size='large' className={classes.navBtn} startIcon={<KeyboardArrowLeft />}>Go Back</Button>
                        <span>
                        <Button onClick={()=> onOrderAccept(id)} size='large' disableElevation variant='text' className={classes.navBtn} disabled={currentOrder.delivery ? true : false} > Accept Order</Button>
                        <Divider orientation='vertical' />
                        <Button href='/seller/orders' size='large' disableElevation variant='text' className={classes.navBtn} > Cancel Order</Button>
                        <Divider orientation='vertical' />
                        <Button href='/seller/orders' size='large' disableElevation variant='text' className={classes.navBtn} > Resolve An Issue</Button>
                        </span>
                    </Box>


                    <Box>
                        <Card className={`${classes.card}`} variant='outlined'  elevation={0}>
                            <CardContent>

                            {/* DETAILS */}
                            { path === 'details' && 
                                <>
                                    <Box display={'flex'}>
                                        <Typography variant='h6' gutterBottom> Order #{currentOrder.orderNumber} </Typography>
                                        <Typography variant='h6' gutterBottom color='textSecondary' style={{marginLeft: 'auto'}}> GH¢ {parseInt(currentOrder.total).toLocaleString()}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box margin={'1.5rem 0'}>
                                        <Link href={`/${currentOrder.seller.username.toLowerCase()}/${currentOrder.gig.slug}`} color='textPrimary'> 
                                            <Typography variant='h6' style={{fontSize: '1.1rem', fontWeight: 400, width: '50%'}} gutterBottom noWrap> {currentOrder.gig.title} </Typography>
                                        </Link>
                                        <span className={classes.details}>
                                            <Typography variant='body1' gutterBottom color='textSecondary'> Ordered from <Link>{currentOrder.user.username}</Link> </Typography>
                                            <Divider orientation='vertical' /> 
                                            <Typography variant='body1' gutterBottom color='textSecondary'> Delivery Date : {currentOrder.delivery ? new Date(currentOrder.delivery).toDateString() : 
                                                <Tooltip arrow title={<Typography>Order must be accepted by the Seller to set the delivery date</Typography>}>
                                                    <span style={{textDecoration: 'underline', cursor: 'pointer'}}>Order not accepted</span>
                                                </Tooltip>}  
                                            </Typography>
                                            <Divider orientation='vertical' /> 
                                            <Link href='#' underline='none' ><Typography variant='body1' gutterBottom color='textSecondary' onClick={()=>{alert('Invoice Downloading...')}}> Download Invoice </Typography></Link>
                                        </span>
                                    </Box>

                                    <Box marginBottom={'1rem'}>
                                        <Table className={classes.table}>
                                            <TableHead style={{background: grey[300]}}>
                                                <TableRow>
                                                    <TableCell width={'50%'}><Typography>ORDER ITEM</Typography></TableCell>
                                                    <TableCell><Typography>QTY</Typography></TableCell>
                                                    <TableCell><Typography>DURATION</Typography></TableCell>
                                                    <TableCell align='center'><Typography>PRICE (GH¢)</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width={'50%'}>
                                                        <Typography>{currentOrder.gig.title}</Typography>
                                                        <Typography variant='body2' color='textSecondary'>{currentOrder.package} Package</Typography>
                                                    </TableCell>
                                                    <TableCell align='center'><Typography>{currentOrder.purchasedQty}</Typography></TableCell>
                                                    <TableCell><Typography>{selectedPackage.Delivery} Days</Typography></TableCell>
                                                    <TableCell align='center'><Typography>{`${parseInt(selectedPackage.Price).toLocaleString()}`}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                            <TableFooter style={{background: grey[200]}}>
                                                <TableRow>
                                                    <TableCell colSpan={3}><Typography>SubTotal</Typography></TableCell>
                                                    <TableCell align='center'><Typography>{`${parseInt(selectedPackage.Price).toLocaleString()}`}</Typography></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={3}><Typography>Service Charge</Typography></TableCell>
                                                    <TableCell align='center'><Typography>5%</Typography></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={3}><Typography style={{fontWeight: 600}}>TOTAL</Typography></TableCell>
                                                    <TableCell align='center'><Typography style={{fontWeight: 600}}>GH¢ {`${parseInt(currentOrder.total).toLocaleString()}`}</Typography></TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>

                                        
                                        </Box>

                                </>
                            }

                            {/* REQUIREMENTS */}
                            {
                                path === 'requirements' &&
                                <Box textAlign={'center'} padding='2rem 0'>
                                    <img src={Requirements} alt='require-img' style={{margin: '1rem 0'}} />
                                    <Typography variant='h6' gutterBottom><Link>jeremiah</Link> has all the requirements</Typography>
                                    <Typography paragraph color='textSecondary'>The seller stated you provided all the requirements needed. <br/>To view your prior conversation, view your Inbox.</Typography>
                                </Box>

                            }

                            {/* DELIVERY */}
                            {
                                path === 'delivery' &&
                                <Box>
                                    <DeliveryItem />
                                    <DeliveryItem />
                                </Box>
                            }


                            </CardContent>
                        </Card>
                        
                    </Box>

                    <Box>

                    </Box>

                </Grid>
            </Grid>
            
        </Container>

        {/* LOADER */}
        <Dialog open={loader} maxWidth={'sm'}>
            <Box padding={'2.5rem'} textAlign={'center'}>
                <img src={LoadGig} alt='creating gig' width={'25%'}/>
                <Typography paragraph>Updating your order. Please wait.</Typography>
                <LinearProgress />
            </Box>
        </Dialog>
        </>
        }

        return <Loader />
    }


  return (
    <div className={classes.root}>
        {renderPage()}
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {acceptOrder, successModal, errorModal})(SellerOrderDetails)