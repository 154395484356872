import { Box, Button, Container, Grid, InputAdornment, Link, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Email, Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Modal from '../../components/Modal'
import { connect } from 'react-redux'
import { forgotUserPassword } from '../../actions/actions'

const useStyles = makeStyles(theme => ({
    wrap : {
        display: 'flex',
        flexDirection: 'column',
        padding: '3.2rem',
        borderRadius: '16px',
        boxShadow: '-24px 24px 72px 8px rgb(145 158 171 / 24%)',
      },
      title : {
        fontSize: '1.8rem',
        marginBottom: '.5rem',
        fontWeight: 500
      },
      field : {
        marginBottom: '1.5rem',
        '& *': {
            borderRadius: '8px'
          },
        '& label.Mui-focused':{
          color: theme.primaryColor
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
          }
        }
      },
      fieldIcon: {
        color: grey[400],
        cursor: 'pointer',
      },
      btn: {
        background: theme.primaryColor,
        padding: '1rem 0',
        color: '#fff',
        borderRadius: '8px',
        marginBottom: '2rem',
        '&:hover' : {
          background: theme.secondaryColor
        }
      },

}))
const ForgetPassword = (props) => {
    const classes = useStyles()

    const reducerFn = (state, action) => {
      switch (action.type) {
        case "EMAIL":
          return {...state, email: action.payload}
        case "RESET":
          return { email: ''}    
        default:
          return state
      }
    }
    const [formInput, dispatch] = React.useReducer(reducerFn, { email : ''})
  
    const onFormSubmit = (e) => {
      e.preventDefault()
      if(formInput.email === ''){
        return
      }
      // call action creators
      props.forgotUserPassword(formInput)
    }


  return (
   <Container>

   {/* MODAL */}
    { props.modal && <Modal status={props.modal.status} /> }

        <Grid container style={{height: '70vh', alignItems:'center', justifyContent: 'center'}}>
            <Grid item sm={5}>
                <Box className={classes.wrap}>
                    <Typography className={classes.title} variant='h5'>Forgot Password</Typography>
                     <Typography style={{marginBottom: '2rem'}} paragraph color='textSecondary'>Please provide your email address to reset your password</Typography>

                      <TextField className={classes.field} variant='outlined' label='Email Address' value={formInput.email} onChange={(e)=>dispatch({type:"EMAIL", payload: e.target.value})}
                        helperText='' fullWidth type='email' InputProps={{ endAdornment: <InputAdornment position='start'> <Email className={classes.fieldIcon} /> </InputAdornment>}}
                     />
                      <Button className={classes.btn} disableElevation variant='contained' type='submit' fullWidth onClick={(e) => onFormSubmit(e)}>Reset Password</Button>
                </Box>
            </Grid>
        </Grid>

   </Container>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {forgotUserPassword})(ForgetPassword)