import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import NewGig from './NewGig'
import SellerDashboard from './SellerDashboard'
import SellerGigs from './SellerGigs'
import SellerOrders from './SellerOrders'
import SellerSettings from './SellerSettings'
import { connect } from 'react-redux'
import SellerNotifications from './SellerNotifications'
import SellerOrderDetails from './SellerOrderDetails'
import { getSellerGigs, getSellerOrders } from '../../actions/actions'


const SellerAccount = (props) => {
    const { currentUser, getSellerGigs, getSellerOrders } = props
    const path = useLocation().pathname
    const id = path.split('/')[3]

    useEffect(()=> {
        if(currentUser.seller){
            getSellerGigs(currentUser.id)
            getSellerOrders()
        }
    },[getSellerGigs, getSellerOrders, currentUser])


    const renderPage = () => {
        if(props.currentUser.seller){
            return (
                    <div>
                        { path === "/seller/dashboard" ? <SellerDashboard user={currentUser} /> : null }
                        { path === `/seller/orders/${id}/details` ? <SellerOrderDetails id={id} /> : null}
                        { path === "/seller/orders" ? <SellerOrders /> : null }
                        { path === "/seller/notifications" ? <SellerNotifications /> : null }
                        { path === "/seller/gigs" ? <SellerGigs /> : null }
                        { path === "/seller/gigs/new" ? <NewGig /> : null }
                        { path === "/seller/settings" ? <SellerSettings user={currentUser} /> : null }
                    </div>
            )
        }

        return <Navigate to='/' />
    }
  return (
      <>
        {renderPage()}
      </>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {getSellerGigs, getSellerOrders})(SellerAccount)