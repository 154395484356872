import { Box, Card, Container, Divider, Grid, IconButton, List, ListItem, ListItemIcon, Typography } from '@material-ui/core'
import React from 'react'
import Banner from '../components/Banner'
import Talent from '../assets/images/talent2.jpg'
import L1 from '../assets/images/b1.jpg'
import L3 from '../assets/images/b3.jpg'
import L4 from '../assets/images/b4.jpg'
import L5 from '../assets/images/b5.jpg'
import L6 from '../assets/images/b6.jpg'
import L7 from '../assets/images/b7.jpg'
import L8 from '../assets/images/focus.jpg'
import OwlCarousel from 'react-owl-carousel'
import { makeStyles } from '@material-ui/styles'
import { CheckCircle, DesktopWindows} from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'
import CategoryItem from '../components/CategoryItem'
import { connect } from 'react-redux'
import slugify from 'slugify'



const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        marginBottom: '2rem',
        '& .owl-nav': {
            display: 'flex',
            gap: '1rem',
            position: 'absolute',
            top: '-18%',
            right: '0%',
            '& .owl-prev, & .owl-next' : {
                color: theme.primaryColor,
                padding: '10px 12px',
            },
            '& .owl-next' : {
                background: '#d3d3d347',
                borderRadius: '8px'
            }
        },
    },
    clients : {
        '& .owl-item': {
            display: 'flex',
            justifyContent: 'center'
        },
       '& .owl-item > img' : {
           width : '80%',           
       }
    },
    wrap : {
        margin : '3rem 0 4rem 0'
    },
    card : {
        borderRadius: '.8rem',
        padding: '3rem',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px',
            borderColor: theme.primaryColor,

            '& .MuiIconButton-colorPrimary': {
                background: theme.primaryColor
            },
            '& .MuiSvgIcon-root': {
                color: '#fff'
            }
        },
    },
    iconBtn : {
        background: grey[100],
        marginBottom:'1rem',
        transition: 'all .2s ease'
    },
    icon : {
        fontSize: '2.5rem',
        padding: '.5rem',
        transition: 'all .2s ease'
    },
    listIcon : {
        fontSize: '1.8rem',
        padding: '.5rem',
        transition: 'all .2s ease'
    },
    boxRight : {
        padding: '4rem',
        background: theme.secondaryColor,
        color: '#fff',
    },
    boxLeft: {
        backgroundImage: `url(${Talent})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'none',
        height: '100%'
    },
    title : {
        fontSize: '1.5rem',
        marginBottom: '1.5rem',
        fontWeight: 500
    }
}))


const Home = (props) => {
    const { allCategories } = props
    const classes = useStyles()
    const logos = [ L1, L3, L4, L5, L6, L7, L8 ]

    const designCategory = allCategories && allCategories.filter(el => el.category === "Graphic Design")
    const devCategory = allCategories && allCategories.filter(el => el.category === "Development")
    
  return (

    <div className={classes.root}>
        <Banner />
        <Container >
            <Box className={classes.wrap}>
                <OwlCarousel className={classes.clients}
                 items={5} autoplaySpeed={1500} margin={20} loop={true} nav={false}
                    responsive={ { 1400 : {items : '6'}, 1200 : {items : '5'}, 760 : {items : '3'}, 340 : {items : '2'} } }
                > 
                    { logos.map(item => {
                        return (
                            <Box style={{width: '50%'}}  key={item}>
                                <img src={item} alt='clients' style={{filter: 'grayscale(1)', opacity: '0.4'}} />
                            </Box>
                        )
                    })}
                </OwlCarousel>
            </Box>

            <Box>
                <Typography variant='h4' gutterBottom style={{textAlign: 'center'}}>Popular Categories</Typography>
                <Typography variant='body1' color='textSecondary' paragraph style={{textAlign: 'center'}}>Looking for a designer, developer or plumber? We've got you covered</Typography>
                <Grid container spacing={3} style={{marginTop: '2rem'}}>
                    {
                        allCategories && allCategories.map(item => {
                            return (
                            <Grid item lg={3} md={4} sm={6} xs={12} key={item.category}>
                                <Card variant='outlined' className={classes.card} onClick={() => window.location.assign(`/category/${item.slug}`)}>
                                    <IconButton  color='primary' className={classes.iconBtn} size='medium'> 
                                        <DesktopWindows className={classes.icon} /> 
                                    </IconButton>
                                    <Typography variant='h6'>{item.category}</Typography>
                                    <Typography variant='body2' color='textSecondary'>{item.gigCount} Gigs available</Typography>
                                </Card>

                            </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>

            <Box>
                <Grid container style={{margin: '4rem 0', borderRadius: '1rem', overflow: 'hidden'}}>
                    <Grid item sm={6}>
                        <Box className={classes.boxLeft}>
                            <div style={{height: '30rem'}}></div>
                        </Box>

                    </Grid>
                    <Grid item sm={6} className={classes.boxRight}>
                        <Box >
                            <Typography variant='h4' gutterBottom >Find Talents and <br/>Work your way</Typography>
                            <Typography variant='body1' style={{color: '#fff', marginBottom: '4rem'}} paragraph >Meet clients you're excited to work with and take your career or business to new heights.</Typography>
                            <Divider style={{background: '#fff', marginBottom: '1rem'}} />
                            <List>
                                <ListItem>
                                    <ListItemIcon> <CheckCircle color="primary" className={classes.listIcon} /> </ListItemIcon>
                                    <Typography>Find high-quality services at every price point. No hourly rates, just project-based pricing.</Typography>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <ListItemIcon> <CheckCircle color="primary" className={classes.listIcon} /> </ListItemIcon>
                                    <Typography>Find the right freelancer to begin working on your project within minutes..</Typography>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Box className={classes.root}>
                <Typography variant='h4' className={classes.title} gutterBottom >Build your brand</Typography>
                <OwlCarousel
                 items={4} autoplaySpeed={1500} margin={20} loop={true}
                    nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                    responsive={ { 1400 : {items : '4'}, 1200 : {items : '4'}, 760 : {items : '3'}, 340 : {items : '2'} } }
                > 
                    { allCategories && Object.keys(designCategory[0].subcategories).map(sub => {
                        const name = slugify(`${sub}`, {replacement: '_', lower: true, trim: true, remove: /[-*+~.()'"!:@]/g })
                        const slug = slugify(`${sub}`, {replacement: '-', lower: true, trim: true, remove: /[-*+~.()'"!:@]/g })
                        return (
                            <CategoryItem key={sub} title={sub} image={designCategory[0].subcategory_photo[name].url} link={`/category/graphic-design/${slug}`} />
                            )
                        })
                    }
                
                </OwlCarousel>
                
            </Box>

            <Box className={classes.root}>
                <Typography variant='h4' className={classes.title} gutterBottom >Develop your apps</Typography>
                <OwlCarousel
                 items={4} autoplaySpeed={1500} margin={20} loop={true}
                    nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                    responsive={ { 1400 : {items : '4'}, 1200 : {items : '4'}, 760 : {items : '3'}, 340 : {items : '2'} } }
                > 
                    { allCategories && Object.keys(devCategory[0].subcategories).map(sub => {
                        const name = slugify(`${sub}`, {replacement: '_', lower: true, trim: true, remove: /[-*+~.()'"!:@]/g })
                        const slug = slugify(`${sub}`, {replacement: '-', lower: true, trim: true, remove: /[-*+~.()'"!:@]/g })
                        
                        return (
                            <CategoryItem key={sub} title={sub} image={devCategory[0].subcategory_photo[name].url} link={`/category/development/${slug}`} />
                            )
                        })
                    }
                </OwlCarousel>
                
            </Box>



        </Container>
    </div>
  )
}

const mapStateToProps = (state)=> {
    return state
}

export default connect(mapStateToProps, {})(Home)