import { Avatar, Box, Chip, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Image, Movie } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import moment from 'moment'

const useStyles = makeStyles(theme => ({

}))
const ChatItem = (props) => {
    const classes = useStyles()



  return (
    <div>
    {
        props.align ? 
        <Box display={'flex'} gridGap='.6rem' margin={'1.5rem 0'} marginRight='1rem' justifyContent='flex-end'>
               
            <span>
                <Box bgcolor={grey[100]} textAlign='right' padding='1rem' borderRadius={'0 20px 20px 20px'} >
                    <Typography variant='body2' style={{fontSize: '.75rem'}} color='textSecondary'> {moment(props.time).startOf('mini').fromNow()}</Typography>
                    <Typography>
                       {props.body}
                    </Typography>
                    {/* <Box>
                        <Chip icon={<Image />} label={'Design file'} variant='outlined' style={{background: '#fff', margin: '.5rem .3rem'}}/>
                        <Chip icon={<Movie />} label={'Video file'} variant='outlined' style={{background: '#fff', margin: '.5rem .3rem'}}/>
                    </Box> */}
                </Box>
            </span>

            <Avatar style={{borderRadius: '50px'}}/>

        </Box> 
        :
        <Box display={'flex'} gridGap='.6rem' margin={'1.5rem 0'}>
                <Avatar style={{borderRadius: '50px'}}/>
            <span>
                <Box bgcolor={grey[100]} padding='1rem' borderRadius={'0 20px 20px 20px'} >
                    <Typography variant='body2' style={{fontSize: '.75rem'}} color='textSecondary'> {moment(props.time).startOf('mini').fromNow()}</Typography>
                    <Typography>
                       {props.body}
                    </Typography>
                    {/* <Box>
                        <Chip icon={<Image />} label={'Design file'} variant='outlined' style={{background: '#fff', margin: '.5rem .3rem'}}/>
                        <Chip icon={<Movie />} label={'Video file'} variant='outlined' style={{background: '#fff', margin: '.5rem .3rem'}}/>
                    </Box> */}
                </Box>
            </span>

        </Box>
    }

        
    </div>
  )
}

export default ChatItem