import { Avatar, Badge, Box, Button, Card, CardContent, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@material-ui/core'
import { Add, CameraAlt, Facebook, GitHub, Instagram, LinkedIn, Twitter } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import CoverImg from '../../assets/images/cover-image.png'
import JobCard from '../../components/JobCard'
import Notifications from './Notifications'
import OrderDetails from './OrderDetails'
import Orders from './Orders'
import Profile from './Profile'
import { connect } from 'react-redux'
import { grey } from '@material-ui/core/colors'
import User from '../../assets/images/user.gif'
import { editUserLinks, successModal, errorModal, editUserPhoto, editUserCoverImage } from '../../actions/actions'


const useStyles = makeStyles(theme => ({
    root : {
        marginTop: '-2rem',
        background: '#b4b4b412'
    },
    banner: {
        height: '15rem',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    bannerWrap: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'flex-end',
    },
    large : {
        margin: '.5rem auto',
        width: theme.spacing(15),
        height: theme.spacing(15),
        border: `1px solid ${theme.primaryColor}`,
        cursor: 'pointer'
    },
    card : {
        borderRadius: '10px',
        boxShadow: '0 2px 1rem rgb(0 0 0 / 12%)',
        '&:hover': {
            boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
          }
    },
    post: {
        borderRadius: '15px',
        border: `1px solid ${theme.primaryColor}`
    },
    postBg:{
        background: theme.primaryColor
    },
    wrap : {
        '& span': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
        }
    },
    socials: {
        margin: '5px'
    },
    field : {
        marginBottom: '1rem'
    },
    bar : {
        height: '.8rem',
        borderRadius: '10px'
    }
}))

const Account = (props) => {
    const classes = useStyles()
    const[open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const path = useLocation().pathname
    const id = path.split('/')[3]
    const browse = useRef()
    const cover = useRef()

    const initialState = {
        facebook: '',
        linkedin: '',
        instagram : '',
        twitter: '',
        github: ''
    }
    const reducerFn = (state, action) => {
        switch (action.type) {
            case "FB":
                return {...state, facebook: action.payload}
            case "LK":
                return {...state, linkedin: action.payload}
            case "IN":
                return {...state, instagram: action.payload}
            case "TW":
                return {...state, twitter: action.payload}
            case "GH":
                return {...state, github: action.payload}
            case "RESET":
                return initialState
            default:
                return state;
        }
    }

    const [formInput, dispatch] = useReducer(reducerFn, initialState)

    const onFormSubmit = () => {
        if(formInput.facebook === '' && formInput.twitter === '' && formInput.instagram === '' && formInput.linkedin === '' && formInput.github === ''){
            return
        }
        setOpen(false)
        setLoader(true)
        setTimeout(async() => {
            const res = await props.editUserLinks(formInput)
            if(res.status === 'success'){
                //reset the form
                dispatch({type: "RESET"})
                setLoader(false)
                props.successModal('Settings update successful')
            }else{
                setLoader(false)
                props.errorModal('Sorry. Something went wrong. Please try again')
            }
        }, 2000);
    }

    const closeHandle = () => {
        dispatch({type: "RESET"})
        setOpen(false)
    }

    const onPhotoSelect = (e) => {
        const data = new FormData()
        data.append('profile_pic', e.target.files[0])
        setLoader(true)
        setTimeout(async() => {
            const res = await props.editUserPhoto(data)
            if(res.status === 'success'){
                //reset the form
                setLoader(false)
                props.successModal('Profile photo uploaded successfully')
            }else{
                setLoader(false)
                props.errorModal('Sorry, something went wrong. Please refresh and try again')
            }
        }, 2000);
    }

    const onCoverSelect = (e) => {
        const data = new FormData()
        data.append('coverImg', e.target.files[0])
        setLoader(true)
        setTimeout(async() => {
            const res = await props.editUserCoverImage(data)
            if(res.status === 'success'){
                //reset the form
                setLoader(false)
                props.successModal('Cover image uploaded successfully')
            }else{
                setLoader(false)
                props.errorModal('Sorry, something went wrong. Please refresh and try again')
            }
        }, 2000);
    }

    const renderPage = () => {
        if(props.currentUser){
            if(props.currentUser && props.currentUser.isEmailVerified){
                return (
                    <div className={classes.root}>
                    {  path === '/account/orders' || 
                        path === `/account/orders/${id}/details` || 
                        path === `/account/orders/${id}/requirements` || 
                        path === `/account/orders/${id}/message` || 
                        path === `/account/orders/${id}/delivery` ||
                        path === '/account/inbox' || path === '/account/notifications'
                        ? null :  
                        <Box className={classes.banner} style={{ backgroundImage: props.currentUser && props.currentUser.coverImg ? `url(${props.currentUser.coverImg.url})` : `url(${CoverImg})` }}>
                            <input type={'file'} accept='.jpg, .png, .jpeg' style={{display: 'none'}} ref={cover} onChange={(e)=> onCoverSelect(e)} />
                            <Container className={classes.bannerWrap}>
                                <Button variant='contained' color='primary' startIcon={<CameraAlt />} 
                                style={{color: '#fff', textTransform: 'none', marginBottom: '2rem', background: '#ed8a2f42'}} disableElevation onClick={() => cover.current.click()} >Cover Image</Button>
                            </Container>
                        </Box> 
                        }

             <Box>
                <Container >

            <Grid container spacing={7} style={{paddingBottom: '5rem'}} >
                { path === '/account/orders' || 
                  path === `/account/orders/${id}/details` || 
                  path === `/account/orders/${id}/requirements` || 
                  path === `/account/orders/${id}/message` || 
                  path === `/account/orders/${id}/delivery` || 
                  path === '/account/inbox' ||
                  path === `/account/notifications` ? null 
                : <Grid item lg={4}>
                    <Card className={classes.post} elevation={0} style={{marginTop: '-6rem'}}>
                        <CardContent style={{textAlign: 'center', padding: '2rem 1.5rem'}}>
                            <Avatar className={classes.large} src={props.currentUser.profile_pic ? props.currentUser.profile_pic.url : null} />
                            <Typography variant='h6'>{props.currentUser.username}</Typography>
                            <Typography variant='body1' paragraph color='textSecondary'>{props.currentUser.email}</Typography>
                                <input type={'file'} accept='.jpg, .jpeg, .png' ref={browse} name='profile_pic' style={{display: 'none'}} onChange={(e) => onPhotoSelect(e)} />
                            <Button variant='contained' color='primary'
                             style={{color: '#fff', borderRadius: '8px'}} disableElevation onClick={()=> browse.current.click()} >
                                 Upload Photo
                            </Button>

                            <Divider style={{margin: '1rem 0'}} />

                            <Box className={classes.wrap}>
                                <span>
                                    <Typography>Firstname</Typography>
                                    <Typography color='textSecondary'>{props.currentUser.firstname ? props.currentUser.firstname : '*********' }</Typography>
                                </span>
                                <span>
                                    <Typography>Lastname</Typography>
                                    <Typography color='textSecondary'>{props.currentUser.lastname ? props.currentUser.lastname : '*********' }</Typography>
                                </span>
                        
                            </Box>

                        </CardContent>
                    </Card>

                    {/* ACCOUNT STATUS */}
                    <Card className={classes.post} elevation={0} style={{marginTop: '1.5rem'}}>
                        <CardContent style={{padding: '2rem 1.5rem'}}>
                            
                            <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                <Typography variant='h6' gutterBottom>Account Status</Typography>
                                <Typography color='textSecondary'>({props.currentUser.total}%)</Typography>
                            </Box>
                            <Typography color='textSecondary' paragraph>Fill your details to complete your status</Typography>
                            <LinearProgress value={props.currentUser.total} variant='determinate' className={classes.bar} />
                        </CardContent>
                    </Card>

                    {/* SOCIAL LINKS */}

                    <Card className={classes.post} elevation={0} style={{marginTop: '1.5rem'}}>
                        <CardContent style={{padding: '2rem 1.5rem'}}>
                            
                            <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                <Typography variant='h6' gutterBottom>Social Links</Typography>
                                <IconButton style={{background: grey[300], padding:'.5rem', marginTop: '-10px'}} onClick={()=>setOpen(true)}><Add fontSize='small' /></IconButton>
                            </Box>
                            <Typography color='textSecondary' paragraph>Add your social handles to display here</Typography>
                            <Box marginBottom={'1rem'}>
                                { props.currentUser.links && props.currentUser.links.facebook ?  <Chip className={classes.socials} icon={<Facebook />} label={<Typography variant='body2'>Facebook</Typography>} /> : null }
                                { props.currentUser.links && props.currentUser.links.linkedin ? <Chip className={classes.socials} icon={<LinkedIn />} label={<Typography variant='body2'>LinkedIn</Typography>} /> : null }
                                { props.currentUser.links && props.currentUser.links.instagram ? <Chip className={classes.socials} icon={<Instagram />} label={<Typography variant='body2'>Instagram</Typography>} /> : null }
                                { props.currentUser.links && props.currentUser.links.twitter ? <Chip className={classes.socials} icon={<Twitter />} label={<Typography variant='body2'>Twitter</Typography>} /> : null }
                                { props.currentUser.links && props.currentUser.links.github ? <Chip className={classes.socials} icon={<GitHub />} label={<Typography variant='body2'>GitHub</Typography>} /> : null }
                            </Box>
                        </CardContent>
                    </Card>

                    {/* JOBCARD */}
                    <JobCard />
                   

                </Grid> 
                }
                <Grid item 
                lg={ path === '/account/orders' || 
                     path === `/account/orders/${id}/details` || 
                     path === `/account/orders/${id}/message` || 
                     path === `/account/orders/${id}/requirements` || 
                     path === '/account/inbox' || path === '/account/notifications' ||
                     path === `/account/orders/${id}/delivery` ? 12 : 8 
                     } 
                    style={{paddingTop: '5rem'}}>

                    { path === '/account/settings' && <Profile />}
                    { path === '/account/orders' && <Orders />}
                    { path === `/account/orders/${id}/details` || 
                      path === `/account/orders/${id}/message` || 
                      path === `/account/orders/${id}/requirements` || 
                      path === `/account/orders/${id}/delivery` ? <OrderDetails id={id} /> : null
                    }
                    { path === '/account/notifications' && <Notifications />}

                </Grid>
            </Grid>

            </Container>


            </Box>
        </div>
                )
            }
        }
        return <Navigate to='/' />
    }
    
  return (
    <>
    {renderPage()}

    <Dialog open={open} >
        <DialogTitle >Social Accounts</DialogTitle>
        
        <DialogContent >
            <Box minWidth={'300px'}>
                <TextField className={classes.field} value={formInput.facebook} onChange={(e)=>dispatch({type: "FB", payload: e.target.value})} variant='outlined' fullWidth label='Facebook' InputProps={{
                    endAdornment: <InputAdornment position='end'><Facebook style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={formInput.linkedin} onChange={(e)=>dispatch({type: "LK", payload: e.target.value})} variant='outlined' fullWidth label='LinkedIn' InputProps={{
                    endAdornment: <InputAdornment position='end'><LinkedIn style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={formInput.instagram} onChange={(e)=>dispatch({type: "IN", payload: e.target.value})} variant='outlined' fullWidth label='Instagram' InputProps={{
                    endAdornment: <InputAdornment position='end'><Instagram style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={formInput.twitter} onChange={(e)=>dispatch({type: "TW", payload: e.target.value})} variant='outlined' fullWidth label='Twitter' InputProps={{
                    endAdornment: <InputAdornment position='end'><Twitter style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />
                <TextField className={classes.field} value={formInput.github} onChange={(e)=>dispatch({type: "GH", payload: e.target.value})} variant='outlined' fullWidth label='GitHub' InputProps={{
                    endAdornment: <InputAdornment position='end'><GitHub style={{color: `${grey[400]}`}} /></InputAdornment>
                }} />

            </Box>
        </DialogContent>
        <DialogActions >
            <Button variant='outlined' onClick={closeHandle}>Cancel</Button>
            <Button variant='contained' disableElevation color='secondary' onClick={onFormSubmit}>Add Account</Button>
        </DialogActions>
    </Dialog>

    {/* LOADER */}
    <Dialog open={loader} >
        <Box padding={'2.5rem'} textAlign={'center'}>
            <img src={User} alt='creating gig' width={'25%'}/>
            <Typography paragraph>Updating your settings. Please wait.</Typography>
            <LinearProgress />
        </Box>
      </Dialog>
    </>
  )
}

const mapStateToProps =(state) => {
    return state
}

export default connect(mapStateToProps, {editUserLinks, editUserPhoto, successModal, errorModal, editUserCoverImage})(Account)