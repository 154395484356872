import { Accordion, AccordionDetails, AccordionSummary, alpha, Avatar, Box, Button, Card, CardContent, Container, Dialog, DialogContent, Divider, Grid, InputAdornment, LinearProgress, Link, MenuItem, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Cached, Chat, Check, Close, ExpandMore, KeyboardArrowDown, Receipt, Update, Visibility, VisibilityOff } from '@material-ui/icons'
import { Rating} from '@material-ui/lab'
import { makeStyles, withStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import PageCrumbs from '../components/PageCrumbs'
import { connect } from  'react-redux'
import { useLocation } from 'react-router-dom'
import Loader from '../components/Loader'
import { loginUser, errorModal, successModal } from '../actions/actions'
import User from '../assets/images/password.gif'
import slugify from 'slugify'
import Modal from '../components/Modal'
import OwlCarousel from 'react-owl-carousel'


const useStyles = makeStyles(theme => ({
    root: {
        '& .owl-carousel': {
            position: 'relative',
            '&:hover .owl-nav': {
                opacity: 1
            }
        },
        '& .owl-nav': {
            opacity: 0,
            width: '100%',
            position: 'absolute',
            display: 'flex',
            top: '40%',
            justifyContent: 'space-between',
            transform: 'TranslateY(50%)',
            transition: 'all .2s ease-in'
        },
        '& .owl-prev': {
            padding: '1rem',
            background: alpha('#000', 0.5),
            color: '#fff'

        },
        '& .owl-next': {
            padding: '1rem',
            background: alpha('#000', 0.5),
            color: '#fff'
        }
    },
    wrap: {
        margin: '2rem 0'
    },
    title: {
        fontWeight: 500,
        fontSize: '1.4rem'
        
    },
    field: {
        marginBottom: '1rem'
    },
    subtitle: {
        fontWeight: 500,
        fontSize: '1.4rem',
        marginBottom: '1rem'
    },
    large : {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    preview : {
        height: '25rem',
        margin: '1.5rem 0',
        borderRadius: '15px',
        backgroundSize: 'cover'
    },
    menu : {
        marginTop: '1rem',
        borderLeft: `1px solid ${grey[400]}`,
        position: 'sticky',
        top: '5%'
    },
    menuItem: {
        margin: '1rem 0',
        padding: '1rem',
        display: 'block',
        transition: 'all .3s ease'
    },
    activeMenu: {
        borderLeft: '4px solid',
        color: theme.primaryColor
    },
    activePackage: {
        borderBottom: '4px solid',
        color: theme.primaryColor
    },
    tabList: {
        display: 'flex',
        borderBottom: '1px solid lightgrey',
        '& p:not(:last-child)' : {
            borderRight: '1px solid lightgrey'
        }
    },
    tabs : {
        flex: 1,
        padding: '1.5rem',
        textAlign: 'center',
        cursor: 'pointer',
    },
    tabContent: {
        padding: '1.5rem',
        '& span': {
            display: 'flex', 
            justifyContent: 'space-between'
        },
        '& div' : {
            display: 'flex', 
            gap: '.5rem',
            alignItems: 'center'
        }
    },
    btn : {
        margin: '1.5rem 0 .5rem 0',
        padding: '1rem',
        color: '#fff',
        borderRadius: '10px',

        '& span': {
            display: 'block',
            justifyContent: 'normal',
            textAlign: 'center'
            
        },
        '&:hover' : {
            background: theme.secondaryColor
        }
    },
    contactBtn : {
        marginLeft:'auto', 
        color:'#fff', 
        height: 'fit-content',
        '&:hover' : {
            background: theme.secondaryColor
        }
    },
    faqs: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            margin: 0,
            '&:not(:last-child)': {
              borderBottom: 0,
              margin: 0
            },
            '&:before': {
              display: 'none',
            },
            '&$expanded': {
              margin: 'auto',
            },
    },
    icon: {
        color: grey[400],
        marginRight: '.5rem'
    },
    popList: {
        borderRadius: '5px'
    },
    fieldIcon: {
        color: grey[400],
        cursor: "pointer",
      },
    thumbnails: {
  
        height: '8rem', 
        borderRadius: '10px',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },


}))

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const SingleGig = (props) => {
    const { allGigs } = props
    const classes = useStyles()
    const [active, setActive] = useState('Overview')
    const [value, setValue] = useState('Basic')
    const [pass, setPass] = useState(false)
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [expand, setExpanded] = React.useState('panel0')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const gigId = useLocation().pathname.split('/')[2]

    const initState = {
        username: '',
        password: ''
    }

    const reducerFn = (state, action) => {
        switch (action.type) {
            case "LOGIN_USERNAME":
                return {...state, username: action.payload}
            case "LOGIN_PASSWORD":
                return {...state, password: action.payload}
            case "RESET":
                return initState
            default:
                return state;
        }
    }

    const [formInput, dispatch] = useReducer(reducerFn, initState)

    const singleGig = allGigs && allGigs.filter( gig => gig.slug === gigId )[0]
    const menuList = ['Overview', 'Description', 'Seller', 'Packages', 'FAQs', 'Review']
    const select = ['Basic', 'Standard', 'Premium']

    const onMenuClick = (e) => {
        setActive(e.target.innerText)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        console.log(newExpanded)
        setExpanded(newExpanded ? panel : false);
      };

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

      const onLogin = () => {
        if(formInput.username === '' || formInput.password === ''){
           return
        }
            setShow(false)
            setLoader(true)
            setTimeout(async()=>{
                const res = await props.loginUser(formInput)
                if(res.status === 'success'){
                    //reset the form
                    dispatch({type: "RESET"})
                    setLoader(false)
                    props.successModal('Login successful')
                }else{
                    setLoader(false)
                    props.errorModal(res.message)
                }
            },2000)
      }

      const onPurchase = (val) => {
        if(!props.currentUser){
            setShow(true)
            return
        }
        
        window.location.assign(`/purchase?ctx=${singleGig._id}&pk=${val}`)
      }


      const renderPage = () => {
          if(singleGig){
              const categorySlug = slugify(singleGig.category, {lower: true})
              const subCategorySlug = slugify(singleGig.subcategory, {lower: true})
              const basicPackage = JSON.parse(singleGig.packages.basic)
              const standardPackage = JSON.parse(singleGig.packages.standard)
              const premiumPackage = JSON.parse(singleGig.packages.premium)

              //console.log(basicPackage)

              const filteredBasic = Object.keys(basicPackage).filter(el => el !== 'Price' && el !== 'Delivery' && el !== 'Revision')
              const filteredStandard = Object.keys(standardPackage).filter(el => el !== 'Price' && el !== 'Delivery' && el !== 'Revision')
              const filteredPremium = Object.keys(premiumPackage).filter(el => el !== 'Price' && el !== 'Delivery' && el !== 'Revision')

              const packageFn = (packages, item) => {
                if(packages[item] && packages[item] === 'yes'){
                    return <Check />
                }if(packages[item] && packages[item] === 'no'){
                    return <Close />
                }if(!packages[item]){
                    return <Close style={{color: 'lightgrey'}}/>
                }
                return packages[item]
              }

              return <Container>
                  {props.modal && <Modal status={props.modal.status} /> }
              <Grid container spacing={3} >
                  {/* STEPPERS */}
                  <Grid item lg={2} style={{position: 'relative'}}>
                        <Box className={classes.menu}>
                            {menuList.map(menu => {
                                return (
                                    <Link key={menu} underline='none' href={`#${menu.toLowerCase()}`} color='textSecondary' onClick={(e)=> onMenuClick(e)} className={`${active === menu && classes.activeMenu} ${classes.menuItem}`}>
                                        <Typography>{menu}</Typography>
                                    </Link>
                                        
                                )
                            })}
        
                        </Box>
  
                  </Grid>
  
                  <Grid item lg={6} id='overview'>
                      <PageCrumbs link1={{name: `${singleGig.category}`, path: `/category/${categorySlug}`}} link2={{name: `${singleGig.subcategory}`, path: `/category/${categorySlug}/${subCategorySlug}` }} />
                      <Typography variant='h5' className={classes.title} gutterBottom noWrap>{singleGig.title}</Typography>
  
                      <Box display={'flex'} gridGap='1rem' alignItems='center'>
                          <Avatar className={classes.large} src={ singleGig.user.profile_pic ? singleGig.user.profile_pic.url : null} />
                          <Box>
                              <Box display={'flex'} gridGap='1rem' alignItems='center'>
                                  <Link href={`/${singleGig.user.username.toLowerCase()}`}>
                                    <Typography variant='h6' style={{fontSize: '1rem'}} >{singleGig.user.username}</Typography>
                                  </Link>
                                  <Typography color='textSecondary' >Level 1 Seller</Typography>
                              </Box>
                              <Box display={'flex'} alignItems='center' gridGap={'.5rem'}>
                                  <Rating value={5} readOnly /> <Typography>4.9 (24)</Typography>
                              </Box>
                          </Box>
                          <Button className={classes.contactBtn} variant='contained' color='primary' onClick={handleClick}
                          disableElevation endIcon={<KeyboardArrowDown fontSize='small' />}>Contact</Button>
                              
                          {/* POPOVER */}
                          <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                             <Card>
                                 <CardContent style={{padding: '1rem'}}>
                                      <MenuItem className={classes.popList} > <Receipt className={classes.icon} fontSize='small' /> <Typography>Request A Quote</Typography></MenuItem>
                                      <MenuItem className={classes.popList} > <Chat className={classes.icon} fontSize='small' /> <Typography>Message Me</Typography></MenuItem>
                                 </CardContent>
                             </Card>
                          </Popover>
                      </Box>
  
                      {/* GALLERY */}
  
                      <Box >
                        <OwlCarousel
                            items={4} autoplaySpeed={1500} margin={20} loop={true}
                                nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                                responsive={ { 1400 : {items : '1'}, 1200 : {items : '1'}, 760 : {items : '1'}, 340 : {items : '1'} } }
                            > 
                            {
                                singleGig.gallery && singleGig.gallery.map(item => {
                                    return (
                                        <Box className={classes.preview} key={item.filename} style={{ backgroundImage: `url(${item.url})`}} ></Box>
                                    )

                                })
                            }

                            </OwlCarousel>
                            <Box marginTop={'-1rem'}>
                                <OwlCarousel
                                    items={4} autoplaySpeed={1500} margin={20} loop={true}
                                        nav={true} navElement="div" navText={[`<i class='fas fa-arrow-left'></i>`, `<i class='fas fa-arrow-right'></i>`]}
                                        responsive={ { 1400 : {items : '4'}, 1200 : {items : '4'}, 760 : {items : '3'}, 340 : {items : '2'} } }
                                    > 
                                    {
                                        singleGig.gallery && singleGig.gallery.length > 1 ? singleGig.gallery.map(item => {
                                            return (
                                                <Box key={item.filename} sm={3} className={classes.thumbnails} style={{ width: '100%', backgroundImage: `url(${item.url})`}}></Box>
                                            )
                                        }): null
                                    } 
                                </OwlCarousel>
                            </Box>
                      </Box>
  
                      {/* ABOUT */}
                      <Box className={classes.wrap} id='description'>
                          <Typography variant='h5' className={classes.subtitle} gutterBottom>About This Gig</Typography>
                          <Typography color='textSecondary' paragraph>
                             {singleGig.description}
                          </Typography>
                          
                      </Box>
  
                      {/* SELLER */}
                      <Box className={classes.wrap} id='seller'>
                          <Typography variant='h5' className={classes.subtitle} gutterBottom>The Seller</Typography>
                          <Box display={'flex'} alignItems='center' gridGap={'1rem'} >
                              <Avatar className={classes.large} src={ singleGig.user.profile_pic ? singleGig.user.profile_pic.url : null} />
                              <span>
                                  <Typography variant='h6' style={{fontSize: '1rem'}} > <Link href={`/${singleGig.user.username.toLowerCase()}`}>{singleGig.user.username}</Link>  | Level 1 Seller</Typography>
                                  <Typography color='textSecondary' >{singleGig.subcategory}</Typography>
                                  <Box display={'flex'} alignItems='center' gridGap={'.5rem'}>
                                      <Rating value={5} readOnly /> <Typography>4.9 (24)</Typography>
                                  </Box>
                              </span>
  
                          </Box>
                          <Divider style={{margin: '1rem 0'}} />
                          <Typography color='textSecondary' paragraph>
                              {singleGig.user.sellerInfo.about}
                              </Typography>
                      </Box>
  
                      {/* PACKAGES */}
                      <Box className={classes.wrap} id='packages'>
                          {/* <Typography variant='h5' className={classes.subtitle} gutterBottom>Packages</Typography> */}
                          <Table style={{border: '1px solid lightgrey'}}>
                              <TableHead >
                                  <TableRow>
                                      <TableCell><Typography variant='h6'>Packages</Typography></TableCell>
                                      <TableCell align='center'><Typography variant='h6'>Basic</Typography></TableCell>
                                      <TableCell align='center'><Typography variant='h6'>Standard</Typography></TableCell>
                                      <TableCell align='center'><Typography variant='h6'>Premium</Typography></TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              {
                                  Object.keys(JSON.parse(singleGig.packages.premium)).map((item, index) => {
                                      return (
                                          <StyledTableRow key={index}>
                                              <TableCell> <Typography color='textSecondary'> {item} </Typography> </TableCell>
                                              <TableCell align='center'> <Typography color='textSecondary'> { packageFn(basicPackage, item) } </Typography></TableCell>
                                              <TableCell align='center'><Typography color='textSecondary'> {packageFn(standardPackage, item) }</Typography></TableCell>
                                              <TableCell align='center'><Typography color='textSecondary'> {packageFn(premiumPackage, item)} </Typography></TableCell>
                                          </StyledTableRow>
                                      )
                                  })
                              }
  
                              </TableBody>
                          </Table>
                         
  
                      </Box>
  
                      {/* FAQs */}
                      <Box className={classes.wrap} id='faqs'>
                          <Typography varinat='h5' className={classes.subtitle} gutterBottom> FAQs </Typography>
                          
                          { [].map((item, i) => {
                              return(
                                  <Accordion key={i} className={classes.faqs} square expanded={expand === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                                      <AccordionSummary expandIcon={<ExpandMore />} style={{backgroundColor: 'rgba(0, 0, 0, .03)'}} >
                                          <Typography>{item.question}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                          <Typography color='textSecondary'>{item.answer}</Typography>
                                      </AccordionDetails>
                                  </Accordion>
                              )
                          })}
                         
  
                      </Box>
  
                      {/* REVIEW */}
                      <Box className={classes.wrap} id='reviews'>
                          <Typography variant='h5' className={classes.subtitle} gutterBottom> Review </Typography>
                      </Box>
  
  
                  </Grid>
  
                  <Grid item lg={4} style={{position: 'relative'}}>
                  <Box className={classes.wrap} style={{border: '1px solid lightgrey', marginLeft:'3rem', position: 'sticky', top: '5%'}}> 
                      <Box className={classes.tabList} display={'flex'} style={{borderBottom: '1px solid lightgrey'}} >
                      { select.map(item => {
                          return (
                              <Typography key={item} className={`${value === item && classes.activePackage} ${classes.tabs}`} onClick={(e)=>setValue(e.target.innerText)}>{item}</Typography>
  
                          )
                      })}
                          
                      </Box>
  
                      <Card elevation={0} >
                          
                          {/* BASIC PACKAGE */}
                          {
                              value === 'Basic' && 
                                  <CardContent className={classes.tabContent}>
                                    <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                      <Typography noWrap>{singleGig.title}</Typography>
                                        <Typography style={{fontWeight: 600}}>GH¢{parseInt(basicPackage.Price).toLocaleString()}</Typography>
                                    </Box>
                                      <Divider style={{margin: '1rem 0'}} />
                                      <span>
                                          <div> <Update /> <Typography >{basicPackage.Delivery} Days Delivery</Typography> </div> 
                                          <div> <Cached /> <Typography >{basicPackage.Revision} Revisions</Typography> </div> 
                                      </span>
                                      <Divider style={{margin: '1rem 0'}} />
                                      {/* includes */}
                                      <Typography paragraph><b>What's Included</b></Typography>
                                      {
                                      filteredBasic.map((item, index) => {
                                          return (
                                              <div key={index} style={{marginBottom: '.5rem'}}>
                                                  <Check color='primary' />
                                                  <Typography color='textSecondary'>{ basicPackage[`${item}`] === 'yes' ? null : basicPackage[item]} {item}</Typography>
                                              </div>
                                                )
                                            })
                                      }
                                      {/* PREMIUM EXTRAS */}
                                      {
                                      filteredPremium.filter(el => !filteredBasic.includes(el)).map((item, index) => {
                                          return (
                                              <div key={index} style={{marginBottom: '.5rem', color: 'lightgrey'}}>
                                                  <Check />
                                                  <Typography >{ premiumPackage[`${item}`] === 'yes' ? null : premiumPackage[item]} {item}</Typography>
                                              </div>
                                                )
                                            })
                                      }
  
                                      <Button className={classes.btn} color='primary' variant='contained' disableElevation fullWidth  onClick={() => onPurchase('basic')}>Select GH¢ {parseInt(basicPackage.Price).toLocaleString()}</Button>
                                      <Typography variant='body1' color='textSecondary' style={{textAlign: 'center'}} paragraph>{value} Package</Typography>
                                  </CardContent>
                          }
  
                          {/* STANDARD PACKAGE */}
                          {
                              value === 'Standard' && 
                              <CardContent className={classes.tabContent}>
                                      <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                      <Typography noWrap>{singleGig.title}</Typography>
                                        <Typography style={{fontWeight: 600}}>GH¢{parseInt(standardPackage.Price).toLocaleString()}</Typography>
                                    </Box>
                                      <Divider style={{margin: '1rem 0'}} />
                                      <span>
                                          <div> <Update /> <Typography >{standardPackage.Delivery} Days Delivery</Typography> </div> 
                                          <div> <Cached /> <Typography >{standardPackage.Revision} Revisions</Typography> </div> 
                                      </span>
                                      <Divider style={{margin: '1rem 0'}} />
                                      {/* includes */}
                                      <Typography paragraph><b>What's Included</b></Typography>
                                      {
                                      filteredStandard.map((item, index) => {
                                          return (
                                                <div key={index} style={{marginBottom: '.5rem'}}>
                                                    <Check color='primary' />
                                                    <Typography color='textSecondary'>{ standardPackage[`${item}`] === 'yes' ? null : standardPackage[item]} {item}</Typography>
                                                </div>
                                                )
                                            })
                                      }
                                      {/* PREMIUM EXTRAS */}
                                      {
                                      filteredPremium.filter(el => !filteredStandard.includes(el)).map((item, index) => {
                                          return (
                                              <div key={index} style={{marginBottom: '.5rem', color: 'lightgrey'}}>
                                                  <Check />
                                                  <Typography >{ premiumPackage[`${item}`] === 'yes' ? null : premiumPackage[item]} {item}</Typography>
                                              </div>
                                                )
                                            })
                                      }
  
                                      <Button className={classes.btn} color='primary' variant='contained' disableElevation fullWidth  onClick={() => onPurchase('standard')}>Select GH¢ {parseInt(standardPackage.Price).toLocaleString()}</Button>
                                      <Typography variant='body1' color='textSecondary' style={{textAlign: 'center'}} paragraph>{value} Package</Typography>
                                  </CardContent>
                          }
  
                          {/* PREMIUN PACKAGE */}
                          {
                              value === 'Premium' && 
                              <CardContent className={classes.tabContent}>
                                      <Box display='flex' justifyContent={'space-between'} alignItems='center'>
                                        <Typography noWrap>{singleGig.title}</Typography>
                                        <Typography style={{fontWeight: 600}}>GH¢{parseInt(premiumPackage.Price).toLocaleString()}</Typography>
                                        </Box>
                                      <Divider style={{margin: '1rem 0'}} />
                                      <span>
                                          <div> <Update /> <Typography >{premiumPackage.Delivery} Days Delivery</Typography> </div> 
                                          <div> <Cached /> <Typography >{premiumPackage.Revision} Revisions</Typography> </div> 
                                      </span>
                                      <Divider style={{margin: '1rem 0'}} />
                                      {/* includes */}
                                      <Typography paragraph><b>What's Included</b></Typography>
                                      {
                                        filteredPremium.map(item => {
                                            return (
                                                <div key={item} style={{marginBottom: '.5rem'}}>
                                                    <Check color='primary' />
                                                    <Typography color='textSecondary'>{ premiumPackage[`${item}`] === 'yes' ? null : premiumPackage[item]} {item}</Typography>
                                                </div>
                                            )
                                        })
                                      }
  
                                      <Button className={classes.btn} color='primary' variant='contained' disableElevation fullWidth onClick={() => onPurchase('premium')}>Select GH¢ {parseInt(premiumPackage.Price).toLocaleString()}</Button>
                                      <Typography variant='body1' color='textSecondary' style={{textAlign: 'center'}} paragraph>{value} Package</Typography>
                                  </CardContent>
                          }
  
                          
                      </Card>
                     
                  </Box>
  
                 
       
                  </Grid>
              </Grid>
              
          </Container>
          }

          return <Loader />
      }

  return (
    <div className={classes.root}>
        {renderPage()}

        {/* LOGIN / SIGNUP */}
        <Dialog open={show} onClose={()=> setShow(false)}>
            <DialogContent dividers style={{padding: '2.5rem', textAlign: 'center'}}>
                <Typography variant='h6' style={{textAlign: 'center', fontSize: '1.4rem', marginBottom: '1rem'}} gutterBottom>Login to Amaly</Typography>
                
                <TextField fullWidth variant='outlined' className={classes.field} label='Username' value={formInput.username} onChange={(e)=>dispatch({type: "LOGIN_USERNAME", payload: e.target.value})} />
                
                <TextField fullWidth variant='outlined' className={classes.field} label='Password' type={pass ? 'text' : 'password'} 
                InputProps={{ endAdornment: <InputAdornment position='end'> { pass ? <Visibility className={classes.fieldIcon}  onClick={()=> setPass(!pass)} /> : <VisibilityOff className={classes.fieldIcon} onClick={()=> setPass(!pass)} /> } </InputAdornment> }}
                value={formInput.password} onChange={(e)=> dispatch({type: "LOGIN_PASSWORD",  payload: e.target.value})} />
                
                <Button style={{height: '3.5rem', color: '#fff'}} fullWidth variant='contained' disableElevation color='primary' onClick={onLogin}>Continue</Button>
                <Typography color='textSecondary' style={{marginTop: '2rem'}}>Not a member yet? <Link href='/auth/register' underline='none' color='primary'>Signup</Link> </Typography>
                <Typography color='textSecondary' style={{marginTop: '.5rem'}}> <Link href='/auth/forgotpassword' underline='none' color='primary'>ForgotPassword</Link> </Typography>
            </DialogContent>
        
        </Dialog>

         {/* LOADER */}
        <Dialog open={loader} >
            <Box padding={'2.5rem'} textAlign={'center'}>
                <img src={User} alt='creating gig' width={'25%'}/>
                <Typography paragraph>Updating your settings. Please wait.</Typography>
                <LinearProgress />
            </Box>
        </Dialog>

    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, { loginUser, errorModal, successModal })(SingleGig)