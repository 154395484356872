import { Box, IconButton, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'


const useStyles = makeStyles(theme => ({
    iconBtn: {
        background: grey[100],
        padding: '8px'
    },
    wrap: {
        border: '1px solid lightgrey',
        borderRadius: '10px',
        padding:'1rem',
        marginBottom: '1rem',
        transition: 'all .2s ease',
        '&:hover': {
            border: `1px solid ${theme.primaryColor}`
        }
    }
}))

const FaqItem = (props) => {
    const classes = useStyles()

  return (
    <Box className={classes.wrap}>
        <Box display='flex' justifyContent={'space-between'}>
            <Typography variant='h6' gutterBottom>{props.question}</Typography>
            <IconButton className={classes.iconBtn}><Edit fontSize='small' /></IconButton>
        </Box>
        <Typography color='textSecondary'>{props.answer}</Typography>

    </Box>
  )
}

export default FaqItem