import { Avatar, Box, Divider, IconButton, Link, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Bookmark, Star } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'


const useStyles = makeStyles(theme => ({
  wrap : {
    borderRadius: '10px',
    boxShadow: '0 1px 1rem rgb(0 0 0 / 12%)',
    overflow : 'hidden'
  },
  imageBox : {
    height: '12rem',
    backgroundPosition: 'top-center',
    backgroundSize: 'cover',
    cursor: 'pointer'
  },
  iconBtn : {
    marginLeft: 'auto'
  },
  icon : {
    color: grey[400]
  },
  price : {
    margin : '1rem',
    textAlign: 'right',
    '& span': {
      fontWeight: 600,
      color: '#000'
    }
  },
  copy : {
    margin: '1.2rem',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title : {
    fontWeight: 500
  }

}))
const GigItem = ({link, data}) => {
    const classes = useStyles()


    const renderPage = () => {
      if(data){
        const basicPackage = JSON.parse(data.packages.basic)
        //console.log(basicPackage)
        return <Box className={classes.wrap}>
            <Box onClick={()=>window.location.assign(`/${data.user.username.toLowerCase()}/${data.slug}`)} className={classes.imageBox} style={{ backgroundImage: `url(${data.gallery[0].url})`,}} ></Box>
              <Box display='flex' gridGap={'.8rem'} margin='1.2rem'>
                { data.user.profile_pic ? <Avatar src={data.user.profile_pic.url} /> : <Avatar>{data.user.username[0]}</Avatar>}
                <div>
                  <Link color='textPrimary' underline='none' href={`/${data.user.username.toLowerCase()}`}>
                    <Typography className={classes.title}>{data.user ? data.user.username : 'Anonymous'}</Typography>
                  </Link>
                  <Box display='flex' gridGap={'.5rem'}> <Star color='primary' fontSize='small' /> <Typography color='textSecondary'>4.5 (0)</Typography> </Box>
                </div>
                <IconButton className={classes.iconBtn}> <Bookmark  fontSize='small' className={classes.icon}/> </IconButton>
              </Box>

              <Link color='textPrimary' underline='none' href={`/${data.user.username.toLowerCase()}/${data.slug}`}>
                <Typography className={classes.copy} color='textPrimary' >{data.title}</Typography>
              </Link>
              <Divider />

              <Typography className={classes.price} color='textSecondary'>Starting at <span>GH¢ {parseInt(basicPackage.Price).toLocaleString()}</span> </Typography>

        </Box>
      }
    }



  return (
    <> {renderPage()} </>
  )
}

export default GigItem