import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControlLabel, Grid, InputAdornment, Popover, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import GigItem from '../components/GigItem'
import PageCrumbs from '../components/PageCrumbs'


const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 600,
        fontSize: '1.7rem'
        
    },
    result : {
       
        fontSize: '1rem',
        fontWeight: 500
    },
    filters : {
        marginRight: '1rem',
        textTransform: 'none'
    },
    filterWrap: {
        margin: '3rem 0 2rem 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            marginLeft: 'auto'
        }
    },
    icon: {
        color: grey[400],
        marginLeft: '.5rem'
    },
    filterCard: {
        padding: '1.8rem 1.2rem'
    } 
}))
const Search = () => {
    const classes = useStyles()
    const keyword = useLocation().pathname.split('/')[2]
    const [searchResult, setSearchResult] = React.useState([])
    const gigs = ['1','2','3','4','5','6','7','8','9','10','11','12']

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter ] = React.useState()
    const [value, setValue ] = React.useState('')
    const open = Boolean(anchorEl);

    console.log(keyword);

    useEffect(()=>{
        const search = async() => {
            try {
                const res = await axios.get(`/api/search?q=${keyword}`)
                setSearchResult(res.data.data)
            } catch (error) {
                console.log(error.response.data)
            }
        }
        search()
    },[keyword])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setFilter(event.target.innerText)
        // console.log(event.target.innerText)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onFilterChange = (event) => {
        setValue(event.target.value)
       
      };


  return (
    <>
        <Container >
            <PageCrumbs link1={'Search'} link2='Results' />
            <Typography variant='h5' className={classes.title} gutterBottom>Results for "{keyword}"</Typography>
            <Typography color='textSecondary'>Stand out from the crowd with a logo that fits your brand personality</Typography>

                <Box className={classes.filterWrap} >
                    <Typography variant='body1' className={classes.result}>Showing 12 results</Typography>
                    <span>
                        <Button variant='outlined' className={classes.filters} onClick={handleClick} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Budget</Button>
                        <Button variant='outlined' className={classes.filters} onClick={handleClick} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Delivery</Button>
                        <Button variant='outlined' className={classes.filters} onClick={handleClick} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Ratings</Button>
                        <Button variant='outlined' className={classes.filters} onClick={handleClick} disableElevation size='large' endIcon={<KeyboardArrowDown className={classes.icon} />}>Sorting</Button>
                    </span>
                    <Popover style={{marginTop: '.5rem'}} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    >
                       <Card variant='outlined' elevation={0} >
                            { filter === 'Budget' && 
                             <CardContent className={classes.filterCard} style={{width: '16rem'}}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <TextField size="small" label='Min' fullWidth variant='outlined' placeholder='0' InputProps={{
                                            startAdornment: <InputAdornment position='start'>Gh¢</InputAdornment>
                                        }} />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <TextField size="small" label='Max' fullWidth variant='outlined' placeholder='0' InputProps={{
                                            startAdornment: <InputAdornment position='start'>Gh¢</InputAdornment>
                                        }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            }
                            { filter === 'Delivery' &&
                            <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                <RadioGroup name="delivery-filter" value={value} onChange={onFilterChange} color='primary'>
                                    <FormControlLabel value="express" control={<Radio />} label="Express 24Hrs" />
                                    <FormControlLabel value="up-to-3days" control={<Radio />} label="Up to 3days" />
                                    <FormControlLabel value="up-to-5days" control={<Radio />} label="Up to 5days" />
                                    <FormControlLabel value="up-to-7days" control={<Radio />} label="Up to 7days" />
                                    <FormControlLabel value="anytime" control={<Radio />} label="Anytime" />
                                </RadioGroup>
                            </CardContent>
                            }
                            { filter === 'Sorting' &&
                                <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                    <RadioGroup name="sorting-filter" value={value} onChange={onFilterChange}>
                                        <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                                        <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                                        <FormControlLabel value="lowest-price" control={<Radio />} label="Lowest Price" />
                                        <FormControlLabel value="highest-price" control={<Radio />} label="Highest Price" />
                                        <FormControlLabel value="none" control={<Radio />} label="None" />
                                    </RadioGroup>
                                </CardContent>
                            }
                            { filter === 'Ratings' &&
                                <CardContent className={classes.filterCard} style={{width: '10rem'}}>
                                    <RadioGroup name="ratings-filter" value={value} onChange={onFilterChange}>
                                        <FormControlLabel value="4.5+" control={<Radio />} label="Above 4.5" />
                                        <FormControlLabel value="4.5" control={<Radio />} label="4.5 Ratings" />
                                        <FormControlLabel value="4.0" control={<Radio />} label="4.0 Ratings" />
                                        <FormControlLabel value="3.5" control={<Radio />} label="3.5 Ratings" />
                                        <FormControlLabel value="-3.0" control={<Radio />} label="Below 3.0" />
                                    </RadioGroup>
                                </CardContent>
                            }
                           
                           <Divider />
                           <CardActions style={{padding: '.8rem'}}>
                               <Button>Clear</Button>
                               <Button variant='contained' disableElevation color='primary' style={{color: '#fff'}}>Apply</Button>
                           </CardActions>
                       </Card>
                    </Popover>
                </Box>
            
            <Box>
                <Grid container spacing={3}>
                    {gigs.map(item => {
                        return (
                            <Grid item lg={3} md={4} sm={6} xs={12} key={item}>
                                <GigItem />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

        </Container>
    </>
  )
}

export default Search