import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  link: {
    transition: "all .3s ease",
    "&:hover": {
      color: theme.primaryColor,
    },
  },
}));

const PageCrumbs = ({ link1, link2, link3 }) => {
  const classes = useStyles();

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        style={{ margin: "1.5rem 0" }}
      >
        <Link
          color="textSecondary"
          underline="none"
          className={classes.link}
          href={link1.path}
          children
        >
          {link1.name}
        </Link>
        <Link
          color="textSecondary"
          underline="none"
          className={classes.link}
          href={link2.path}
          children
        >
          {link2.name}
        </Link>
        <Typography color="textPrimary">{link3}</Typography>
      </Breadcrumbs>
    </>
  );
};

export default PageCrumbs;
