import { Avatar, Box, Button, Card, CardContent, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { AttachFile, Close, Facebook, GitHub, Instagram, LinkedIn, Twitter } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import GigItem from '../components/GigItem'
import Cover from '../assets/images/cover-image.png'
import { connect } from 'react-redux'
import { getSellerDetails } from '../actions/actions'
import { useLocation } from 'react-router-dom'
import Loader from '../components/Loader'



const useStyles = makeStyles(theme => ({
    large : {
        width: theme.spacing(14),
        height: theme.spacing(14),
        margin: '.5rem auto',
        border: '5px solid #fff'
    },
    subtitle : {
        fontSize: '1.1rem',
        fontWeight: 500,
        textAlign: 'left',
        marginBottom: '.3rem'
    },
    skills : {
        margin: '5px'
    },
    cover : {
        height: '10rem',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        margin: '0 auto',
    }
}));

const SellerProfile = (props) => {
    const { getSellerDetails } = props
    const classes = useStyles() 
    const[open, setOpen] = useState(false)
    const path = useLocation().pathname.split('/')[1]
    const [seller, setSeller] = useState()


    useEffect(()=>{
        const fetchDetails = async() => {
            const res = await getSellerDetails(path);
            console.log(res.data)
            setSeller(res.data)
        }
        fetchDetails()
    },[getSellerDetails, path])

    const clickHandle = () =>{
        setOpen(true)
    }

    const renderPage = () => {
        if(seller){
            return <Container style={{marginTop: '3rem'}}>

            <Grid container spacing={5}>

                <Grid item lg={4}>
                <Box border={'1px solid lightgrey'} borderRadius={'10px'} overflow='hidden'>

                    <Box className={classes.cover} style={{ backgroundImage: seller && seller.user ? `url(${seller.user.coverImg.url})`: `url(${Cover})`,}}></Box>
                     
                    <Box padding={'2rem'} marginTop={'-6rem'} textAlign='center' >
                        <Avatar className={classes.large} src={ seller.user.profile_pic ? seller.user.profile_pic.url : null} />
                        <Typography variant='h6'>{seller.user.username}</Typography>
                        <Typography color='textSecondary'>{seller.user.sellerInfo.subcategory}</Typography>

                        <span style={{display: 'flex', gap: '.5rem', justifyContent: 'center'}}> <Rating value={4.5} readOnly /> <Typography> 4.9(24)</Typography></span>
                        <Button variant='contained' color='primary' disableElevation style={{color:'#fff', marginTop: '1rem'}} onClick={clickHandle}>Contact Me</Button>

                        <Divider style={{margin: '1rem 0'}} />
                        <span style={{display: 'flex', gap: '.5rem', justifyContent: 'space-between'}}> <Typography paragraph color='textSecondary'> City </Typography> <Typography paragraph> {seller.user.sellerInfo.city}</Typography></span>
                        <span style={{display: 'flex', gap: '.5rem', justifyContent: 'space-between'}}> <Typography paragraph color='textSecondary'> Country </Typography> <Typography paragraph> {seller.user.sellerInfo.country}</Typography> </span>
                        <span style={{display: 'flex', gap: '.5rem', justifyContent: 'space-between'}}> <Typography color='textSecondary'> Language</Typography> <Typography >{seller.user.sellerInfo.language[0]}</Typography></span>
                        <Divider style={{margin: '1rem 0'}} />

                        <Typography variant='h6' className={classes.subtitle}>About Me </Typography>
                        <Typography color='textSecondary' style={{textAlign: 'left'}}>
                            {seller.user.sellerInfo.about}
                        </Typography>

                        <Divider style={{margin: '1rem 0'}} />
                        <Typography variant='h6' className={classes.subtitle}>Socials </Typography>
                        <Box textAlign={'left'}>
                            {  seller.user.links && seller.user.links.facebook ? <Chip className={classes.skills} label='Facebook' icon={<Facebook />} /> : null }
                            { seller.user.links && seller.user.links.instagram ? <Chip className={classes.skills} label='Instagram' icon={<Instagram />} /> : null }
                            { seller.user.links && seller.user.links.twitter ? <Chip className={classes.skills} label='Twitter' icon={<Twitter />} />  : null}
                            { seller.user.links && seller.user.links.github ? <Chip className={classes.skills} label='Github' icon={<GitHub />} /> : null }
                            { seller.user.links && seller.user.links.linkedin ? <Chip className={classes.skills} label='LinkedIn' icon={<LinkedIn />} /> : null }
                        </Box>

                        <Divider style={{margin: '1rem 0'}} />
                        <Typography variant='h6' className={classes.subtitle}>Top Skills </Typography>
                        <Box textAlign={'left'}>
                            {
                                seller.user.sellerInfo.skills.length > 0 ? seller.user.sellerInfo.skills.map(item => {
                                    return (
                                        <Chip key={item} className={classes.skills} label={item} variant='outlined' />
                                    )
                                }): null
                            }
                        </Box>

                    </Box>
                </Box>

                </Grid>

                <Grid item lg={8}>
                    <Typography variant='h6' style={{marginBottom: '2rem'}}> {seller.user.username}'s Gigs</Typography>
                    <Grid container spacing={3}>
                        {
                            seller.gigs.length > 0 ? seller.gigs.map( item => {
                                return (
                                    <Grid key={item._id} item lg={4}>
                                        <GigItem data={item} />
                                    </Grid>
                                )
                            })
                            : 
                            <Box>

                                <Typography color='textSecondary'>Seller has no gigs available</Typography>
                            </Box>
                        }
                        
                    </Grid>

                </Grid>

            </Grid>
        </Container>
        }

        return <Loader />
    }

  return (
    <>
        
        {renderPage()}

        <Dialog open={open} maxWidth='lg'>
            <Box display={'flex'} justifyContent='space-between' alignItems={'center'} width='40rem' padding={'10px 20px'} bgcolor={'#e7e7e7'}>
                <Typography variant='h6'>Send a Message</Typography>
                <IconButton onClick={()=>setOpen(false)}><Close /></IconButton>
            </Box>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <Box padding={'1rem'} >
                            <span style={{textAlign:'center'}}>
                                <Avatar className={classes.avatar} src={ seller && seller.user.profile_pic ? seller.user.profile_pic.url : null}  />
                                <Typography variant='h6'>{ seller && seller.user.username}</Typography>
                                <Typography variant='body2' color='textSecondary'>online</Typography>
                            </span>
                            <Divider style={{margin: '1rem 0'}} />
                            <span>
                                <Typography style={{fontWeight: 600, fontSize: '1rem'}} gutterBottom>Please include</Typography>
                                <Typography variant='body2' color='textSecondary'> <li>Project Description</li></Typography>
                                <Typography variant='body2' color='textSecondary'><li>Specific Instructions</li></Typography>
                                <Typography variant='body2' color='textSecondary'><li>Relevant Files</li></Typography> 
                                <Typography variant='body2' color='textSecondary'><li>Your Budget</li></Typography>
                            </span>
                        </Box>
                    </Grid>
                    <Grid item lg={8}>
                        <Box>
                            <Typography gutterBottom color='textSecondary'>Local Time: 1:02PM</Typography>
                        </Box>
                        <TextField fullWidth multiline rows={8} variant='outlined' />
                        {/* <Box marginTop={'.5rem'}> 
                            <Chip onDelete={()=>{alert('Attachment deleted')}} label='Attached File' /> 
                        </Box> */}
                        <Box marginTop={'.5rem'} >
                            <Button variant='contained' color='primary' style={{color:'#fff'}} disableElevation >Send Message</Button>
                            <IconButton style={{padding: '10px', marginLeft: '5px'}}><AttachFile /></IconButton>
                        </Box>

                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {getSellerDetails})(SellerProfile)