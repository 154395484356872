import { Box, Card, CardContent, Chip, Grid, IconButton,Container, InputAdornment, MenuItem, TextField, Typography, Avatar } from '@material-ui/core'
import { Cached, CancelOutlined, CheckCircleOutlined, ListAlt, Search, SyncAltOutlined, Tune, Visibility } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DataGrid } from '@material-ui/data-grid'
import React, { useState} from 'react'
import PageCrumbs from '../../components/PageCrumbs'
import FullStack from '../../assets/images/fullstack.jpg'
import ChatBox from '../../components/ChatBox'
import { connect } from 'react-redux'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '-2rem',
        padding: '0 1rem',
        paddingTop: '2.5rem',
        background: '#b4b4b412',
        '& *' : {
          borderRadius: '10px',
        },
      "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
          }
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderRadius: 0
        }
      },
      title: {
        fontWeight: 500,
        marginBottom: '-1rem'
      },
      card : { 
        padding: '1rem',
        borderRadius: '15px',
        '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
        }
      },
      menu : {
          padding: '1rem',
      },
      icon: {
          marginRight: '.7rem',
          color: theme.primaryColor
      },
      post: {
        border: `1px solid ${theme.primaryColor}`
    },
    postBg : {
        background: theme.primaryColor
    }, 
    orderImg : {
        height: 'auto',
        width: '5em',
        backgroundImage: `url(${FullStack})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
}))
const SellerOrders = (props) => {
    const classes = useStyles()
    const [pageSize, setPageSize] = useState(5)
    const [select, setSelect] = useState('All Orders')


    const links = [
        {name: 'All Orders', path: '#', icon: <ListAlt className={classes.icon}/>},
        {name: 'In Progress', path: '#', icon: <Cached className={classes.icon}/>},
        {name: 'Completed', path: '#', icon: <CheckCircleOutlined className={classes.icon}/>},
        {name: 'Cancelled', path: '#', icon: <CancelOutlined className={classes.icon}/>},
        {name: 'Dispute', path: '#', icon: <SyncAltOutlined className={classes.icon}/>}
    ]

    const columns = [
      {field: 'gig', headerName: 'Order Details', flex: 1.1, renderCell: (params) => {
       // console.log(params)
          const packages = params.row.package[0].toUpperCase() + params.row.package.substring(1).toLowerCase()
          return (
              <Box display={'flex'} gridGap='1rem'>
                  <Box className={classes.orderImg} style={{ backgroundImage: `url(${params.row.gig.gallery[0].url})`,}}></Box>
                  <Box width='12rem' >
                      <Typography variant='h6' style={{fontSize: '1rem'}} noWrap>{params.row.gig.title}</Typography>
                      <Typography variant='body2' color='textSecondary'>{packages} Package | Qnty: {params.row.purchasedQty} </Typography>
                      <Typography variant='body2' color='textSecondary'>GH¢ {params.row.total.toLocaleString()} </Typography>
                  </Box>
              </Box>
              )
          }
      }, 
      {field: 'user', headerName: 'Ordered By', flex: .6, renderCell: (params) => {
        return (
          <Box display={'flex'} gridGap='10px' alignItems={'center'}>
            <Avatar src={params.value.profile_pic.url} style={{borderRadius: '50%'}} />
            <Typography noWrap>{params.value.username}</Typography>
           
          </Box>
        )
      }}, 
      {field: 'createdAt', headerName: 'Date & Time', flex: .6, renderCell: (params) => { 
        return (
          <Box display={'flex'} flexDirection='column'>
            <Typography>{  new Date(params.value).toDateString() }</Typography>
            <Typography color='textSecondary' variant='body2'>{  new Date(params.value).toLocaleTimeString() }</Typography> 
          </Box>
          )
        }},
      {field: 'status', headerName:' Status', flex: .4, renderCell: (params) => { return <Chip disabled label={params.value} />} },
      {field: '_id', headerName:' Action', flex: .4, renderCell: (params) => { return <><IconButton onClick={()=> window.location.assign(`/seller/orders/${params.value}/details`)}><Visibility fontSize='small' /></IconButton> </>}}
    ]
  

  const orders = [
      {order: '', createdAt: '24th March, 2022', total: '540', status: 'In Progress', id: '6c30231823912815'},
      {order: '', createdAt: '24th March, 2022', total: '1210', status: 'Cancelled', id: '6c302318239128174'},
      {order: '', createdAt: '24th March, 2022', total: '860', status: 'Completed', id: '6c302332239128174'},
      {order: '', createdAt: '24th March, 2022', total: '1010', status: 'Cancelled', id: '7cf3023156308111'},
      {order: '', createdAt: '24th March, 2022', total: '720', status: 'Dispute', id: '2bs95c083599014'},
  ]

  const [filterOrders, setFilterorders] = useState(orders)

  const onMenuClick = (val) => {
      if(val === 'All Orders') {
        setSelect(val)
        setFilterorders(orders)
        return
      }
        setSelect(val)
        const filtered = orders.filter(el => el.status === val);
        setFilterorders(filtered)
    
  }

  const onSearch = () => {

  }



  return (
    <div className={classes.root}>
      <Container>

        <Typography variant='h5' className={classes.title}>Orders({props.sellerOrders ? props.sellerOrders.length : 0})</Typography>
        <PageCrumbs link1={{name:'Dashboard', path: '/seller/dasboard'}} link2={{name:'Orders', path: '/seller/orders'}} link3={'All Orders'} />
        
        <Grid container spacing={5}>
            <Grid item lg={3} md={3} sm={12}>

                <Card elevation={0} variant='outlined' className={`${classes.card} ${classes.post}`}  >
                    <CardContent style={{padding: '0'}}>
                    { links.map((el,index) => {
                        return (
                        <MenuItem onClick={()=>onMenuClick(el.name)} selected={el.name === select} className={classes.menu} key={el.name}>
                            {el.icon}
                            <Typography>{el.name}</Typography> 
                        </MenuItem>
                        )
                    })}
                    </CardContent>
                </Card>

                {/* CHATBOX */}
                <ChatBox />
                
            </Grid>

            <Grid item lg={9}>
                <Box>
                    
                        <TextField  variant='outlined' style={{ marginBottom: '20px', background: '#fff'}}
                            placeholder='Find an order' fullWidth onChange={(e) => onSearch(e.target.value)}
                            InputProps={{ 
                            startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>,
                            endAdornment: <InputAdornment position='end'> <IconButton> <Tune fontSize='small' /> </IconButton>  </InputAdornment>
                            }}/>
                        <DataGrid autoHeight style={{background: '#fff'}}
                        pagination rows={props.sellerOrders ? props.sellerOrders : [] } rowsPerPageOptions={[5, 10, 15, 20]}
                        rowHeight={120} columns={columns} 
                        pageSize={pageSize} checkboxSelection 
                        onPageSizeChange={(newSize)=> setPageSize(newSize)}
                        />

                </Box>

            </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {})(SellerOrders)