import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, InputAdornment, MenuItem, Popover, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { DoneAllRounded, DoneRounded, Search, TuneRounded, ForumOutlined, AttachFile, InsertEmoticon, Send } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect,useRef, useState} from 'react'
import ChatItem from '../components/ChatItem'
import MessageListItem from '../components/MessageListItem'
import {useDispatch } from 'react-redux';
import PageCrumbs from '../components/PageCrumbs'
import { getChatUsers, newChat, getCurrentChat, realtimeChat } from '../actions/actions'
import { connect } from 'react-redux'



const drawerWidth = 260
const useStyles = makeStyles(theme => ({
    root: {
        '& *:not(.MuiListItem-root, .MuiBox-root-96, .MuiFormControl-root *)' : {
            borderRadius: '10px',
        },
        '& .MuiSvgIcon-fontSizeLarge' : {
            fontSize: '6rem'
        }
    },
    title: {
        fontWeight: 500,
        marginBottom: '-1rem'
    },
    wrap : {

    },
    mail : {
        height: '100%'
    },
    icon : {
        color: grey[500],
        marginRight: '.5rem'
    },
    send: {
        height: '100%',
        background: grey[200]
    },
    sendIcon: {
        fontSize: '2rem',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
      }
}))
const Messages = (props) => {
    const { getChatUsers, currentUser, realtimeChat, socket } = props
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [currentChat, setCurrentChat] = React.useState()
    const open = Boolean(anchorEl);
    const [message, setMessage] = React.useState('')
    const [attachment, setAttachment] = React.useState([])
    
    // const [activeUser, setActiveUser] = useState([])
    // const [socketMessage, setSocketMessage] = useState('')

   // const socket = useRef();
   // const dispatch = useDispatch()

    // useEffect(() => {
    //     socket.current = io("ws://localhost:8000");
    //    // console.log("This is the socket",socket)
    //    socket.current.on('getMessage',(data)=>{
    //        //console.log(data)
    //        setSocketMessage(data)
    //    })

      
       
    //  },[])
      
//dispatch the socket message to the store
// useEffect(() => {
//     if(socketMessage && currentChat){
//          if(socketMessage.senderId === currentChat._id && socketMessage.receiverId === currentUser.id){
//               dispatch({
//                    type: 'UPDATE_CHAT',
//                    payload : socketMessage
                   
//               })
//          }
//     }
//     //setSocketMessage('')
//  },[socketMessage]);

// //Emit login user to the server
// useEffect(()=>{
//     socket.current.emit('addUser',currentUser.id,currentUser)
// },[])


//  //receiving users from socket server
//  useEffect(() => {
//     socket.current.on("getUser", (users)=>{
//         //console.log("check user",users)
//         const filterUsers = users.filter(user=>user.userId !== currentUser.id)
//         setActiveUser(filterUsers)
//     })
    
//   },[])

    useEffect(()=>{
        getChatUsers()
    },[getChatUsers])

    useEffect(()=>{
        socket.on('chat', (data) => {
            realtimeChat(data)
        })
        socket.on("sendMessage",(data)=>{
            realtimeChat(data)
        })
        
    },[realtimeChat])

 //Emit login user to the server
    useEffect(()=>{
        socket.current.emit('addUser', currentUser.id, currentUser)
    },[])

    const options = [
        {title:'Read (5)', icon: <DoneAllRounded className={classes.icon}/> },
        {title: 'Unread (15)', icon: <DoneRounded className={classes.icon}/> },
    ]

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChatHandle = (id) => {
        //filter chatUsers
       const chat = props.chats.filter( user => user.id === id)
        setCurrentChat(chat[0]);
        setShow(true)

        //call action creator
        props.getCurrentChat(id)
    }

    const onChatSubmit = () => {
        const formData = new FormData()
        formData.append('message', message);
        formData.append('attachment', attachment);
        formData.append('receiverId', currentChat.id);
       // send to socket
        socket.emit("chat", {
            message:{body:message,  attachment},
            receiverId: currentChat.id,
            senderId: currentUser.id,
        })
        //save to database
        props.newChat(formData)
        // socket.current.emit("sendMessage", {
        //     senderId:currentUser.id,
        //     senderName: currentUser.username,
        //     receiverId: currentChat.id,
        //     time: new Date (),
        //     message:{
        //         body: message
        //     }
        // })
        //reset chat input
        setMessage('')
    }

  return (
    <div className={classes.root}>
        <Typography variant='h5' className={classes.title}>Inbox (20)</Typography>
        <PageCrumbs home='' link1={{name: 'Amalyapp', path: '/'}} link2={{name: 'Inbox', path: '/account/inbox'}} />

        <Box>
            <Grid container spacing={3} className={classes.wrap}>
                <Grid item lg={4} >
                    <Card variant='outlined'>

                        {/* SEARCH & FILTER */}
                        <Box display='flex' gridGap={'.3rem'} padding ='1.2rem' bgcolor={grey[100]}>
                            <TextField variant='outlined' placeholder='Search...' fullWidth InputProps={{
                                startAdornment: <InputAdornment position='start'><Search fontSize='small' /></InputAdornment>,
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton onClick={(e)=>setAnchorEl(e.currentTarget)}><TuneRounded fontSize='medium' /></IconButton>
                                </InputAdornment>
                            }}/>
                            
                            <Popover  open={open} anchorEl={anchorEl} className={classes.pops} 
                                onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                            >
                                <Card>
                                    <CardContent style={{padding: '.5rem'}}>
                                    {
                                        options.map(el => {
                                            return <MenuItem key={el.title} style={{borderRadius: '8px'}}>
                                            {el.icon}
                                            <Typography>{el.title}</Typography>
                                            </MenuItem> 
                                        })
                                    }
                                    </CardContent>
                                </Card>
                            </Popover>
                        </Box>
                        <Divider />

                        {/* CHAT USERS */}
                        <CardContent style={{padding: '0', height: '38rem', overflowY: 'scroll'}}>
                            { props.chats && props.chats.map(el => <MessageListItem onChatHandle={()=>onChatHandle(el.id)}  key={el.username} username={el.username.toLowerCase()} />)}
                        </CardContent>
                    </Card>
                </Grid>
                
                {/* MESSAGE BOX */}
                <Grid item lg={8}>
                    <Card variant='outlined' className={classes.mail}>
                    {
                        show ? 
                        <>
                        <CardHeader title={
                            <Box display={'flex'} gridGap='1rem'>
                                <Avatar style={{borderRadius: '50px'}}/>
                                <span>
                                    <Typography variant='h6'>{currentChat.username}</Typography>
                                    <Typography variant='body2' color='textSecondary' style={{marginTop: '-5px'}}>{currentChat.email}</Typography>
                                </span>
                                <IconButton style={{marginLeft: 'auto'}}><AttachFile /></IconButton>
                            </Box>
                        } />
                        <Divider />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '1rem', padding: '0 2rem', }}>
                            {/* CHAT AREA */}
                            <Box height='33.5rem' width={'100%'} display={'block'} style={{overflowY:'scroll'}}>
                            {/* <Drawer open={true} variant="persistent" anchor="right" className={classes.drawer}>
                                <Card> <CardContent>

                                </CardContent> </Card>
                            </Drawer> */}
                            {
                                props.currentChat ? props.currentChat.map(chat => {
                                    if(chat.senderId === props.currentUser.id){
                                        return (
                                            <ChatItem key={chat._id} align={true} body={chat.message.body} time={ chat.createdAt } />
                                        )
                                    }else {
                                        return (
                                            <ChatItem key={chat._id} align={false} body={chat.message.body} time={ chat.createdAt } />
                                        )
                                    }
                                }) : 

                                <CardContent style={{height:'100%'}}>
                                    <Box textAlign={'center'} justifyContent={'center'} height='100%' display={'flex'} flexDirection='column' alignItems='center'>
                                        <span>
                                            <IconButton><ForumOutlined fontSize='large' className={classes.chatIcon} /></IconButton>
                                            <Typography variant='h6' style={{fontSize: '1.5rem'}}>Select a Conversation</Typography>
                                            <Typography color='textSecondary'>Try selecting a conversation or searching for someone specific.</Typography>
                                        </span>
                                    </Box>
                            
                                 </CardContent>
                            }
                                   
                            </Box>
                            {/* CHAT MESSAGE */}
                            <Box display={'flex'} gridGap='.5rem' width={'100%'} bgcolor='#fff'>
                                <TextField variant='outlined' placeholder='Type something' value={message} multiline fullWidth onChange={(e)=>setMessage(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><IconButton><InsertEmoticon /></IconButton></InputAdornment>,
                                    endAdornment: <InputAdornment position='end'>
                                        <IconButton onClick={()=>{}}><AttachFile fontSize='medium' /></IconButton>
                                    </InputAdornment>
                                }}/>
                                <IconButton className={classes.send} onClick={onChatSubmit}><Send className={classes.sendIcon} /></IconButton>
                            </Box>
                        </CardContent>
                    </> :
                    <CardContent style={{height:'100%'}}>
                        <Box textAlign={'center'} justifyContent={'center'} height='100%' display={'flex'} flexDirection='column' alignItems='center'>
                            <span>
                                <IconButton><ForumOutlined fontSize='large' className={classes.chatIcon} /></IconButton>
                                <Typography variant='h6' style={{fontSize: '1.5rem'}}>Select a Conversation</Typography>
                                <Typography color='textSecondary'>Try selecting a conversation or searching for someone specific.</Typography>
                            </span>
                        </Box>
                            
                    </CardContent>
                    }
                    </Card>
                </Grid>
            </Grid>
        </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {getChatUsers, newChat, getCurrentChat, realtimeChat})(Messages)