import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { AlternateEmail, ArrowBack, ArrowForward, Person, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useReducer, useState } from "react";
import Google from "../../assets/images/google.svg";
import Banner from "../../assets/images/banner3.jpg";
import { connect } from "react-redux";
import "animate.css";
import Modal from "../../components/Modal";
import { signUpUser, errorModal } from "../../actions/actions";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    width: '55%',
    margin: '0 auto',
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  subtitle: {
    display: "block",
    margin: "2rem",
    fontWeight: 500,
    color: "#fff",
  },
  field: {
    marginBottom: "1.5rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  btn: {
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
  btnGoogle: {
    padding: "1rem 0",
    background: grey[300],
    borderRadius: "8px",
  },
  flex: {
    marginBottom: "2rem",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  form: {
    width: '100%'
  },
  banner : {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems : "center",
    justifyContent : "end",
    backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 50%), transparent), url(${Banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  inside: {
    color: '#fff',
    padding: '2rem',
    background: 'rgba(225,225,225, 20%)',
    backdropFilter: 'blur(8px)',
    borderRadius: '10px',
    margin: '4rem 8rem'
  }
}));

const Register = (props) => {
  const classes = useStyles();
  const [load, setLoad] = useState(false)
  const [page, setPage] = useState(0)
  const [btnText, setBtnText] = useState('Continue')
  const[show, setShow] = useState(false)
  const[confirm, setConfirm] = useState(false)
  // const path= useLocation();

  //console.log(path)
  

  const reducerFn = (state, action) => {
    switch (action.type) {
      case "EMAIL":
        return {...state, email: action.payload}
      case "USERNAME":
        return {...state, username: action.payload}
      case "PASSWORD":
        return {...state, password: action.payload}
      case "CONFIRM_PASSWORD":
        return {...state, confirmPassword: action.payload}
      case "RESET":
        return { email: '', username : ''}    
      default:
        return state
    }
  }
  const [formInput, dispatch] = useReducer(reducerFn, { email: '', username : '', password : '', confirmPassword: ''})

  const stepBack =() => {
    setPage(0)
    setBtnText('Continue')
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    if(page === 0){
      if(formInput.username === '' || formInput.email === '' ){
        return
      }
      if(!formInput.email.includes('@')){
        props.errorModal('Invalid email address')
        return
      }
      setPage(1);
      setBtnText('Register')
    }
    if(page === 1){
      if(formInput.password === '' || formInput.confirmPassword === '' ){
        return
      }
      if( formInput.password.length < 6 || formInput.confirmPassword.length < 6){
        props.errorModal('Password must be more than 6 characters')
        return
      }
      if( formInput.password !== formInput.confirmPassword){
        props.errorModal('Sorry, passwords do not match')
        return
      }
      //setLoad(true)
      // call action creators
      props.signUpUser(formInput)
    }

  }

  const renderPage = () => {
    if (!props.currentUser) {
      return (
        <div className={classes.root}>
          {/* MODAL  */}
          {props.modal && <Modal status={props.modal.status} />}

          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className={classes.banner}>
                  <Box className={classes.inside}>
                      <Typography variant="h4" gutterBottom>
                        Work Anywhere
                      </Typography>
                      <Typography>
                        Find the right services, work anywhere anytime. Get access to quality services and control your workflow
                      </Typography>
                    <Button href="/" startIcon={<ArrowBack />} style={{ color: "#fff", marginTop: '2rem' }}>Amalyapp</Button>
                  </Box>
                </Box>
            </Grid>

            <Grid item xs={12} lg={6} className="animate__animated animate__zoomIn" >
              
                <Box className={`${classes.wrapper}`}>
                  <Typography className={classes.title} variant="h4"> Create Account</Typography>
                  <Typography style={{ marginBottom: "2.5rem" }} paragraph color="textSecondary">
                    Already have an account? <Link href="/auth/login">Login here</Link>
                  </Typography>

                  <form className={classes.form}>
                  {
                    page === 0 &&
                    <>
                      <TextField className={classes.field} variant="outlined" label="Username" helperText=""
                        value={formInput.username} onChange={(e) => dispatch({ type: "USERNAME", payload: e.target.value })} fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Person className={classes.fieldIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField className={classes.field} variant="outlined" label="Email address" helperText=""
                        value={formInput.email} onChange={(e) => dispatch({ type: "EMAIL", payload: e.target.value })} fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <AlternateEmail className={classes.fieldIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  }
                  {
                    page === 1 &&
                    <>
                      <TextField className={classes.field}  variant="outlined" label="Password" fullWidth type={show ? 'text' : 'password'}
                        value={formInput.password} onChange={(e) => dispatch({ type: "PASSWORD", payload: e.target.value })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">   
                              { show ? <Visibility  onClick={()=>setShow(!show)} className={classes.fieldIcon} /> : <VisibilityOff onClick={()=>setShow(!show)} className={classes.fieldIcon} /> }
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField className={classes.field}  variant="outlined" label="Confrim Password" fullWidth type={confirm ? 'text' : 'password'}
                        value={formInput.confirmPassword} onChange={(e) => dispatch({ type: "CONFIRM_PASSWORD", payload: e.target.value })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">   
                            { confirm ? <Visibility className={classes.fieldIcon} onClick={()=>setConfirm(!confirm)} /> : <VisibilityOff onClick={()=>setConfirm(!confirm)} className={classes.fieldIcon} /> }
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  }
                    
                    <Box display='flex' gridGap={'1rem'}>
                      { page === 1 && <Button className={classes.btn}  variant='contained' color='secondary' disableElevation onClick={() => stepBack()} fullWidth > Go Back </Button> }
                      <Button className={classes.btn} variant='contained' endIcon={load ? <CircularProgress size='15' /> : <ArrowForward /> }
                      disabled={load} disableElevation onClick={(e) => onFormSubmit(e)} color='primary' fullWidth > {btnText} </Button>
                    </Box>

                    <Box className={classes.flex} width="100%">
                      <Divider />
                      <Typography color="textSecondary" variant="body2">  or continue with </Typography>
                      <Divider />
                    </Box>
                    <Button className={classes.btnGoogle} disableElevation fullWidth >
                      <img style={{ marginRight: "1rem" }} src={Google} alt="google_icon" /> 
                      Login with Google </Button>
                  </form>
                </Box>
             
            </Grid>
          </Grid>
        </div>
      );
    }
    return <Navigate to="/" />;
  };

  return (
          <>
              {renderPage()}
          </> 
      );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { signUpUser, errorModal })(Register);
