import { Box, Button, Container, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import Modal from '../../components/Modal'
import {connect} from 'react-redux'
import { useLocation } from 'react-router-dom'
import { resetUserPassword } from '../../actions/actions'

const useStyles = makeStyles(theme => ({
    wrap : {
        display: 'flex',
        flexDirection: 'column',
        padding: '3.2rem',
        borderRadius: '16px',
        boxShadow: '-24px 24px 72px 8px rgb(145 158 171 / 24%)',
      },
      title : {
        fontSize: '1.8rem',
        marginBottom: '.5rem',
        fontWeight: 500
      },
      field : {
        marginBottom: '1.5rem',
        '& *': {
            borderRadius: '8px'
          },
        '& label.Mui-focused':{
          color: theme.primaryColor
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.primaryColor}`
          }
        }
      },
      fieldIcon: {
        color: grey[400],
        cursor: 'pointer',
      },
      btn: {
        background: theme.primaryColor,
        padding: '1rem 0',
        color: '#fff',
        borderRadius: '8px',
        marginBottom: '2rem',
        '&:hover' : {
          background: theme.secondaryColor
        }
      },

}))
const ResetPassword = (props) => {
    const classes = useStyles()
    const token = useLocation().search.split('=')[1]
    const [pass, setPass] = useState(false);
    const [confirm, setConfirm] = useState(false);
    // console.log(token)


    const reducerFn = (state, action) => {
      switch (action.type) {
        case "PASSWORD":
          return {...state, password: action.payload}
        case "CONFIRM_PASSWORD":
          return {...state, confirmPassword: action.payload}
        case "RESET":
          return { password: '', confirmPassword : ''}    
        default:
          return state
      }
    }
    const [formInput, dispatch] = useReducer(reducerFn, { password: '', confirmPassword : ''})
  
    const onFormSubmit = (e) => {
      e.preventDefault()
      if(formInput.password !== formInput.confirmPassword){
        return
      }
  
      // call action creators
      props.resetUserPassword(token, formInput)
    }

  return (
   <Container>

   {/* MODAL */}
    { props.modal && <Modal status={props.modal.status} /> }

        <Grid container style={{height: '70vh', alignItems:'center', justifyContent: 'center'}}>
            <Grid item sm={5}>
                <Box className={classes.wrap}>
                    <Typography className={classes.title} variant='h5'>Reset Password</Typography>
                     <Typography style={{marginBottom: '2.5rem'}} paragraph color='textSecondary'>Password must be more than 8 characters. Passwords must also match</Typography>

                     <TextField className={classes.field} variant='outlined' label='Password'  value={formInput.password} onChange={(e)=>dispatch({type:"PASSWORD", payload: e.target.value})}
                        helperText='' fullWidth type={ pass ? 'text' : 'password'} InputProps={{ endAdornment: <InputAdornment position='start'>{ pass ? <Visibility className={classes.fieldIcon} onClick={()=> setPass(!pass)} /> : <VisibilityOff className={classes.fieldIcon} onClick={()=> setPass(!pass)} />} </InputAdornment>}}
                     />
                      <TextField className={classes.field} variant='outlined' label='Confirm Password'  value={formInput.confirmPassword} onChange={(e)=>dispatch({type:"CONFIRM_PASSWORD", payload: e.target.value})}
                        helperText='' fullWidth type={ confirm ? 'text' : 'password'} InputProps={{ endAdornment: <InputAdornment position='start'>{ confirm ? <Visibility className={classes.fieldIcon} onClick={()=> setConfirm(!confirm)} /> :  <VisibilityOff className={classes.fieldIcon} onClick={()=> setConfirm(!confirm)} /> } </InputAdornment>}}
                     />
                      <Button className={classes.btn} disableElevation variant='contained' type='submit' fullWidth onClick={(e) => onFormSubmit(e)}>Reset Password</Button>
                </Box>
            </Grid>
        </Grid>

   </Container>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {resetUserPassword})(ResetPassword)