import { Box, Card, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'


const useStyles = makeStyles(theme => ({
    gallery: {
        height: '8.5rem',
        borderRadius: '10px',
        backgroundImage: props => `url(${ props.image })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
    },
    iconBtn : {
        background: '#eee',
        padding: '6px'
    }
}))

const GalleryItem = (props) => {
    const classes = useStyles(props)

  return (
    <div>
        <Card variant='outlined' className={classes.gallery}>
            <Box position={'absolute'} height='100%' width='100%' padding={'.4rem'}>
                <IconButton className={classes.iconBtn} onClick={()=> props.delete()}><Close fontSize='small' /></IconButton>
            </Box>
        </Card>
    </div>
  )
}

export default GalleryItem