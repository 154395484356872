import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DataGrid } from '@material-ui/data-grid'
import React, { useState } from 'react'
import PageCrumbs from '../../components/PageCrumbs'
import { Visibility } from '@material-ui/icons'
import JobCard from '../../components/JobCard'
import { connect } from 'react-redux'
import { readNotification } from '../../actions/actions'


const useStyles= makeStyles(theme => ({
    root : {
        '& *:not(.MuiDataGrid-root *)' : {
            borderRadius: '10px',
          }
    },
    title: {
        fontWeight: 500,
        marginBottom: '-1rem'
    },
    subtitle: {
        fontSize: '1rem'
    },
    card : { 
        padding: '1rem',
        '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
        }
    },
    avatar: {
        background: theme.primaryColor
    }

}))
const Notifications = (props) => {
    const classes = useStyles()
    const [pageSize, setPageSize] = useState(5)
    const [open, setOpen] = useState(false)
    const [current, setCurrent] = useState()
    const read = props.notifications && props.notifications.filter(el => el.isRead === 'read')
    const unread = props.notifications && props.notifications.filter(el => el.isRead === 'unread')

    const columns = [
        {field: 'updatedAt', headerName: 'Notications', flex: 2, renderCell: (params) => {
            return (
                <List style={{padding: '0',  display: 'contents'}}>
                <ListItem style={{padding: '.5rem 0',}}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>{params.row.subject.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <Typography variant='h6' className={classes.subtitle}>{params.row.subject}</Typography>
                        <Typography variant='body2' color='textSecondary' noWrap>{params.row.body}</Typography>
                    </ListItemText>
                </ListItem>
             </List>
                )
            }
        }, 
        {field: 'createdAt', headerName: 'Date', flex: 1, renderCell: (params) =>  <Typography variant='body2' color='textSecondary'>{new Date(params.value).toDateString()}</Typography> }, 
        {field: 'isRead', headerName:' Status', flex: 1, renderCell: (params) => { return <Chip disabled label={params.value} />} },
        {field: '_id', headerName:' Action', flex: 1, renderCell: (params) => { return <>
            <IconButton onClick={() => readNotification(params.value)} >
                <Visibility fontSize='small' />
            </IconButton> 
            </>
        }}
      ]

      const readNotification = (id) => {
          const res = props.notifications && unread.filter(el => el.id === id)[0]
          setCurrent(res)
          setOpen(true)
          props.readNotification(id)
      }

  return (
    <div className={classes.root}>

        <Typography variant='h5' className={classes.title}>Notifications ({props.notifications && props.notifications.length})</Typography>
        <PageCrumbs home='' link1={{name: 'Amalyapp', path: '/'}} link2={{name: 'Notifications', path: '/account/notifications'}} />

        <Grid container spacing={4}>
            <Grid item lg={3}>
                <Card variant='outlined' className={classes.card}>
                    <CardContent>
                        <FormControlLabel control={<Switch color='primary' checked />} label={<Typography>Email Notification</Typography>} />
                        <FormControlLabel control={<Switch color='primary' />} label={<Typography>SMS Notification</Typography>} />
                        <FormControlLabel control={<Switch />} label={<Typography>Newsletters</Typography>} />
                    </CardContent>
                </Card>

                <JobCard />

            </Grid>

            <Grid item lg={9}>
                <Box display={'flex'} gridGap={'1rem'} marginBottom='1rem' >
                    <Button variant='outlined' disableElevation> Read({ props.notifications && read.length})</Button>
                    <Button variant='outlined' disableElevation> Unread({props.notifications && unread.length})</Button>
                </Box>
                <DataGrid autoHeight style={{background: '#fff'}}
                        pagination rows={props.notifications ? props.notifications : []} rowsPerPageOptions={[5, 10, 15, 20]}
                        rowHeight={100} columns={columns} getRowId={(row)=> row._id}
                        pageSize={pageSize} checkboxSelection 
                        onPageSizeChange={(newSize)=> setPageSize(newSize)}
                />

            </Grid>

        </Grid>

        {/* DIALOG */}
        <Dialog open={open}>
            <DialogTitle  >
                <Box display='flex' gridGap='1rem'>
                    <Avatar color='primary'>{current && current.subject.charAt(0)}</Avatar>
                    <span>
                        <Typography variant='h6'>{current && current.subject}</Typography>
                        <Typography variant='body2' color='textSecondary'>{ current && new Date(current.createdAt).toDateString()}</Typography>
                    </span>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Typography >
                    { current && current.body}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>

    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {readNotification})(Notifications)