import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, Container, Dialog, DialogActions, Divider, FormControlLabel, Grid, InputAdornment, LinearProgress, MenuItem, Step, StepLabel, Stepper, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles, withStyles } from '@material-ui/styles'
import CardImage from '../../assets/images/sellerbanner.jpg'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Modal from '../../components/Modal'
import { getAllCategories, errorModal, createNewGig } from '../../actions/actions'
import { grey } from '@material-ui/core/colors'
import Upload from '../../assets/images/file-upload.png'
import Finish from '../../assets/images/finished.png'
import GalleryItem from '../../components/GalleryItem'
import LoadGig from '../../assets/images/browser.gif'
import FaqImg from '../../assets/images/faqs.png'
import FaqItem from '../../components/FaqItem'
import RequirementItem from '../../components/RequirementItem'


const useStyles = makeStyles(theme => ({
    root : {
      marginTop: '-2rem',
      padding: '0 1rem',
      paddingTop: '2.5rem',
      background: '#b4b4b412',
      '& .MuiStepper-alternativeLabel': {
        background: 'transparent'
      },
      '& .MuiTable-root': {
        border : `1px solid #e2e2e2 !important`
      },
      '& .MuiTableRow-root': {
        height: '5rem'
      },
      '& .MuiTableHead-root': {
        background: grey[400]
      }
    },
    field : {
      marginBottom: '1.5rem',
      borderRadius: '1rem'
    },
    wrapper : {
      padding: '2.5rem'
    },
    card : {
      marginTop: '1rem',
      borderRadius: '.8rem',
      padding: '1rem',
      '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)'
        }
  },
  table : {
    border : `1px solid ${grey}`
  },
  input: {
    maxWidth: '7rem'
  }
}))

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NewGig = (props) => {
    const classes = useStyles()
    const { getAllCategories } = props
    const [page, setPage] = useState(0)
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState('')
    const [value, setValue] = useState('Save')
    const [index, setIndex] = useState()
    const [subCategory, setSubCategory] = useState([])
    const [uploads, setUploads] = useState([])
    const [packages, setPackages] = useState([])
    const [faqs, setFaqs] = useState({ques: '', ans: ''})
    const [reqs, setReqs] = useState([])
    const browse = useRef()
    const tags = ['Photoshop', 'React','Nodejs', 'Adobe Suit', 'Illustrator','Content Writing', 'Blogging', 'Voice Over', 'Wordpress', 'Flutter', 'Mobile Apps', 'SEO','Website', 'Digital Marketing', 'Video Editing', 'Animation']
    const count = [100,200,300,400,500,600,700,800,900,1000,2000]
    const count2 = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]

    useEffect( ()=> {
      getAllCategories()
    },[getAllCategories])


    const initState = {
      title: '',
      category: '',
      subcategory: '',
      tags: [],
      description: '',
      gallery: [],
      faqs: [],
    }

    const basicPackage = {
      Price: '',
      Delivery: '',
      Revision: ''
    }

    const standardPackage = {
      Price: '',
      Delivery: '',
      Revision: ''
    }

    const premiumPackage = {
      Price: '',
      Delivery: '',
      Revision: ''
    }

    const reqState = {
      question: '',
      answer: '',
      required: false,
    }

    const filterCategory = (val) => {
      setSubCategory([])
      const subs = props.allCategories.filter(el => el.category === val)[0];
      setSubCategory(subs.subcategories)
    }

    const filterSubCategory = (val) => {
      const results = Object.keys(subCategory).filter(el => el === val).reduce((obj, key)=> {
        return obj[key] = subCategory[key];
      },{})
      console.log(results)
      setPackages(results)
    } 
    

    const reqReducer = (state, action) => {
      switch (action.type) {
        case "QUESTION":
          return {...state, question: action.payload}
        case "ANSWER":
          return {...state, answer: action.payload}
        case "REQUIRED":
          return {...state, required: action.payload}
        case "CHANGE_STATE":
          return action.payload
        case "RESET":
          return reqState
        default:
          return state;
      }
    }


    const reducerFn = (state, action) => {
      switch (action.type) {
        case "TITLE":
          return {...state, title: action.payload}
        case "TAGS":
          return {...state, tags: action.payload }
        case "CATEGORY":
          filterCategory(action.payload)
          return {...state, category: action.payload}
        case "SUBCATEGORY":
          filterSubCategory(action.payload)
          return {...state, subcategory: action.payload}
        case "DESCRIPTION":
          return {...state, description: action.payload}
        case "FAQ":
          return {...state, faqs: [...state.faqs, action.payload ]}
        case "GALLERY":
          return {...state, gallery: [...state.gallery, action.payload]}
        case "UPDATE_GALLERY":
          return {...state, gallery: action.payload}
        default:
          return initState
      }
    }

    // basic reducer function
    const basicReducerFn = (state, action) => {
      switch (action.type) {
          case "BASIC_ADD":
              let newInput = Object.fromEntries(new Map([action.payload]))
              return {...state, ...newInput}
          case "BASIC_PRICE":
              return {...state, Price: action.payload}
          case "BASIC_DELIVERY":
              return {...state, Delivery: action.payload}
          case "BASIC_REVISION":
              return {...state, Revision: action.payload}
          case "RESET":
              return basicPackage
          default:
              return state;
      }
  }
// standard reducer function
const standardReducerFn = (state, action) => {
  switch (action.type) {
          case "STANDARD_ADD":
              let newInput = Object.fromEntries(new Map([action.payload]))
              return {...state, ...newInput}
          case "STANDARD_PRICE":
              return {...state, Price: action.payload}
          case "STANDARD_DELIVERY":
              return {...state, Delivery: action.payload}
          case "STANDARD_REVISION":
              return {...state, Revision: action.payload}
          case "RESET":
              return standardPackage
          default:
              return state;
  }
} 
// premium reducer function
const premiumReducerFn = (state, action) => {
  switch (action.type) {
            case "PREMIUM_ADD":
              let newInput = Object.fromEntries(new Map([action.payload]))
              return {...state, ...newInput}
          case "PREMIUM_PRICE":
              return {...state, Price: action.payload}
          case "PREMIUM_DELIVERY":
              return {...state, Delivery: action.payload}
          case "PREMIUM_REVISION":
              return {...state, Revision: action.payload}
          case "RESET":
              return premiumPackage
          default:
              return state;
  }
}

      // description reducer
      const [formInput, dispatch] = useReducer(reducerFn, initState)
      const [basicInput, basicDispatch] = useReducer(basicReducerFn, basicPackage)
      const [standardInput, standardDispatch] = useReducer(standardReducerFn, standardPackage)
      const [premiumInput, premiumDispatch] = useReducer(premiumReducerFn, premiumPackage)
      const [reqInput, reqDispatch] = useReducer(reqReducer, reqState)

      const stepBack = () => {
        // if(page===1){
        //   standardDispatch({type:"RESET"})
        //   basicDispatch({type:"RESET"})
        //   premiumDispatch({type:"RESET"})
        // }
        setPage( prev => {
          return prev - 1
        })

      }
  
    const onFormSubmit = () => {
      const data = {
        ...formInput,
        basicPackage: basicInput, 
        standardPackage: standardInput,
        premiumPackage: premiumInput
      }
      console.log(data)
      if(page === 0){
        //..do checks
        if(formInput.title === '' || formInput.category === '' || formInput.subcategory === '' || formInput.tags === [] || formInput.description === ''){
          //console.log(formInput)
          props.errorModal('Invalid. All fields are required')
          return
        }
        //console.log(formInput)
        setPage(1)
      }
      if(page === 1){
        //console.log(basicInput)
        if(basicInput.Price === '' || basicInput.Delivery === '' || basicInput.Revision === '' || standardInput.Price === '' || standardInput.Delivery === '' || standardInput.Revision === '' || premiumInput.Price === '' || premiumInput.Delivery === '' || premiumInput.Revision === ''){
          props.errorModal('Sorry. Please provide your price package')
          return
        }
        setPage(2)
      }
      if(page === 2){
        if(formInput.gallery.length === 0){
          props.errorModal('Sorry. Please provide gallery')
          return
        }
        setPage(3)
      }
      if(page === 3){
        if(reqs.length === 0){
          props.errorModal('Please provide at least 1 requirement')
          return
        }
        setLoader(true)
        //formData
        const data = new FormData()
        data.append('title', formInput.title);
        data.append('category', formInput.category);
        data.append('subcategory', formInput.subcategory);
        data.append('description', formInput.description);
        data.append('basic', JSON.stringify(basicInput));
        data.append('standard', JSON.stringify(standardInput));
        data.append('premium', JSON.stringify(premiumInput));

        //add gallery
        if(formInput.gallery.length > 0){
          for (let index = 0; index < formInput.gallery.length; index++) {
              data.append('gallery', formInput.gallery[index])
          }
        }
        //add tags
        if(formInput.tags.length > 0){
          for (let index = 0; index < formInput.tags.length; index++) {
              data.append('tags', formInput.tags[index])
          }
        }
        //add faqs
        if(formInput.faqs.length > 0){
          for (let index = 0; index < formInput.faqs.length; index++) {
              data.append('faqs', formInput.faqs[index])
          }
        }

        //add requirements
        if(reqs.length > 0){
          for (let index = 0; index < reqs.length; index++) {
              data.append('requirements', JSON.stringify(reqs[index]))
          }
        }

        setTimeout( async() => {
            const res = await props.createNewGig(data) 
            //console.log(res)
            if(res === 'success'){
              setPage(5)
              setLoader(false)
            }else{
              setLoader(false)
              props.errorModal('Sorry, something went wrong')
            }
        },1000)
      }
      
    }

    
    const selectInput = (text, value, fn,type) => {
      return (
          <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={value} onChange={(e)=> fn({type: type, payload: e.target.value})}>
            {
              [2,3,4,5,6,7,8,9].map((el)=> {
                return <MenuItem key={el} value={el}><Typography>{el} {text}</Typography></MenuItem>
              })
            }
          </TextField>
          )
    }

    const readFile = (file) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const result = event.target.result;
        //console.log(event)
        setUploads(prev => [...prev, result])
      });
      reader.readAsDataURL(file);
    }

    const onImageSelect = (e) => {
      for (let i = 0; i < e.target.files.length; i++){
          if(e.target.files[i].size >= 500000){
              props.errorModal(`Invalid file size: Image(${i + 1}) is too large`)
              return;
          }
          let duplicate = uploads.find(file => file.name === e.target.files[i].name);
          if(duplicate !== undefined){
              props.errorModal(`Invalid: '${e.target.files[i].name}' is already selected`)
              return;
          }
          //readfiles
          readFile(e.target.files[i])
          // setGallery(prev => [...prev, e.target.files[i]])
          dispatch({type: "GALLERY", payload: e.target.files[i]})
      }
      
  }

  const onImageDelete = (index) => {
    const filterUploads = uploads.filter((image, i) => i !== index);
    const filterGallery = formInput.gallery.filter((image, i) => i !== index);
    setUploads(filterUploads)
    dispatch({type: "UPDATE_GALLERY", payload: filterGallery})
  }

  const addFAQ = () => {
    if(faqs.ques === '' || faqs.ans === ''){
      props.errorModal('Sorry, please provide all fields')
      return
    }

    dispatch({type: "FAQ", payload: {ques: faqs.ques, ans: faqs.ans}})
    setFaqs({ques: '', ans: ''})
    setOpen(false)
  }

  // Open Requirement Box
  const openReq = () => {
    setValue('Save')
    setOpen('requirement')
    reqDispatch({type: "RESET"})
  }

  // Save Requirement
  const saveReq = (val) => {
    if(val === 'Save'){
      if(reqInput.question === '' || reqInput.answer === '' ){
        props.errorModal('You must add at least 1 requirement')
        return
      }
      //reset the fields
      reqDispatch({type: "RESET"})
  
      //update the array state
      // console.log(reqInput)
      //setReqs(reqs.push(reqInput))
      setReqs([...reqs, reqInput])
      setOpen(false)
    }
    else{
      //update requirements
      if(reqInput.question === '' || reqInput.answer === '' ){
        props.errorModal('You must add at least 1 requirement')
        return
      }
      //reset the fields
      reqDispatch({type: "RESET"})
      setReqs(prev => {
        prev[index] = reqInput
        return prev
      })
      setOpen(false)
    }

  }

  //Edit Requirement
  const editRequirement = (index, val) => {
    setValue(val)
    setOpen('requirement')
    setIndex(index)
    const current = reqs.filter(el => reqs.indexOf(el) === index)[0] 
    reqDispatch({type: "CHANGE_STATE", payload: current})
  }

  //Delete Requirement
  const deleteRequirement = (index) => {
    const current = reqs.filter(el => reqs.indexOf(el) !== index)
    setReqs(current)
  }

    
  return (
    <div className={classes.root}>
      { props.modal && <Modal status={props.modal.status} />}
      
      <Container>
          <Box marginBottom={'1rem'}>
            <Stepper alternativeLabel activeStep={page}>
              <Step>
                <StepLabel>Overview</StepLabel>
              </Step>
              <Step>
                <StepLabel>Pricing</StepLabel>
              </Step>
              <Step>
                <StepLabel>Gallery</StepLabel>
              </Step>
              <Step>
                <StepLabel>Requirements</StepLabel>
              </Step>
              <Step>
                <StepLabel>Publish</StepLabel>
              </Step>
            </Stepper>
          </Box>

          <Grid container spacing={4}>
            <Grid item sm={8}>

              <Card variant='outlined' className={classes.card}>
                <CardContent className={classes.wrapper}>

                  {/* OVERVIEW PAGE */}
                  {
                    page === 0 &&
                    <>
                        <TextField variant='outlined' label='Gig Tite' fullWidth className={classes.field} value={formInput.title} onChange={(e)=>dispatch({type: "TITLE", payload: e.target.value})}/>
                        <TextField variant='outlined' select label='Category' fullWidth className={classes.field} value={formInput.category} onChange={(e)=> dispatch({type: "CATEGORY", payload: e.target.value})}>
                            {
                            props.allCategories ? props.allCategories.map((el)=> {
                                return <MenuItem key={el.category} value={el.category}>
                                          <Typography>{el.category}</Typography>
                                      </MenuItem>
                              }) : 
                              <MenuItem>
                                  <Typography>Loading data...</Typography>
                              </MenuItem>
                            }
                        </TextField>
                        <TextField variant='outlined' select label='Sub-Category' value={formInput.subcategory} fullWidth className={classes.field} onChange={(e)=> dispatch({type: "SUBCATEGORY", payload: e.target.value})}>
                        {
                              Object.keys(subCategory).map((el)=> {
                                return <MenuItem key={el} value={el}>
                                        <Typography>{el}</Typography>
                                      </MenuItem>
                              })
                            }
                        </TextField>
                        <Autocomplete multiple options={tags} limitTags={4} value={formInput.tags} onChange={(e, values) => dispatch({type:"TAGS", payload: values})} 
                          renderInput={(params) => (
                            <TextField {...params} className={classes.field} variant='outlined' label='Tags' fullWidth  />
                          )} />
                        <TextField variant='outlined' label='Description' multiline rows={8} value={formInput.description} onChange={(e)=>dispatch({type: "DESCRIPTION", payload: e.target.value})} fullWidth className={classes.field} />

                    </>
                  }

                  {/* PRICING PAGE */}
                  {
                    page === 1 && 
                    <>
                      <Table className={classes.table} >
                        <TableHead>
                          <TableRow>
                            <TableCell><Typography variant='h6'>Packages</Typography></TableCell>
                            <TableCell align='center' ><Typography  variant='h6'>Basic</Typography></TableCell>
                            <TableCell align='center'><Typography variant='h6'>Standard</Typography></TableCell>
                            <TableCell align='center'><Typography variant='h6'>Premium</Typography></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {/* SELECTED PACKAGE */}
                          {
                            packages.map(pk => {
                              if(pk === ''){
                                return null
                              }
                              if(pk.type === 'text'){
                                return (
                                  <StyledTableRow key={pk.name}>
                                    <TableCell><Typography color='textSecondary'>{pk.name}</Typography> </TableCell>
                                    <TableCell align='center'> <Checkbox color='primary' checked={basicInput[pk.name] === 'yes' ? true : false} defaultValue={''} value={basicInput[pk.name]} onChange={(e)=> basicDispatch({type: "BASIC_ADD", payload:[ pk.name, e.target.checked ? 'yes' : 'no' ] })} /> </TableCell>
                                    <TableCell align='center'> <Checkbox color='primary' checked={standardInput[pk.name] === 'yes' ? true : false} defaultValue={''} value={standardInput[pk.name]} onChange={(e)=> standardDispatch({type: "STANDARD_ADD", payload:[ pk.name, e.target.checked ? 'yes' : 'no'] })}/> </TableCell>
                                    <TableCell align='center'> <Checkbox color='primary' checked={premiumInput[pk.name] === 'yes' ? true : false} defaultValue={''} value={premiumInput[pk.name]} onChange={(e)=> premiumDispatch({type: "PREMIUM_ADD", payload:[ pk.name, e.target.checked ? 'yes' : 'no' ] })} /> </TableCell>
                                  </StyledTableRow>
                                )
                              }
                              if(pk.type === 'number' && pk.name === 'Words Included'){
                                  return (
                                    <StyledTableRow key={pk.name}>
                                    <TableCell><Typography color='textSecondary'>{pk.name}</Typography> </TableCell>
                                    <TableCell align='center'>
                                          <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={basicInput[pk.name]} onChange={(e)=> basicDispatch({type: "BASIC_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                    </TableCell>
                                    <TableCell align='center'>
                                          <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={standardInput[pk.name]} onChange={(e)=> standardDispatch({type: "STANDARD_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={premiumInput[pk.name]} onChange={(e)=> premiumDispatch({type: "PREMIUM_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                      </TableCell>
                                  </StyledTableRow>
                                  )
                              }
                              else {
                                return (
                                  <StyledTableRow key={pk.name}>
                                    <TableCell><Typography color='textSecondary'>{pk.name}</Typography> </TableCell>
                                    <TableCell align='center'>
                                          <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={basicInput[pk.name]} onChange={(e)=> basicDispatch({type: "BASIC_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count2.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                    </TableCell>
                                    <TableCell align='center'>
                                          <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={standardInput[pk.name]} onChange={(e)=> standardDispatch({type: "STANDARD_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count2.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField size='small' className={classes.input} variant='outlined' select fullWidth label='Select' defaultValue={''} value={premiumInput[pk.name]} onChange={(e)=> premiumDispatch({type: "PREMIUM_ADD", payload:[pk.name, e.target.value]})}>
                                            {
                                              count2.map((el)=> {
                                                return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                                              })
                                            }
                                          </TextField>
                                      </TableCell>
                                  </StyledTableRow>
                                )
                              }
                            })
                          }


                          {/* DELIVERY */}
                          <TableRow>
                            <TableCell><Typography color='textSecondary'>Delivery</Typography></TableCell>
                            <TableCell align='center'> {selectInput('days', basicInput.delivery, basicDispatch, "BASIC_DELIVERY")} </TableCell>
                            <TableCell align='center'> {selectInput('days', standardInput.delivery, standardDispatch, "STANDARD_DELIVERY")} </TableCell>
                            <TableCell align='center'> {selectInput('days', premiumInput.delivery, premiumDispatch, "PREMIUM_DELIVERY")} </TableCell>
                          </TableRow>

                          {/* REVISION */}
                          <TableRow >
                            <TableCell><Typography color='textSecondary'>Revisions</Typography></TableCell>
                            <TableCell align='center'> {selectInput(null, basicInput.revision, basicDispatch, "BASIC_REVISION")} </TableCell>
                            <TableCell align='center'> {selectInput(null, standardInput.revision, standardDispatch,  "STANDARD_REVISION")} </TableCell>
                            <TableCell align='center'> {selectInput(null, premiumInput.revision, premiumDispatch, "PREMIUM_REVISION")} </TableCell>
                          </TableRow>

                            {/* PRICING */}
                          <TableRow>
                              <TableCell component="th" scope="row"><Typography color='textSecondary'>Pricing</Typography></TableCell>
                              <TableCell align='center'>
                                <TextField size='small' type={'number'} inputProps={{min: '0'}} InputProps={{ endAdornment: <InputAdornment position='end'>¢</InputAdornment>}} 
                                  className={classes.input} variant='outlined' placeholder='0.00' value={basicInput.price} 
                                  onChange={(e)=> basicDispatch({type: "BASIC_PRICE", payload: e.target.value})} 
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <TextField size='small' type={'number'} inputProps={{min: '0'}} InputProps={{ endAdornment: <InputAdornment position='end'>¢</InputAdornment>}} 
                                  className={classes.input} variant='outlined' placeholder='0.00' value={standardInput.price} 
                                  onChange={(e)=> standardDispatch({type: "STANDARD_PRICE", payload: e.target.value})} 
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <TextField size='small'type={'number'} inputProps={{min: '0'}}  InputProps={{ endAdornment: <InputAdornment position='end'>¢</InputAdornment>}} 
                                  className={classes.input} variant='outlined' placeholder='0.00' value={premiumInput.price} 
                                  onChange={(e)=> premiumDispatch({type: "PREMIUM_PRICE", payload: e.target.value})} 
                                />
                              </TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </>
                  }

                  {/* GALLERY */}
                  {
                    page === 2 && 
                    <>
                      <Box style={{cursor: 'pointer'}} onClick={() => browse.current.click()} padding={'4rem'} border='1px dashed lightgrey' borderRadius={'10px'} textAlign='center' marginBottom={'1.5rem'}>
                          <input ref={browse} type="file" style={{display: 'none'}} name='gallery' accept='.jpg, .png' onChange={(e)=> onImageSelect(e)}/>
                          <img src={Upload} alt='file upload' width={'20%'}/>
                          <Typography color='textSecondary' variant='body1'>Click to browse and upload images</Typography>
                          <Typography color='textSecondary' variant='body2'>Max File Size - 0.5MB</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        {
                          uploads.map((file, index) => {
                            return (
                              <Grid item sm={3} key={index}> <GalleryItem image={file} delete={() => onImageDelete(index)} /> </Grid>
                            )
                          })
                        }
                      </Grid>
                    </>
                  }

                  {/* REQUIREMENTS*/}

                  {
                    page === 3 &&
                    <>
                    {
                      reqs.length > 0 ?
                      <Box>
                        <Typography variant='h6' gutterBottom>Requirements({reqs.length > 0 ? reqs.length : 0})</Typography>
                        {
                          reqs.length > 0 && reqs.map((el, index) => {
                           return <RequirementItem key={el.question} question={el.question} answer={el.answer} required={el.required} clickFn={() => editRequirement(index, 'Update')} delete={() => deleteRequirement(index)} />
                          })
                        }
                        <Button variant='contained' disableElevation style={{marginTop: '1rem'}} onClick={openReq}>Add Requirement</Button>
                      </Box>
                      :
                      <Box textAlign={'center'}>
                        <img src={FaqImg} width='15%' alt='faq img' style={{marginBottom: '1rem'}} />
                        <Typography variant='h6' gutterBottom>Get all the information you need from buyers to get started</Typography>
                        <Typography color='textSecondary'>Add questions to help buyers to provide you with exactly what <br/ >you need to start working on their order</Typography>
                        <Button variant='contained' disableElevation style={{marginTop: '1rem'}} onClick={openReq} >Add Requirements</Button>
                      </Box>
                    }
                    <Divider style={{margin: '2rem 0'}} />

                    {/* FAQS */}
                    {
                      formInput.faqs.length > 0 ?
                      <Box>
                        <Typography variant='h6' gutterBottom>Frequently Asked Questions(1)</Typography>
                        {
                          formInput.faqs.length > 0 && formInput.faqs.map(el => {
                           return <FaqItem key={el.ques} question={el.ques} answer={el.ans} />
                          })
                        }
                        <Button variant='contained' disableElevation style={{marginTop: '1rem'}} onClick={()=>setOpen('faq')}>Add FAQ</Button>
                      </Box>
                      :
                      <Box textAlign={'center'}>
                        
                        <Typography variant='h6'>Frequently Asked Questions (FAQs)</Typography>
                        <Typography color='textSecondary'>You can answer some questions you think your buyers may ask here</Typography>
                        <Button variant='contained' disableElevation style={{marginTop: '1rem'}} onClick={()=>setOpen('faq')} >Add FAQ</Button>
                      </Box>
                    }
                    </>
                  }

                  {/* COMPLETE */}

                  {
                    page === 5 &&
                    <>
                      <Box textAlign={'center'} >
                        <img src={Finish} alt='finish' width={'30%'} style={{marginBottom: '2rem'}}  />
                        <Typography variant='h5' gutterBottom>Congratulations!!</Typography>
                        <Typography color='textSecondary' gutterBottom>You have successfully created your gig. <br/>Click "Proceed" to view your Gig</Typography>
                        <Button variant='contained' disableElevation href='/seller/gigs' color='primary' style={{color:"#fff", marginTop: '1rem'}} >Proceed</Button>
                      </Box>
                    </>
                  }

                </CardContent>
              </Card>

              {/* SAVE BUTTON */}
              <Box display={'flex'} justifyContent='flex-end' gridGap={'1rem'} marginTop={'2rem'}>
                 { page > 0 && page < 4 ? <Button variant='contained' disableElevation onClick={stepBack} >Previous</Button> : null }
                { page < 5 && <Button variant='contained' disableElevation onClick={onFormSubmit} color='primary' style={{color:"#fff"}} >{ page === 3 ? 'Save & Publish' : 'Save & Continue' }</Button> }
              </Box>

            </Grid>

            <Grid item sm={4}>
              <Card variant='outlined' className={classes.card}>
              <CardActionArea>
                  <CardMedia component="img" alt="New Gig" height="180"
                    image={CardImage} style={{borderRadius: '.8rem'}}
                    />
                  <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">How to create a Gig</Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                        across all continents except Antarctica
                        
                      </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>

      </Container>

      {/* LOADER */}
      <Dialog open={loader} maxWidth={'sm'}>
        <Box padding={'2.5rem'} textAlign={'center'}>
            <img src={LoadGig} alt='creating gig' width={'25%'}/>
            <Typography paragraph>Creating your Gig. Please wait.</Typography>
            <LinearProgress />
        </Box>
      </Dialog>

      {/* REQUIREMENT */}
      <Dialog open={open === 'requirement' ? true : false} maxWidth='sm'>
        <Box padding={'2rem 2rem 0 2rem'} textAlign={'center'} width='30rem'>
             <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography>Add a question</Typography>
              <FormControlLabel control={<Switch checked={reqInput.required} onChange={(e) => reqDispatch({type: "REQUIRED", payload: !reqInput.required})} color='primary' />} label={'Required'} />
             </span>
            <TextField className={classes.field} multiline rows={5} fullWidth variant='outlined' value={reqInput.question} label='Question' onChange={(e)=> reqDispatch({type: "QUESTION", payload: e.target.value })} />
            
            <Typography style={{textAlign: 'left'}} gutterBottom>Get answer in the form of</Typography>
            <TextField variant='outlined' required select fullWidth className={classes.field} value={reqInput.answer} onChange={(e)=> reqDispatch({type: "ANSWER", payload: e.target.value})}>
                {
                  ['Text','Attachment'].map((el)=> {
                    return <MenuItem key={el} value={el}><Typography>{el}</Typography></MenuItem>
                  })
                }
            </TextField>
           
        </Box>
        <DialogActions style={{padding: '1rem 2rem '}}>
            <Button variant='contained' disableElevation onClick={()=>setOpen(false)} >Cancel</Button>
            <Button variant='contained' color='primary' disableElevation style={{color:'#fff'}} onClick={() => saveReq(value)} >Save Requirement</Button>
        </DialogActions>
      </Dialog>

      {/* FAQS */}
      <Dialog open={open === 'faq' ? true : false} maxWidth={'sm'}>
        <Box padding={'2rem 2rem 0 2rem'} textAlign={'center'}>
            <TextField className={classes.field} fullWidth variant='outlined' value={faqs.ques} label='Question' onChange={(e)=> setFaqs( prev => {return {...prev, ques: e.target.value}})} />
            <TextField className={classes.field} fullWidth multiline rows={6} value={faqs.ans} variant='outlined' label='Answer' onChange={(e)=> setFaqs( prev => {return {...prev, ans: e.target.value}})} />
        </Box>
        <DialogActions style={{padding: '1rem 2rem '}}>
            <Button variant='contained' disableElevation onClick={()=>setOpen(false)} >Cancel</Button>
            <Button variant='contained' color='primary' disableElevation style={{color:'#fff'}} onClick={addFAQ} >Save FAQ</Button>
        </DialogActions>
      </Dialog>
        
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {getAllCategories, errorModal, createNewGig})(NewGig)