import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import PageNotFound from '../assets/images/error-404.png'

const NotFound = () => {



  return (
    <Box textAlign={'center'} padding='3rem' height={'100vh'}>
      <img src={PageNotFound} width='40%' alt='404' />
      <Typography variant='h3' style={{fontWeight: 600}} gutterBottom>Oops, You Broke Something</Typography>
      <Typography paragraph>The page you are looking for does not exist. Go back to the homepage</Typography>

      <Button href='/' variant='contained' size='large' disableElevation color='primary' style={{color: '#fff', marginTop: '1rem'}}  >HomePage</Button>

    </Box>
  )
}

export default NotFound