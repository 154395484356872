import { Box, Chip, IconButton, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Delete, Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'


const useStyles = makeStyles(theme => ({
    iconBtn: {
        background: grey[100],
        padding: '6px'
    },
    wrap: {
        border: '1px solid lightgrey',
        borderRadius: '10px',
        padding:'1rem',
        marginBottom: '1rem',
        transition: 'all .2s ease',
        '&:hover': {
            border: `1px solid ${theme.primaryColor}`
        },
        '&:hover #btns':{
            opacity: 1
        }
    },
    btns : {
        opacity: 0,
        transition: 'all .2s ease'
    }
}))

const RequirementItem = (props) => {
    const classes = useStyles()

  return (
    <Box className={classes.wrap}>
        <Box display='flex' justifyContent={'space-between'}>
            <Typography variant='h6' gutterBottom>{props.question}</Typography>
            <span id='btns' className={classes.btns}>
                <IconButton className={classes.iconBtn} onClick={() => props.clickFn()}><Edit fontSize='small' /></IconButton>
                <IconButton style={{marginLeft: '6px'}} onClick={() => props.delete()} className={classes.iconBtn}><Delete fontSize='small' /></IconButton>
            </span>
        </Box>
        <Chip label={<Typography variant='body2' color='textSecondary'>{props.answer}</Typography>} />
        { props.required === true ? <Chip style={{marginLeft: '.5rem'}} label={<Typography variant='body2' color='textSecondary'>Required</Typography>} /> : null }
    </Box>
  )
}

export default RequirementItem