import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
   
    typography : {
        fontFamily: "Plus Jakarta Sans",
        fontSize: 13
    },
    palette : {
        primary: {
            main: '#ED8A2F'
        },
        secondary : {
            main: '#3f5176'
        }
    },
    primaryColor : '#ED8A2F',
    secondaryColor: '#3f5176',
    contentBackground: '#e3f2fd78',
    container: '85%'
})